<template>
  <div class="container-fluid">
    <h3 v-if="localAnalitId">Редактирование аналита {{ analit.paramName }},  {{ analit.biomaterialType | bioMType(this.biomaterialTypeOptions) }}</h3>
    <h3 v-else>Создание аналита</h3>
    <b-form @submit.prevent="onSubmit">
      <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="font-weight-bold">
        <b-tab title="Параметры аналита" active>
          <div class="container">
            <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div>
            <b-row class="titleEditRow">
              <b-col cols="3">
                Полное наименование аналита
              </b-col>
              <b-col cols="1">
                <img src="@/assets/images/Icon_Metabolomnie_Anliti_Final_20x20.png" :class="analit.metabolomic ? 'metabolomic' : 'metabolomic-hide' " >
              </b-col>
              <b-col>
                <b>{{ analit.paramName }}, {{ analit.biomaterialType | bioMType(this.biomaterialTypeOptions) }}</b>
              </b-col>
            </b-row>
            <b-row class="titleEditRow">
              <b-col>
                <b-form-checkbox id="metabolomic" switch size="lg" v-model="analit.metabolomic">
                  Метаболомный аналит
                </b-form-checkbox>
              </b-col>
              <b-col>
                  <b-form-checkbox id="infectious" switch size="lg" v-model="analit.infectious">Инфекционный аналит
                  </b-form-checkbox>
              </b-col>
            </b-row>
            <b-form-group id="paramName" label-for="paramName" label="Наименование аналита" label-cols-lg="2">
              <b-form-input id="paramName" type="text" placeholder="" required v-model="analit.paramName" autocomplete="off"></b-form-input>
            </b-form-group>
            <b-form-group id="biomaterialType" label-for="biomaterialType" label="Тип биоматериала" label-cols-lg="2">
              <b-form-select id="biomaterialType" v-model="analit.biomaterialType" :options="biomaterialTypeOptions"
                             :select-size="1"></b-form-select>
            </b-form-group>
            <b-form-group id="paramSynonym" label-for="paramSynonym" label="Синоним" label-cols-lg="2">
              <b-form-input id="paramSynonym" type="text" placeholder="" v-model="analit.paramSynonym" autocomplete="off"></b-form-input>
            </b-form-group>
            <b-form-group id="measurementUnitId" label-for="measurementUnitId" label="Единицы измерения"
                          label-cols-lg="2">
              <nobr>
              <b-form-select id="measurementUnitId" v-model="analit.measurementUnitId" :options="measureOptions"
                             :select-size="1"></b-form-select>
              <b-button class="btn-info" @click="addNewMeasurementUnit()">
                <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
              </nobr>
            </b-form-group>
            <b-form-group id="paramCode" label-for="paramCode" label="Код аналита" label-cols-lg="2">
              <b-form-input id="paramCode" type="text" placeholder="" v-model="analit.paramCode" autocomplete="off"></b-form-input>
            </b-form-group>
            <b-form-group id="paramType" label-for="paramType" label="Тип аналита" label-cols-lg="2">
              <b-form-select id="paramType" v-model="analit.paramType" :options="paramTypeOptions"
                             :select-size="1"></b-form-select>
            </b-form-group>
            <b-form-group id="omsCode" label-for="omsCode" label="Приказ МЗ РФ №804" label-cols-lg="2">
              <b-form-input id="omsCode" type="text" placeholder="" v-model="analit.omsCode" autocomplete="off"></b-form-input>
            </b-form-group>
            <b-form-group v-if="(analit.paramType === 1 || analit.paramType === 3 || analit.paramType === 6 || analit.paramType === 10)"  id="individualCoefOfVariation" label-for="individualCoefOfVariation" label="Коэффициент индивидуальной вариации" label-cols-lg="2">
              <b-form-input v-mask="vMask" id="individualCoefOfVariation" type="text" placeholder="" :disabled="analit.icvIsAbsent" v-model="analit.individualCoefOfVariation" autocomplete="off"></b-form-input>
            </b-form-group>
            <div v-if="(analit.paramType === 1 || analit.paramType === 3 || analit.paramType === 6 || analit.paramType === 10)"  >
              <b-form-group id="paramFormulaId" label-for="paramFormulaId" label="Способ расчета интервалов" label-cols-lg="2">
                <b-form-select id="paramFormulaId" v-model="analit.paramFormulaId" :options="paramFormulaOptions"
                               :select-size="1"></b-form-select>
              </b-form-group>

            </div>
            <b-row v-if="analit.paramType === 1 && analit.paramFormulaId === 3">
              <b-col>K1</b-col>
              <b-col><b-form-input v-mask="vMask" id="k1" type="text" placeholder="" v-model="analit.k1" autocomplete="off"></b-form-input></b-col>
              <b-col>K2</b-col>
              <b-col><b-form-input v-mask="vMask" id="k2" type="text" placeholder="" v-model="analit.k2" autocomplete="off"></b-form-input></b-col>
              <b-col>K3</b-col>
              <b-col><b-form-input v-mask="vMask" id="k3" type="text" placeholder="" v-model="analit.k3" autocomplete="off"></b-form-input></b-col>
              <b-col>K4</b-col>
              <b-col><b-form-input v-mask="vMask" id="k4" type="text" placeholder="" v-model="analit.k4" autocomplete="off"></b-form-input></b-col>
              <b-col>K5</b-col>
              <b-col><b-form-input v-mask="vMask" id="k5" type="text" placeholder="" v-model="analit.k5" autocomplete="off"></b-form-input></b-col>
              <b-col>K6</b-col>
              <b-col><b-form-input v-mask="vMask" id="k6" type="text" placeholder="" v-model="analit.k6" autocomplete="off"></b-form-input></b-col>

            </b-row>
<!--            <div v-if="(analit.paramType === 1 || analit.paramType === 3 || analit.paramType === 6 || analit.paramType === 10)"  >-->
<!--              <b-form-checkbox id="icvSwitchOff" switch v-model="analit.icvSwitchOff">Коэффициент индивидуальной вариации выключен-->
<!--              </b-form-checkbox>-->
<!--            </div>-->
            <div v-if="(analit.paramType === 1 || analit.paramType === 3 || analit.paramType === 6 || analit.paramType === 10)"  >
              <b-form-checkbox id="icvIsAbsent" switch v-model="analit.icvIsAbsent">Коэффициент индивидуальной вариации отсутствует
              </b-form-checkbox>
            </div>
            <b-form-group v-if="analit.paramType === 3 || analit.paramType === 10"  id="formulaForCalculateParam" label-for="formulaForCalculateParam" label="Формула" label-cols-lg="2">
              <b-form-input id="formulaForCalculateParam" type="text" placeholder="" v-model="analit.formulaForCalculateParam" autocomplete="off" @change="formulaForCalculateParamChange"></b-form-input>
            </b-form-group>

            <div>
              <span class="badgeSelected" v-for="item in formulaForCalculateParamItems" :key="item.paramId">
                <b-badge variant="info" >
                  {{ item.paramName }}, {{ item.biomaterialTypeName }}
                </b-badge>
              </span>
            </div>

            <b-form-group v-if="analit.paramType === 3 || analit.paramType === 10"  id="paramCheckType" label-for="paramCheckType" label="" label-cols-lg="2">
              <b-form-radio-group
                  id="paramCheckType"
                  v-model="analit.paramCheckType"
                  :options="paramCheckTypeOptions"
                  name="paramCheckType"
                  stacked
              ></b-form-radio-group>
            </b-form-group>
            <b-row v-if="analit.paramType === 3 || analit.paramType === 10 ">
              <b-col>K1</b-col>
              <b-col><b-form-input v-mask="vMask" id="k1" type="text" placeholder="" v-model="analit.k1" autocomplete="off"></b-form-input></b-col>
              <b-col>K2</b-col>
              <b-col><b-form-input v-mask="vMask" id="k2" type="text" placeholder="" v-model="analit.k2" autocomplete="off"></b-form-input></b-col>
              <b-col>K3</b-col>
              <b-col><b-form-input v-mask="vMask" id="k3" type="text" placeholder="" v-model="analit.k3" autocomplete="off"></b-form-input></b-col>
              <b-col>K4</b-col>
              <b-col><b-form-input v-mask="vMask" id="k4" type="text" placeholder="" v-model="analit.k4" autocomplete="off"></b-form-input></b-col>
              <b-col>K5</b-col>
              <b-col><b-form-input v-mask="vMask" id="k5" type="text" placeholder="" v-model="analit.k5" autocomplete="off"></b-form-input></b-col>
              <b-col>K6</b-col>
              <b-col><b-form-input v-mask="vMask" id="k6" type="text" placeholder="" v-model="analit.k6" autocomplete="off"></b-form-input></b-col>

            </b-row>
<!--            <b-form-group v-if="analit.paramType === 4"  id="complexParamList" label-for="complexParamList" label="Аналиты для комплексного теста" label-cols-lg="2">-->
<!--              <b-form-input id="complexParamList" type="text" placeholder="" v-model="analit.complexParamList"></b-form-input>-->
<!--            </b-form-group>-->
<!--            <b-form-group v-else-if="analit.paramType === 5"  id="complexParamList" label-for="complexParamList" label="Аналиты для связанного теста" label-cols-lg="2">-->
<!--              <b-form-input id="complexParamList" type="text" placeholder="" v-model="analit.complexParamList"></b-form-input>-->
<!--            </b-form-group>-->
<!--            <b-form-group v-else-if="analit.paramType === 6"  id="complexParamList" label-for="complexParamList" label="Аналиты для интервального теста" label-cols-lg="2">-->
<!--              <b-form-input id="complexParamList" type="text" placeholder="" v-model="analit.complexParamList"></b-form-input>-->
<!--            </b-form-group>-->
<!--            <div v-if="localAnalitId && (analit.paramType === 4 || analit.paramType === 8 || analit.paramType === 9 || analit.paramType === 5 || analit.paramType === 6)">-->
<!--              <label v-if="analit.paramType === 4 || analit.paramType === 8 || analit.paramType === 9" class="editLabel" for="childrenParams">Аналиты для комплексного теста</label>-->
<!--              <label v-else-if="analit.paramType === 5" class="editLabel" for="childrenParams">Аналиты для связанного теста</label>-->
<!--              <label v-else-if="analit.paramType === 6" class="editLabel" for="childrenParams">Аналиты для интервального теста</label>-->
<!--              <br>-->
<!--              <treeselect name="childrenParams" id="childrenParams" placeholder=""-->
<!--                          multiple clearable searchable-->
<!--                          open-on-click disableFuzzyMatching-->
<!--                          :noResultsText="'Не найдено...'"-->
<!--                          :options="childrenParamOptions"-->
<!--                          :max-height="200"-->
<!--                          :normalizer="normalizeTreeselect"-->
<!--                          v-model="childrenParams" />-->
<!--            </div>-->
            <div v-if="localAnalitId">
<!--              <h4>Альтернативные единицы измерения</h4>-->
<!--              <AnalitMeasurements v-bind:analitId="this.localAnalitId" ref="analitMeasurements"></AnalitMeasurements>-->
              <AnalitAlternativeMeasurements v-bind:analitId="this.localAnalitId" ref="analitAlternativeMeasurements"></AnalitAlternativeMeasurements>
              <h4>Рубрикаторы</h4>
              <AnalitRubricators v-bind:analitId="this.localAnalitId" ref="analitRubricators"></AnalitRubricators>
            </div>

            <b-form-group id="paramDefinition" label-for="paramDefinition" label="Описание аналита">
              <ckeditor :editor="editor" v-model="analit.paramDefinition" tag-name="textarea"></ckeditor>
            </b-form-group>

            <div>
              <b-form-checkbox id="workInPractice" switch v-model="analit.workInPractice">Реализовано формирование
                отчета
                для аналита
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox id="hiddenForSearch" switch v-model="analit.hiddenForSearch">Скрыть при поиске
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox id="emptyDummy" switch v-model="analit.emptyDummy">Закрытый
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox id="paramDefinitionToReport" switch v-model="analit.paramDefinitionToReport">Включить описание аналита в отчет врача
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox id="debugOnly" switch v-model="analit.debugOnly">Отладочный аналит
              </b-form-checkbox>
            </div>


            <b-button variant="info" type="submit">Сохранить</b-button>
            &nbsp;
            <b-button variant="outline-info" @click="clickBackButton">Назад</b-button>
          </div>
        </b-tab>
        <b-tab v-if="localAnalitId && (analit.paramType === 4 || analit.paramType === 8 || analit.paramType === 9 || analit.paramType === 5 || analit.paramType === 6)"
               title="Связанные аналиты">
          <div>
            <ChildParamsSortOrder v-bind:analit-id="this.localAnalitId"></ChildParamsSortOrder>
          </div>
        </b-tab>
        <b-tab title="Основная интервальная шкала" v-if="localAnalitId && analit.paramType !== 2 && analit.paramType !== 7">
          <div>
            <ParamScaleMainList @after-recalc="afterCalc" @notAllowToChangeRDChanged="saveNotAllowToChangeRD"
                                v-bind:notAllowToChangeRD="analit.notAllowToChangeRD" v-bind:param-type="analit.paramType" v-bind:analitId="this.localAnalitId" ></ParamScaleMainList>
          </div>
        </b-tab>
        <b-tab title="Интерпретация ПС" lazy v-if="localAnalitId">
          <div class="container">
            <FactorBandList v-bind:analitId="this.localAnalitId" v-bind:param-type="this.analit.paramType" v-bind:factorName="'Disease'"></FactorBandList>
          </div>
        </b-tab>
        <b-tab title="Интерпретация ЛВ" lazy v-if="localAnalitId">
          <div class="container">
            <FactorBandList v-bind:analitId="this.localAnalitId" v-bind:param-type="this.analit.paramType" v-bind:factorName="'Drug'"></FactorBandList>
          </div>
        </b-tab>
        <b-tab title="Интерпретация ОС" lazy v-if="localAnalitId">
          <div class="container">
            <FactorBandList v-bind:analitId="this.localAnalitId" v-bind:param-type="this.analit.paramType" v-bind:factorName="'RiskFactor'"></FactorBandList>
          </div>
        </b-tab>
        <b-tab title="Интерпретация ИНТ" lazy v-if="localAnalitId">
          <div class="container">
            <FactorBandList v-bind:analitId="this.localAnalitId" v-bind:param-type="this.analit.paramType" v-bind:factorName="'LaboratoryError'"></FactorBandList>
          </div>
        </b-tab>
        <b-tab title="Альтернативные интервальные шкалы" v-if="localAnalitId && analit.paramType !== 2 && analit.paramType !== 7">
          <div>
            <ParamScaleList v-bind:analitId="this.localAnalitId" ></ParamScaleList>
          </div>
        </b-tab>
<!--        <b-tab title="Прямая привязка диапазонов" v-if="localAnalitId && analit.paramType !== 2 && analit.paramType !== 7">-->
<!--          <div>-->
<!--            <ParamScaleRangeList v-bind:analitId="this.localAnalitId" v-bind:measurement-unit-id="analit.measurementUnitId" ></ParamScaleRangeList>-->
<!--          </div>-->
<!--        </b-tab>-->
        <b-tab :title="analit.paramType === 2 ? 'Качественная шкала' : 'Полуколичественная шкала'" v-if="localAnalitId && (analit.paramType === 2 || analit.paramType === 7)">
          <AnalitProperties v-bind:analit-id="this.localAnalitId" v-bind:param-type="analit.paramType"></AnalitProperties>
        </b-tab>

<!--        <b-tab title="Рекомендации" v-if="localAnalitId">-->
<!--          <div class="container">-->
<!--            <Recommendation v-bind:analitId="this.localAnalitId" v-bind:param-type="this.analit.paramType"></Recommendation>-->
<!--          </div>-->
<!--        </b-tab>-->

      </b-tabs>

      <br/>

    </b-form>
    <b-modal id="measurementUnitModal" hide-footer size="lg" title="Единица измерения">
      <MeasurementUnitEdit @save-success="afterSave" v-bind:error="error" v-bind:current-measurement-unit="currentMU"></MeasurementUnitEdit>
    </b-modal>
    <Loader v-bind:showLoader="loading"/>
    <!--      <Loader v-if="loading"></Loader>-->
  </div>

</template>

<script>


import Loader from '@/components/Loader'
import AnalitMeasurements from "@/components/AnalitMeasurements";
import {host} from '@/constants'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AnalitRubricators from "@/components/admin/analits/AnalitRubricators";
import FactorBandList from "@/components/admin/analits/FactorBandList";
import ParamScaleList from "@/components/admin/scales/ParamScaleList";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import AnalitAlternativeMeasurements from "@/components/AnalitAlternativeMeasurements";
import ParamScaleMainList from "@/components/admin/scales/ParamScaleMainList";
import Recommendation from "@/components/admin/analits/Recommendation";
import MeasurementUnitEdit from "@/components/admin/measurementunits/MeasurementUnitEdit";
import AnalitProperties from "@/components/admin/analits/AnalitProperties";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ChildParamsSortOrder from "@/components/admin/analits/ChildParamsSortOrder";
import ParamScaleRangeList from "./admin/scales/ParamScaleRangeList";
const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 4,
  prefix: '',
  suffix: ''
})
export default {
  props: ['analitId'],
  name: "AnalitEditForm",
  data() {
    return {
      localAnalitId: this.analitId,
      tabIndex: 0,
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      // editorConfig: {
      //   toolbar: {
      //     items: [ 'undo', 'redo', '|', 'bold', 'italic' ],
      //   },
      //   plugins: [ Bold, Essentials, Italic, Paragraph ]
      // },
      vMask: valueMask,
      currentMU: null,
      analit: {
        paramName: '',
        paramSynonym: '',
        paramDefinition: '',
        measurementUnitId: 0,
        paramValueUp: '',
        paramValueLow: '',
        paramCode: null,
        workInPractice: false,
        paramValueUpAlert: '',
        paramValueLowAlert: '',
        paramNormal: '',
        paramType: 1,
        biomaterialType: null,
        omsCode: null,
        individualCoefOfVariation: null,
        formulaForCalculateParam: '',
        complexParamList: '',
        bindParamList: '',
        hiddenForSearch: false,
        icvSwitchOff: false,
        icvIsAbsent: false,
        emptyDummy: false,
        paramFormulaId: null,
        k1: null,
        k2: null,
        k3: null,
        k4: null,
        k5: null,
        k6: null,
        paramCheckType: 0,
        paramDefinitionToReport: false,
        notAllowToChangeRD: false,
        metabolomic: false,
        debugOnly: false,
        infectious: false
      },
      loading: true,
      error: null,
      measureOptions: [],
      paramTypeOptions: [],
      biomaterialTypeOptions: [],
      medicalSpecialties: [],
      medicalSpecialtiesOptions: [],
      metabolismTypes: [],
      metabolismTypesOptions: [],
      labStateTissueOrgans: [],
      labStateTissueOrgansOptions: [],
      conditionAndSyndromes: [],
      conditionAndSyndromesOptions: [],
      methods: [],
      methodsOptions: [],
      complexExaminations: [],
      complexExaminationsOptions: [],
      laboratories: [],
      laboratoriesOptions: [],
      childrenParams: [],
      childrenParamOptions:[],
      paramFormulaOptions: [],
      paramCheckTypeOptions: [
        {value: 0, text:'Не реализовано в кабинете врача'},
        {value: 1, text:'Реализовано в кабинете врача'},
        {value: 2, text:'Проверено'}
      ],
      formulaForCalculateParamItems: [],
      formulaForCalculateParamChangeFlg: false,
      normalizeTreeselect(node) {
        return {
          id: node.paramId,
          label: node.paramName + (node.biomaterialTypeName ? ', ' + node.biomaterialTypeName : '') + ' ' +  node.paramCode
        }
      }
    }
  },
  filters: {
    bioMType: function (value, biomaterialTypeOptions) {

      if (biomaterialTypeOptions) {
        const result = biomaterialTypeOptions.filter((el) => el.value === value)

        return result && result.length > 0 ? result[0].text : ""
      } else {
        return ""
      }
    }
  },
  components: {
    ParamScaleRangeList,
    ChildParamsSortOrder,
    AnalitProperties,
    MeasurementUnitEdit,
    Recommendation,
    ParamScaleMainList,
    AnalitAlternativeMeasurements,
    ParamScaleList,
    FactorBandList,
    AnalitRubricators,
    Loader, AnalitMeasurements, Treeselect
  },
  methods: {
    saveNotAllowToChangeRD(notAllowToChangeRD) {
      this.error = null
      this.loading = true
      this.analit.notAllowToChangeRD = notAllowToChangeRD

      let method = 'PUT'

      fetch(host + '/rest/params', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.analit),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            this.loading = false
            if (json.resultCode == 0) {



            } else {

              this.error = json.resultCode + ' ' + json.message;
              this.$bvToast.toast(json.resultCode + ' ' + json.message, {
                title: `Ошибка`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'danger',
                noAutoHide: true,
                appendToast: true
              })
            }
          }).catch(error => {
            this.loading = false
        this.error = error
        this.$bvToast.toast(error, {
          title: `Ошибка`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
          noAutoHide: true,
          appendToast: true
        })

      })

    },
    formulaForCalculateParamChange(newVal) {
      this.formulaForCalculateParamChangeFlg = true
      this.formulaForCalculateParamItems = []
      let vals = newVal//.split('=')
      // if (vals.length < 2) {
      //   vals = vals[0]
      // } else {
      //   vals = vals[1]
      // }

      vals = vals.split('#')
      console.log(vals)
      if (vals.length < 2) {
        return
      }

      vals.splice(0, 1)
      console.log(vals)
      console.log(this.childrenParamOptions)
      for (let val of vals) {
        let lastIndex = -1;
        if (val.indexOf(' ') > 0 && val.indexOf(')') < 0 ) {
          lastIndex = val.indexOf(' ')
        } else if (val.indexOf(' ') < 0 && val.indexOf(')') > 0 ) {
          lastIndex = val.indexOf(')')
        } else if (val.indexOf(' ') > 0 && val.indexOf(')') > 0 && val.indexOf(')') < val.indexOf(' ')) {
          lastIndex = val.indexOf(')')
        } else if (val.indexOf(' ') > 0 && val.indexOf(')') > 0 && val.indexOf(')') > val.indexOf(' ')) {
          lastIndex = val.indexOf(' ')
        } else {
          lastIndex = val.length
        }
        let paramCode = val.substring(0, lastIndex)
        console.log('paramCode = ' + paramCode)
        let arr = this.childrenParamOptions.find(el => el.paramCode === paramCode)
        console.log(JSON.stringify(arr))
        if (arr && !this.formulaForCalculateParamItems.find(el => (el.paramId === arr.paramId))) {
          this.formulaForCalculateParamItems.push(arr)
        }

      }
      console.log(this.formulaForCalculateParamItems)
      //childrenParamOptions

    },
    afterCalc() {
      this.$router.go(0)
    },
    addNewMeasurementUnit() {
      this.$bvModal.show('measurementUnitModal')
    },
    afterSave() {
      this.$bvModal.hide('measurementUnitModal')
      this.loading = true
      this.fetchMU()
      this.$refs.analitAlternativeMeasurements.fetchDicts()
    },
    fetchMU() {
      fetch(host + '/rest/dicts/measurementunit')
          .then(response => response.json())
          .then(json => {
            this.measureOptions = json.options
            this.loading = false
          })
    },
    clickBackButton() {
      this.error = null
      this.$router.go(-1)
    },
    saveChildren(childrenParams = null) {
      if (childrenParams) {
        let rq = {
          children: this.formulaForCalculateParamItems
        }
        fetch(host + '/rest/params/' + this.localAnalitId + '/childrenparam', {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(rq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {

            }).catch(error => {
          this.error = error
        })
      }
    },
    async onSubmit() {
      this.error = null
      this.loading = true

      let method = 'POST'
      if (this.localAnalitId) {
        method = 'PUT'
      }

      // Если коэффициент индивидуальной вариации не отображается из-за типа аналита, то явно задаём что КИВ отсутствует
      if (this.analit.paramType !== 1 && this.analit.paramType !== 3 && this.analit.paramType !== 6 && this.analit.paramType !== 10) {
        this.analit.icvIsAbsent = true
      }

      fetch(host + '/rest/params', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.analit),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            this.loading = false
            if (json.resultCode == 0) {

              if (!this.analitId) {
                this.localAnalitId = json.entityId
                this.analit.paramId = this.localAnalitId
                this.$router.push('/analitedit/' + this.localAnalitId)
              } else {
                this.$router.push('/analits')
              }

            } else {

              this.error = json.resultCode + ' ' + json.message;
              this.$bvToast.toast(json.resultCode + ' ' + json.message, {
                title: `Ошибка`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'danger',
                noAutoHide: true,
                appendToast: true
              })
            }
          }).catch(error => {
        this.error = error
        this.$bvToast.toast(error, {
          title: `Ошибка`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
          noAutoHide: true,
          appendToast: true
        })

      })
      if (method === 'PUT') {
        this.$refs.analitRubricators.onSubmit()
        await this.$refs.analitAlternativeMeasurements.onSubmitMeasurementParams()
        if (this.analit.paramType === 4 || this.analit.paramType === 8 || this.analit.paramType === 9 || this.analit.paramType === 5 || this.analit.paramType === 6) {
          //this.saveChildren()
        } else if (this.analit.paramType === 3 || analit.paramType === 10) {
          let ids = []
          for (let el of this.formulaForCalculateParamItems) {
            ids.push(el.paramId)
          }
          if (this.formulaForCalculateParamChangeFlg)
            this.saveChildren(ids)
        }
      }
      this.loading = false
    }
  },
  mounted() {
    this.fetchMU()
    fetch(host + '/rest/dicts/paramtype')
        .then(response => response.json())
        .then(json => {
          this.paramTypeOptions = json.options
        })
    fetch(host + '/rest/params', {
        credentials: "include"
      }).then(response => response.json())
        .then(json => {
          this.childrenParamOptions = json.params

          this.formulaForCalculateParamChange(this.analit.formulaForCalculateParam)

          fetch(host + '/rest/params/' + this.analitId + '/children', {
            credentials: "include"
          })
              .then(response => response.json())
              .then(json => {
                this.childrenParams = json.children
              })
        })


    fetch(host + '/rest/dicts/biomaterialtype')
        .then(response => response.json())
        .then(json => {
          this.biomaterialTypeOptions = [{
            value: null,
            text: ''
          }]
          this.biomaterialTypeOptions = this.biomaterialTypeOptions.concat(json.options)

        })

    fetch(host + '/rest/dicts/paramformula')
        .then(response => response.json())
        .then(json => {
          this.paramFormulaOptions = [{
            value: null,
            text: ''
          }]
          this.paramFormulaOptions = this.paramFormulaOptions.concat(json.options)
        })

    if (this.analitId) {
      fetch(host + '/rest/params/' + this.analitId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {

            this.analit = json.param
            this.loading = false

          })
    } else {
      setTimeout(() => {
        this.loading = false
      }, 50)

    }
  }
}
</script>

<style>
.ck-content {
  height: 300px;
}
.titleEditRow {
  padding-bottom: 1rem;
}
.badgeSelected {
  font-size: medium!important;
  padding: 0.2rem;
}

.metabolomic {
  animation-duration: 1s;
  animation-name: metabolomic;
}
.metabolomic-hide {
  animation-duration: 1s;
  animation-name: metabolomic-hide;
  opacity: 0%;
}

@keyframes metabolomic {
  from {
    opacity: 0%;

  }

  to {
    opacity: 100%;
  }
}

@keyframes metabolomic-hide {
  from {
    opacity: 100%;

  }

  to {
    opacity: 0%;
  }
}

</style>