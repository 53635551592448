<template>
  <div>
    <b-list-group horizontal >
      <b-list-group-item :variant="currentRubr !== null && r.value === currentRubr.value ? 'info' : ''" v-for="r in rubricatorList" :key="r.value" button @click="setRname(r)">{{r.text}}</b-list-group-item>
    </b-list-group>
    <div class="container-fluid">
      <RubricatorValueList v-if="currentRubr!==null" v-bind:rubr="currentRubr" ></RubricatorValueList>

    </div>

  </div>
</template>

<script>
import {host} from "@/constants";
import RubricatorValueList from "@/components/admin/rubricators/RubricatorValueList"
export default {
  name: "RubricatorList",
  data () {
    return {
      rubricatorList: [],
      currentRubr: null,
      isSetRname: false
    }
  },
  components: {
    RubricatorValueList
  },
  mounted() {
      this.fetchData()
  },
  methods: {
    fetchData() {
      fetch(host + '/rest/rubricators', {
        credentials:'include'
      })
          .then(response => response.json())
          .then(json => {
            this.rubricatorList = json.options
            //this.loading = false
            console.log(json)
          })
    },
    setRname(rubr = null) {
      this.currentRubr = rubr
    }
  }
}
</script>

<style scoped>

</style>