<template>
  <div class="row">
    <div class="col-lg-12">
      <b-button variant="info" @click="editRubricatorValue()"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить</b-button>
      <br /><br />
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>
      <b-table striped hover :items="items" :fields="paramType === 2 ? fields : fieldsAlternative" ref="measurementUnitTable">
        <!-- A custom formatted column -->
        <template v-slot:cell(value)="data">
          <b>{{ data.item.value }}</b>
        </template>
        <template v-slot:cell(text)="data">
          <span v-html="data.item.text"></span>
        </template>
        <template v-slot:cell(interval)="data">
            <span
                :inner-html.prop="data.item.startValue | valueText1(data.item.measurementUnitName, data.item.finishValue)"></span>
        </template>
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="editRubricatorValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteRubricatorValue(data.item.value, data.item.text)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
      </b-table>
      <b-modal :id="'propValModal' + this.currentRubricator.propId" hide-footer size="lg" :title="this.currentRubricator.propName">
        <AnalitPropertyValueEdit @save-success="afterSave" v-bind:analit-id="analitId" v-bind:error="error" v-bind:current-rubricator-value="currentMeasurementUnit"
                                 v-bind:current-rubricator="currentRubricator" v-bind:param-type="paramType" ></AnalitPropertyValueEdit>
      </b-modal>
      <Loader v-bind:showLoader="loading" />
    </div>
  </div>
</template>

<script>
import {host} from "@/constants"
import Loader from "@/components/Loader";
import AnalitPropertyValueEdit from "@/components/admin/analits/AnalitPropertyValueEdit";
export default {
  props:{
    analitId: String,
    rubr: Object,
    paramType: Number
  },
  name: "AnalitPropertyValues",
  components: {
    AnalitPropertyValueEdit,
    Loader
  },
  data() {
    return {
      fields: [
        {
          key: 'value',
          label: 'Id',
          sortable: true
        },
        {
          key: 'text',
          label: 'Вариант ответа',
          sortable: true
        },
        // {
        //   key: "propertyValueTypeName",
        //   label: 'Классификация ответа',
        //   sortable: true
        // },
        {
          key: "propertyValueNameSynonym",
          label: 'Синонимы',
          sortable: true
        },
        {
          key: "zoneName",
          label: 'Классификация ответа',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      fieldsAlternative: [
        {
          key: 'value',
          label: 'Id',
          sortable: true
        },
        {
          key: 'text',
          label: 'Вариант ответа',
          sortable: true
        },
        {
          key: 'interval',
          label: 'Интервалы',
          sortable: true
        },
        {
          key: "titres",
          label: 'Титры',
          sortable: true
        },
        {
          key: "plusMinus",
          label: 'Кресты',
          sortable: true
        },
        {
          key: "propertyValueNameSynonym",
          label: 'Синонимы',
          sortable: true
        },
        {
          key: "zoneName",
          label: 'Классификация ответа',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentRubricator: this.rubr,
      currentMeasurementUnit: null
    }
  },
  methods: {
    deleteRubricatorValue(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить значение атрибута "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/params/' + this.analitId + '/properties/' + this.currentRubricator.propId + '/values/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.value !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editRubricatorValue(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('propValModal' + this.currentRubricator.propId)
    },
    fetchData() {
      fetch(host + '/rest/params/' + this.analitId + '/properties/' + this.currentRubricator.propId + '/values')
          .then(response => response.json())
          .then(json => {
            this.items = json.options
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('propValModal' + this.currentRubricator.propId)
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  filters: {
    valueText1: function (value, unitName, finishValue) {
      if (value && finishValue) {
        return '<b>' + value + '</b> - <b>' + finishValue + '</b> ' + unitName
      } else if (value) {
        return 'выше <b>' + value + '</b> ' + unitName
      } else if (finishValue) {
        return 'до <b>' + finishValue + '</b> ' + unitName
      }
      return '-'
    }
  },
  watch: {
    rubr: function (val) {
      this.loading = true
      this.currentRubricator = val
      this.fetchData()
    },
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>

