<template>
  <div class="container-fluid p-0">
    <b-table striped hover class="mt-4 ml-0 mr-0 h-100" :items="complexReports" :fields="fields" :tbody-tr-class="'table-row'">
      <template v-slot:head(complexReportName)="data">
        <div class="column-head vertical-center">Название отчета</div>
      </template>
      <template v-slot:head(createDate)="data">
        <div class="column-head vertical-center">Дата создания</div>
      </template>
      <template v-slot:head(complexReportComment)="data">
        <div class="column-head vertical-center">Комментарий к отчету</div>
      </template>
      <template v-slot:head(settings)="data">
        <div class="column-head vertical-center">Настройки отчета</div>
      </template>
      <template v-slot:head(buttons)="data">
        <div class="vertical-center">
          <nobr>
            <b-link v-if="role == 2" @click="deleteAllClientReports()" title="Удалить" class="delete_all_link">Удалить всё <img src="@/assets/images/patients/icon_delete_all.png"></b-link>
          </nobr>
        </div>
      </template>

      <template v-slot:cell(complexReportName)="data">
        <div class="vertical-center-container"><span class="vertical-center" v-html="data.item.complexReportName" ></span></div>
      </template>
      <template v-slot:cell(complexReportComment)="data">
        <div class="vertical-center-container"><span class="vertical-center" v-html="data.item.complexReportComment" ></span></div>
      </template>
      <template v-slot:cell(createDate)="data">
        <div class="vertical-center-container"><span class="vertical-center" >{{ new Date(data.item.createDate).toLocaleDateString() }}</span></div>
      </template>
      <template v-slot:cell(buttons)="data">
        <div class="vertical-center" >
          <nobr>
            <b-link @click="showDetails(data)" class="ml-1" title="Показать аналиты входящие в отчет" ><img src="@/assets/images/patients/icon_details.png"></b-link>
            <b-link @click="openAnalitPdfReport(data.item)" class="ml-1" title="Просмотр отчета" ><img src="@/assets/images/patients/icon_pdf.png"></b-link>
            <b-link v-if="role===2" @click="showEditModalBlankName(data.item)" class="ml-1" title="Редактировать отчет"><img src="@/assets/images/patients/icon_edit.png"></b-link>
            <!--              &nbsp;-->
            <b-link v-if="role===2" @click="deletePrescribedAnalit(data.item)" class="ml-1" title="Удалить отчет"><img src="@/assets/images/patients/icon_delete.png"></b-link>
          </nobr>
        </div>
      </template>
      <template v-slot:cell(settings)="data">
        <div><strong>Уровень отчёта:</strong> {{data.item.reportLevelName}}; </div>
        <div><strong>Управление детализацией отчёта по редким нозологическим формам и/или неспецифическим связям с аналитами: </strong>
          <span v-if="!data.item.excludeSeldomDisease && !data.item.excludePriorityDisease"> Включены все формы; </span>
          <span v-if="data.item.excludeSeldomDisease"> Исключены редкие формы; </span>
          <span v-if="data.item.excludePriorityDisease"> Исключены формы со слабыми связями с аналитом; </span>
        </div>
        <div>
          <strong>Классы нозологических форм в отчёте: </strong>
          <span v-if="data.item.allDisease" >Включены все классы</span>
          <span v-else >Классы включены <b-link @click="showDiseases(data.item)">выборочно</b-link></span>
        </div>
        <b-collapse :id="'disease-collapse-' + data.item.complexReportId">
          <b-card>
            <b-spinner v-if="!data.item.loadedDiseases" variant="success" small></b-spinner>
            <ul v-else>
              <li v-for="(dis) in data.item.diseases" :key="dis.value">{{dis.text}}</li>
            </ul>
          </b-card>
        </b-collapse>
        <div v-if="reportType == 2 || reportType == 3">
          <strong>Управление частотно-вероятностными событиями: </strong>
          <span v-if="data.item.excludeLowProbabilityEvents">Исключены низковероятные события</span>
          <span v-else-if="data.item.excludeMiddleProbabilityEvents">Исключены низковероятные  и умеренно вероятные события</span>
          <span v-else>Включены все события</span>
        </div>
        <div><strong>Учет влияния фармпрепаратов/-групп: </strong> {{data.item.reportViewName}}</div>
        <div >
          <strong>Управление рекомендациями в отчёте: </strong>
          <span v-if="data.item.excludeBlackParam">Исключить детализирующие аналиты</span>
          <span v-else-if="data.item.excludeBlackGreenParam">Исключить уточняющие и детализирующие аналиты</span>
          <span v-else>Включены все рекомендации</span>
        </div>
        <div >
          <strong>Анкета пациента: </strong>
          <span v-if="data.item.excludeCheckListDisease">не включена в отчёт</span>
          <span v-else>включена в отчёт</span>
        </div>
        <div v-if="permission.debugDoctor && reportSubTypeOptions.length > 1">
          <strong>Доступные шаблоны отчетов</strong>
          <div v-for="(opt) in reportSubTypeOptions" :key="opt.value" >
            <b-link @click="openAnalitPdfReport(data.item, opt.value)" class="text_link" title="Просмотр отчета" >{{ opt.text }}</b-link>
          </div>
        </div>
      </template>
<!--      <template v-slot:cell(checkboxes)="data">-->
<!--        <div v-if="data.item.excludeSeldomDisease">Исключены из отчета редкие нозологические формы</div>-->
<!--        <div v-if="data.item.excludePriorityDisease">Исключены из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов</div>-->
<!--      </template>-->

      <template #row-details="data">
        <b-overlay spinner-variant="info" :opacity="0.5" :show="overlayShow">
          <b-card>
            <b-container v-if="!overlayShow || data.item.details">
              <b-row><strong>Включенные аналиты: <span v-if="!data.item.details || data.item.details.length === 0"> Отсутствуют</span></strong></b-row>
              <b-row class="newAttr" style="line-height: 28px;" v-for="(d) in data.item.details" :key="d.paramClientId">
                <ZoneArrow :zone-id="d.zoneId"/>
                &nbsp;{{ d.createDate }},
                <b-icon :id="'param_def_' + d.paramId" icon="book"></b-icon>
                <b-tooltip  :target="'param_def_' + d.paramId" triggers="hover">
                  <span v-html="d.paramDefinition"></span>
                </b-tooltip> &nbsp;
                {{d.paramName}}{{d.biomaterialTypeName ? ', ' + d.biomaterialTypeName : '' }}:
                <span v-if="d.paramType === 2 || (d.paramType === 7 && d.propertyName)">
                  <b class="text-info">{{ data.item.propertyName }}, {{ data.item.propertyValueName }}</b>
                </span>
                <span v-else>
                  <b class="text-info">{{ d.paramValue }}</b> <b>{{ d.measurementUnitName }}</b>
                </span>
              </b-row>

            </b-container>
          </b-card>
        </b-overlay>
      </template>
    </b-table>

    <b-modal id="complexReportEditModal" hide-footer size="lg" title="Редактирование отчета">
      <form @submit.prevent="createComplexReport">
        <b-form-group label="Название отчета" label-cols="3">
          <b-form-input type="text" required size="255" v-model="blankName"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="3" label="Комментарий к отчету" >
          <b-form-input type="text" size="255" v-model="blankComment"></b-form-input>
        </b-form-group>


        <b-button type="submit" class="btn-info" :disabled="saving">
          Сохранить<b-spinner v-if="saving" variant="light" small></b-spinner>
        </b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {host} from "@/constants";
import ZoneArrow from "./reports/params/primitives/ZoneArrow";

export default {
  components: {ZoneArrow},
  props: ["patientId", "reportType"],
  name: "PatientComplexReports",
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      }
    }
  },
  data() {
    return {
      blankId: null,
      blankName: null,
      blankComment: null,
      saving: false,
      overlayShow: true,
      complexReports: [],
      reportSubTypeOptions:[],
      fields:[
        {
          key: 'complexReportName',
          label: 'Название отчета',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'createDate',
          label: 'Дата создания',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'complexReportComment',
          label: 'Комментарий к отчету',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'settings',
          label: 'Настройки отчета',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'buttons',
          label: '',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        }
      ]
    }
  },
  methods: {
    createComplexReport() {
      let method = 'PUT'
      let request = { complexReportId: this.blankId, complexReportName: this.blankName, complexReportComment: this.blankComment }
      fetch(host + '/rest/patients/' + this.patientId + '/reports/complex', {
        credentials: "include",
        method: method,
        body: JSON.stringify(request),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {

            this.saving = false
            if (json.resultCode === 0) {
              this.$messageToast('Обобщенный отчет')
              this.$bvModal.hide('complexReportEditModal')

              this.fetchData()
              //this.fetchPrescribedBlanks()
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        console.log(error)
        this.saving = false
      })
    },
    openAnalitPdfReport(item = null, reportSubType = null) {
      let reportType = 'ComplexDetailedReport'

      if (this.reportType === 2) {
        reportType = 'ComplexGeneralizedReport'
      } else if (this.reportType === 3) {
        reportType = 'ComplexShortReport'
      } else if (this.reportType === 4) {
        reportType = 'GraphicPatientReport'
      } else if (this.reportType === 5) {
        reportType = 'GraphicDoctorReport'
      }


      window.open(host + "/rest/reports/" + reportType + "/patient/" + this.patientId + "/complexreport/" + item.complexReportId
          +  (reportSubType !== null ? "/reportSubType/" + reportSubType : ""), "_blank");

    },
    deleteAllClientReports () {

      if (confirm('Вы уверены, что хотите удалить все отчёты?')) {
        this.loading = true;
        fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + this.reportType + '/byType', {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()
              } else {
              }
            }).catch(error => {
        })
      }
    },
    async deletePrescribedAnalit(item) {

      if (confirm('Вы действительно хотите удалить отчет ' + item.complexReportName + '?')) {
        try {
          let response = await fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + item.complexReportId, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          await response.json()
        } catch (e) {
          console.log(e)
        }

        this.complexReports.splice(this.complexReports.indexOf(item), 1)
      }
    },
    showEditModalBlankName(item = null) {
      if (item) {
        this.blankId = item.complexReportId
        this.blankName = item.complexReportName
        this.blankComment = item.complexReportComment
      } else {
        this.blankId = null
        this.blankName = 'Назначение от ' + new Intl.DateTimeFormat('ru-RU').format(new Date())
        this.blankComment = null
      }
      this.$bvModal.show('complexReportEditModal')
    },
    showDetails(data) {
      this.overlayShow = true
      data.toggleDetails()

      if (!data.item.details) {
        fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + data.item.complexReportId + '/params', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              data.item.details = json.reportParams
              console.log(json)
              this.overlayShow = false
            }).catch(error => {
          this.overlayShow = false
        })
      } else {
        this.overlayShow = false
      }
    },
    fetchData() {
      fetch(host + '/rest/dicts/reportSubtype/' + this.reportType, {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportSubTypeOptions = json.options
            console.log("get dict reportSubType Options ")
            console.log(JSON.stringify(this.reportSubTypeOptions))
          })

      fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + this.reportType, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.complexReports = json.reports
          }).catch(error => {
        console.log(error)
      })


    },
    async showDiseases(item) {
      console.log('disease-collapse-' + item.complexReportId)
      this.$root.$emit('bv::toggle::collapse', 'disease-collapse-' + item.complexReportId)
      if (item.loadedDiseases == false) {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + item.complexReportId + '/diseases', {
          credentials: "include"
        })
        let json = await response.json()

        item.diseases = json.options
        item.loadedDiseases = true
      }


    }
  },
  mounted() {
    this.fetchData()

  }
}
</script>

<style scoped>

</style>