<template>
  <div class="container">
    <Loader v-bind:showLoader="loading" />
    <b-form @submit.prevent="onSubmit">
      <h3 v-if="customerId">Редактирование заказчика</h3>
      <h3 v-else>Создание заказчика</h3>
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>

      <br>
      <b-form-group id="name" label-for="name" label="Название заказчика">
        <b-form-input id="name" type="text" placeholder="" required v-model="customer.name" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="url" label-for="url" label="URL">
        <b-form-input id="url" type="text" placeholder="Например, http://test.domain.com" :required="customer.isAlphalabCustomer" v-model="customer.url" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="login" label-for="login" label="Логин">
        <b-form-input id="login" type="text"  v-model="customer.login" autocomplete="off" :required="customer.isAlphalabCustomer"></b-form-input>
      </b-form-group>
      <b-form-group id="password" label-for="password" label="Пароль">
        <b-form-input id="password" type="password"  :required="customer.isAlphalabCustomer" v-model="customer.password" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="timeout" label-for="timeout" label="Частота запросов, сек">
        <b-form-input id="timeout" type="number" required placeholder="" v-model="customer.timeout" autocomplete="off"></b-form-input>
      </b-form-group>

      <div class="row">
        <b-form-checkbox id="isActive" switch v-model="customer.isActive">Заказчик активен
        </b-form-checkbox>
      </div>
      <div class="row">
        <b-form-checkbox id="isAlphalabCustomer" switch v-model="customer.isAlphalabCustomer">Заказчик Альфалаб
        </b-form-checkbox>
      </div>

      <div class="row">
        <b-form-checkbox id="isEmailSend" switch v-model="customer.isEmailSend">Отправлять Email
        </b-form-checkbox>
      </div>

      <b-form-group id="emailList" label-for="emailList" label="Перечень адресов для отправки Email (через ,)">
        <b-form-input id="emailList" type="text"  v-model="customer.emailList" autocomplete="off" :disabled="!customer.isEmailSend" :required="customer.isEmailSend"></b-form-input>
      </b-form-group>



      <b-form-group id="clinics" label-for="clinics" label="Врач">
        <treeselect name="clinics" id="clinics" placeholder=""
                    clearable searchable
                    open-on-click disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :options="doctorOptions"
                    :max-height="200"
                    :normalizer="normalizeClinics"
                    v-model="customer.doctorId" />
      </b-form-group>

      <b-form-group id="specializations" label-for="specializations" label="Группа соответствия аналитов">

        <treeselect name="specializations" id="specializations" placeholder=""
                    clearable searchable
                    open-on-click disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :options="paramCodeMapOptions"
                    :max-height="200"
                    :normalizer="normalizeSpecializations"
                    v-model="customer.paramCodeMappingId" />
      </b-form-group>



      <b-button variant="info" type="submit" >Сохранить</b-button>
      &nbsp;
      <b-button variant="outline-info" @click="clickBackButton" >Назад</b-button>
    </b-form>


  </div>
</template>

<script>
import {host} from "@/constants";
import Loader from "@/components/Loader";
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  props: ['customerId'],
  name: "CustomerEdit",
  components: {
    Treeselect, Loader
  },
  data() {
    return {
      loading: false,
      error: null,
      customer: {
        id: null,
        name: '',
        url: '',
        login: '',
        password: '',
        timeout: 60,
        doctorId: null,
        paramCodeMappingId: null,
        isActive: false,
        isAlphalabCustomer: false,
        isEmailSend: false,
        emailList: ''
      },
      doctorOptions: [],
      paramCodeMapOptions: [],
      normalizeClinics(node) {
        return {
          id: node.doctorId,
          label: node.lastname + ' ' + node.firstname + ' ' + node.middlename
        }
      },
      normalizeSpecializations(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  async mounted() {
    console.log('mounted started ' + this.customerId)
    if (this.customerId) {

      await this.fetchData()
    }
    await this.fetchCodeMaps()
    await this.fetchDoctors()
  },
  methods: {
    clickBackButton () {
      this.$router.go(-1)
    },
    async onSubmit() {
      this.loading = true
      let method = 'POST'
      if (this.customerId) {
        method = 'PUT'
      }
      let rs = await fetch(host + '/rest/customers', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.customer),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.$errorToast(json.resultCode + ' ' + json.message)
        this.loading = false
        return
      }



      await this.$router.push('/customers')

    },
    async fetchData() {
      console.log('fetchData started')
      this.loading = true
      try {
        const response = await fetch(host + '/rest/customers/'+this.customerId, {
          credentials: 'include'
        })
        const json = await response.json()


        this.loading = false

        if (json.resultCode == 0) {
          this.customer = json.customer


        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
          console.log(error)
        }
      } catch(error) {
        this.$errorToast(error)
        console.log(error)
        this.loading = false
      }

    },
    async fetchDoctors() {
      const response = await fetch(host + '/rest/doctors', {
        credentials: "include"
      })
      const json = await response.json()

      this.doctorOptions = json.doctors
    },
    async fetchCodeMaps() {
      const response = await fetch(host + '/rest/customers/paramcodemaps', {
        credentials: "include"
      })
      const json = await response.json()

      this.paramCodeMapOptions = json.options
    }
  }

}
</script>

<style scoped>

</style>