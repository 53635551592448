<template>
  <b-container fluid>
    <b-form @submit.prevent="updatePhrases()">
      <b-row v-for="(item) in items" :key="item.diseaseId">
        <b-container fluid>
          <b-row class="pt-4">
            <b-form-group :id="'labelPhrase'+item.diseaseId" :label-for="'phrase'+item.diseaseId" class="w-100"
                          :label="item.diseaseName">
              <b-textarea rows="5" max-rows="7" :id="'phrase'+item.diseaseId"
                          v-model="item.diseasePatientReport"></b-textarea>
            </b-form-group>
          </b-row>
          <b-row >
            <b-form-input type="text" required max="128" :id="'phraseShort'+item.diseaseId"  v-model="item.diseasePatientReportShort"></b-form-input>
          </b-row>
        </b-container>
      </b-row>

      <b-row class="pt-4">
        <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>
      </b-row>

    </b-form>
    <Loader v-bind:showLoader="loading"/>
  </b-container>
</template>

<script>
import {host} from "@/constants"
import Loader from "../../Loader";

export default {
  name: "ZoneDiseaseReportPhrases",
  components: {Loader},
  data() {
    return {
      loading: false,
      items: []
    }
  },
  mounted() {
    console.log('ZoneReportPhrases')
    this.fetchData()
  },
  methods: {
    updatePhrases() {
      this.loading = true
      fetch(host + '/rest/reports/phrases/disease', {
        method: 'PUT',
        credentials: "include",
        body: JSON.stringify({reportDiseasePhrases: this.items}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Фразы', 500)
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading = false
          }).catch(er => {
        this.$errorToast('Неизвестная ошибка')
        this.loading = false
      })

    },

    fetchData() {
      this.loading = true
      fetch(host + '/rest/reports/phrases/disease', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.reportDiseasePhrases
            this.loading = false
            console.log(json)

          }).catch(er => {
        this.$errorToast('Неизвестная ошибка')
        this.loading = false
      })
    }

  }
}
</script>

<style scoped>

</style>