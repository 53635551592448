<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="zoneName" label-for="zoneName" label-class="strong-text" label="Наименование" label-cols-lg="2">
        <b-form-input id="zoneName" type="text" required placeholder="Введите значение" v-model="zone.zoneName" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="zoneShowPriority" label-for="zoneShowPriority" label-class="strong-text" label="Приоритет" label-cols-lg="2">
        <b-form-input id="zoneShowPriority" type="number" placeholder="" required v-model="zone.zoneShowPriority" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="zonecoefB" label-for="zonecoefB" label-class="strong-text" label="Коэффициент значимости отклонений" label-cols-lg="5">
        <b-form-input id="zonecoefB" type="number" placeholder="" required v-model="zone.coefB" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="zonecoefBGraphic" label-for="zonecoefBGraphic" label-class="strong-text" label="Коэффициент значимости отклонений для графических отчётов" label-cols-lg="5">
        <b-form-input id="zonecoefBGraphic" type="number" placeholder="" required v-model="zone.coefBGraphic" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="zoneGroupId" label-for="zoneGroupId" label-class="strong-text" label="Группа" label-cols-lg="2">
<!--        <b-form-input id="zoneGroup" type="number" placeholder="" required v-model="zone.zoneGroupId"></b-form-input>-->
        <b-form-select id="zoneGroupId" v-model="zone.zoneGroupId" :options="zoneGroupOptions"
                       :select-size="1"></b-form-select>
      </b-form-group>

      <b-form-group id="paramType" label-for="paramType" label-class="strong-text" label="Тип интервала" label-cols-lg="2">
        <b-form-select id="paramType" required v-model="zone.paramType" :options="paramTypeOptions"
                       :select-size="1"></b-form-select>
      </b-form-group>

      <b-form-group id="reportText" label-for="reportText" label-class="strong-text" label="Текст заключения">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportText"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextDesease" label-for="reportTextDesease" label-class="strong-text" label="Фраза для блока отчета &quot;Патологические состояния и причины отклонений&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextDeseaseId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor  v-model="zone.reportTextDesease"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextDrug" label-for="reportTextDrug" label-class="strong-text" label="Фраза для блока отчета &quot;Лекарственные препараты&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextDrugId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportTextDrug"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextRiskFactor" label-for="reportTextRiskFactor" label-class="strong-text" label="Фраза для блока отчета &quot;Факторы внешней среды и особые состояния организма&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextRiskFactorId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportTextRiskFactor"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextLaboratoryError" label-for="reportTextLaboratoryError" label-class="strong-text" label="Фраза для блока отчета &quot;Лабораторные ошибки&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextLaboratoryErrorId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportTextLaboratoryError"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextParamRecommended" label-for="reportTextParamRecommended" label-class="strong-text" label="Фраза для блока отчета &quot;Рекомендуемые лабораторные исследования&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextParamRecommendedId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportTextParamRecommended"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group id="reportTextRecommendExamination" label-for="reportTextRecommendExamination" label-class="strong-text" label="Фраза для блока отчета &quot;Рекомендуемые инструментальные исследования&quot;">
        <b-row>
          <b-col cols="2">Код фразы</b-col>
          <b-col cols="2"><b-form-input id="zoneShowPriority" type="number" placeholder="" v-model="zone.reportTextRecommendExaminationId" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <ckeditor v-model="zone.reportTextRecommendExamination"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-form-group v-if="zone.paramType === 2 || zone.paramType === 7" id="propertyValueNameSynonym" label-for="propertyValueNameSynonym" label-class="strong-text" :label="zone.paramType === 2 ? 'Шаблон синонимов для качественной шкалы' : 'Шаблон синонимов для полуколичественной шкалы'">
        <b-textarea rows="5" max-rows="7" id="propertyValueNameSynonym" v-model="zone.propertyValueNameSynonym"></b-textarea>
      </b-form-group>

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {host} from "@/constants";

export default {
  props: {
    currentZone: Object
  },
  name: "ZoneValueEdit",
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        extraPlugins: 'colorbutton,colordialog',
      },
      zone: this.currentZone ? this.currentZone : {
        zoneId: null,
        zoneName: null,
        zoneShowPriority: 0,
        reportText: null,
        zoneGroupId: null,
        reportTextDesease: null,
        reportTextDrug: null,
        reportTextRiskFactor: null,
        reportTextLaboratoryError: null,
        reportTextParamRecommended: null,
        propertyValueNameSynonym: null,
        paramType: 1,
        reportTextRecommendExamination: null,
        reportTextId: null,
        reportTextDeseaseId: null,
        reportTextDrugId: null,
        reportTextRiskFactorId: null,
        reportTextLaboratoryErrorId: null,
        reportTextParamRecommendedId: null,
        reportTextRecommendExaminationId: null,
        coefB: 0,
        coefBGraphic: 10
      },
      zoneGroupOptions: [
        {value: null, text: ""},
        {value: 1, text: "Интервалы понижения"},
        {value: 2, text: "Интервалы повышения"}
      ],
      paramTypeOptions: [],
      // [
      //   {value: 1, text: "количественный"},
      //   {value: 2, text: "качественный"},
      //   {value: 3, text: "расчетный"},
      //   {value: 4, text: "комплексный"},
      //   {value: 5, text: "связанный"},
      //   {value: 6, text: "интервальный"},
      //   {value: 7, text: "полуколичественный"}
      // ],
      error: null
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentZone !== null) {
        method = 'PUT'
      }


      fetch(host + '/rest/zones', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.zone),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    },
    // onNamespaceLoaded( CKEDITOR ) {
    //   // Add external `placeholder` plugin which will be available for each
    //   // editor instance on the page.
    //   CKEDITOR.plugins.addExternal( 'colorbutton', '/node_modules/ckeditor4-vue/plugins/colorbutton', 'plugin.js' );
    //   CKEDITOR.plugins.addExternal( 'colordialog', '/node_modules/ckeditor4-vue/plugins/colordialog', 'plugin.js' );
    // }

  },
  mounted() {
    fetch(host + '/rest/dicts/paramtype')
        .then(response => response.json())
        .then(json => {
          this.paramTypeOptions = json.options
        })
  }
}
</script>

<style>

.ck-editor__editable {
  min-height: 150px;
  height: 150px;
}

.strong-text {
  font-weight: bold;
}

</style>