import Vue from 'vue'
import Router from 'vue-router'
import PatientList from "@/components/PatientList";
import Patient from "@/components/Patient";
import PatientEditForm from "@/components/patients/PatientEditForm";
import Home from "@/views/Home";
import AnalitList from "@/components/AnalitList";
import AnalitEditForm from "@/components/AnalitEditForm";
import RulesAndFormulas from "@/components/admin/rules/RulesAndFormulas";
import RubricatorList from "@/components/admin/rubricators/RubricatorList";
import FactorList from "@/components/admin/factors/FactorList";
import ZoneValueList from "@/components/admin/zones/ZoneValueList";
import DoctorList from "@/views/DoctorList";
import DynamicPatientInfoForm from "@/components/DynamicPatientInfoForm";
import PatientReportParams from "@/components/patients/PatientReportParams";
import PatientRecommendations from "@/components/PatientRecommendations";
import ExaminationFiles from "@/components/patients/ExaminationFiles";
import PatientReports from "@/components/patients/PatientReports";
import DoctorInfo from "@/components/DoctorInfo";
import ReportSettings from "@/views/admin/ReportSettings";
import ReportSettingsContainer from "./views/admin/ReportSettingsContainer";
import ZoneTabs from "./components/admin/zones/ZoneTabs";
import CustomerList from "./components/admin/customers/CustomerList";
import CustomerEdit from "./components/admin/customers/CustomerEdit";

Vue.use(Router)



export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: {layout: 'index', isAuth: null},
      component: Home
    },
    {
      path: '/reports/settings',
      component: ReportSettingsContainer,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'doctorinfo',
      path: '/doctorinfo',
      meta: {layout: 'cabinet', isAuth: true},
      component: DoctorInfo,
      props: true
    },
    {
      name: 'patients',
      path: '/patients',
      meta: {layout: 'cabinet', isAuth: true},
      component: PatientList
    },
    {
      name: 'patient',
      path: '/patients/:patientId',
      component: Patient,
      meta: {layout: 'cabinet', isAuth: true},
      props: true,
      children: [
        {
          name: 'anketa',
          path: '',
          component: DynamicPatientInfoForm,
          props: true,
          meta: {layout: 'cabinet', isAuth: true, title: 'Анкета пациента', img: require('@/assets/images/patients/02_Anketa.png')},
        },
        {
          name: 'examinations',
          path: 'examinations',
          component: PatientReportParams,
          props: true,
          meta: {layout: 'cabinet', isAuth: true, title: 'Ввод результатов', img: require('@/assets/images/patients/04_Vvod_rezultatov.png')},
        },
        {
          name: 'reports',
          path: 'reports',
          component: PatientReports,
          props: true,
          meta: {layout: 'cabinet', isAuth: true, title: 'Отчеты', img: require('@/assets/images/patients/05_Otcheti.png')},
        },
        {
          name: 'recommendations',
          path: 'recommendations',
          component: PatientRecommendations,
          props: true,
          meta: {layout: 'cabinet', isAuth: true, title: 'Назначения', img: require('@/assets/images/patients/03_Naznchenia.png')},
        },
        {
          name: 'files',
          path: 'files',
          component: ExaminationFiles,
          props: true,
          meta: {layout: 'cabinet', isAuth: true, title: 'Архив', img: require('@/assets/images/patients/06_Arxiv.png')},
        }
      ]
    },
    {
      path: '/patientsadd',
      component: PatientEditForm,
      meta: {layout: 'cabinet', isAuth: true, title: 'Создание пациента', img: require('@/assets/images/patients/01_Dannie_Pacienta.png')},
      props: true
    },
    {
      name: 'patientsedit',
      path: '/patientsedit/:patientId',
      component: PatientEditForm,
      meta: {layout: 'cabinet', isAuth: true, title: 'Редактирование пациента', img: require('@/assets/images/patients/01_Dannie_Pacienta.png')},
      props: true
    },
    {
      name: 'analits',
      path: '/analits',
      component: AnalitList,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'analitadd',
      path: '/analitedit',
      component: AnalitEditForm,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'analitedit',
      path: '/analitedit/:analitId',
      component: AnalitEditForm,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'rulesandformulas',
      component: RulesAndFormulas,
      path: '/rulesandformulas',
      meta: {isAuth: true},
      props: true
    },
    // {
    //   name: 'measurementunits',
    //   path: '/measurementunits',
    //   component: MeasurementUnitList,
    //   meta: {isAuth: true},
    //   props: true
    // },
    {
      name: 'rubricators',
      path: '/rubricators',
      component: RubricatorList,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'factors',
      path: '/factors',
      component: FactorList,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'zones',
      path: '/zones',
      component: ZoneTabs,
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'doctors',
      path: '/doctors',
      component: () => import('@/views/DoctorList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'docregister',
      path: '/docregister',
      meta: {layout: 'base', isAuth: false},
      component: () => import('@/views/DoctorRegister.vue'),
      props: true
    },
    {
      name: 'patientpwd',
      path: '/patientpwd/:urlParam',
      meta: {layout: 'base', isAuth: false},
      component: () => import('@/views/PatientLinkSetPassword.vue'),
      props: true
    },
    {
      name: 'pwdrecoverset',
      path: '/pwdrecoverset/:urlParam',
      meta: {layout: 'base', isAuth: false},
      component: () => import('@/views/PasswordRecoverSetPassword.vue'),
      props: true
    },
    {
      name: 'pwdrecover',
      path: '/pwdrecover',
      meta: {layout: 'base', isAuth: false},
      component: () => import('@/views/PasswordRecover.vue'),
      props: true
    },
    {
      name: 'doctoradd',
      path: '/doctoredit',
      component: () => import('@/views/DoctorEdit.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'doctoredit',
      path: '/doctoredit/:doctorId',
      component: () => import('@/views/DoctorEdit.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'scales',
      path: '/scales',
      component: () => import('@/components/admin/scales/ScaleValueList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'scaleranges',
      path: '/scaleranges',
      component: () => import('@/components/admin/scales/ScaleRangeValueList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'clinics',
      path: '/clinics',
      component: () => import('@/components/admin/doctors/ClinicList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'paramcodemappings',
      path: '/paramcodemappings',
      component: () => import('@/components/admin/paramcodemapping/ParamCodeMappings.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'pins',
      path: '/pins',
      component: () => import('@/components/admin/pin/PinList'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'specializations',
      path: '/specializations',
      component: () => import('@/components/admin/doctors/SpecializationList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'checklists',
      path: '/checklists',
      component: () => import('@/views/admin/CheckListList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'checklistadd',
      path: '/checklistedit',
      component: () => import('@/components/admin/checklist/CheckListEdit.vue'),
      props: true
    },
    {
      name: 'checklistedit',
      path: '/checklistedit/:checklistId',
      component: () => import('@/components/admin/checklist/CheckListEdit.vue'),
      props: true
    },
    {
      name: 'customers',
      path: '/customers',
      component: () => import('@/components/admin/customers/CustomerList.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'customer-add',
      path: '/customer-edit',
      component: () => import('@/components/admin/customers/CustomerEdit.vue'),
      meta: {isAuth: true},
      props: true
    },
    {
      name: 'customer-edit',
      path: '/customer-edit/:customerId',
      component: () => import('@/components/admin/customers/CustomerEdit.vue'),
      meta: {isAuth: true},
      props: true
    },
  ]
})