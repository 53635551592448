<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row mx-0">
      <b-tabs content-class="mt-0 background_E9F5EA_page_7" nav-class=" color_606D6D font-weight-bold" style="width: 100%;"
              active-nav-item-class="background_E9F5EA_page_7 tab-link__active"
              nav-item-class="color_606D6D"
              active-tab-class="background_EAF3EB" justified>
        <b-tab title="Выбор исследований" title-link-class="tab-link" class="pt-3" v-if="role === 2 || role ===1 ">
<!--          <date-range-picker v-model="dateRange"-->
<!--                             :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }">-->

<!--          </date-range-picker>-->

          <div class="report_date_range ml-2 mt-2 mb-3 edit-container-justify-start">
            <div class="col-9">
              <div class="report_date_range_title ml-1 mt-1 mb-1">Установить период для формирования отчета</div>
              <div class="date_picker_buttons edit-container-justify-start">
<!--                <date-picker type="date" class="date-range-config" range v-on:change="setCustomRange" v-model="dateRange" value-type="format" :format="'DD.MM.YYYY'" ></date-picker>-->
                <b-container class="m-0 pb-1 w-35">
                  <b-row>
                    <b-col class="pl-0 pr-1"><b-form-input id="dateFrom" class="edit-container__input-date30" type="date" placeholder="" v-on:change="setCustomRange"   :min="'2010-01-01'" :max="dateRange[1]" v-model="dateRange[0]" autocomplete="off"></b-form-input></b-col>
                    <b-col class="pl-0 pr-1"><b-form-input id="dateTo" class="edit-container__input-date30" type="date" placeholder="" v-on:change="setCustomRange"   :min="dateRange[0]" v-model="dateRange[1]" autocomplete="off"></b-form-input></b-col>
                  </b-row>
                </b-container>


<!--              :formatter="momentFormat"     -->
                <div class="edit-container30 cursor-pointer va-middle"  @click="setToday">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 1" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 1 ? 'edit-container__text30_active' : 'edit-container__text30'">Сегодня</div>
                  </div>
                </div>
                <div class="edit-container30 cursor-pointer va-middle"  @click="setLastDay">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 2" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 2 ? 'edit-container__text30_active' : 'edit-container__text30'">За 3 дня</div>
                  </div>
                </div>
                <div class="edit-container30 cursor-pointer"  @click="setLastWeek">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 3" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 3 ? 'edit-container__text30_active' : 'edit-container__text30'">За неделю</div>
                  </div>
                </div>
                <div class="edit-container30 cursor-pointer"  @click="setLastMonth">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 4" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 4 ? 'edit-container__text30_active' : 'edit-container__text30'">За месяц</div>
                  </div>
                </div>
                <div class="edit-container30 cursor-pointer"  @click="setLast3Month">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 5" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 5 ? 'edit-container__text30_active' : 'edit-container__text30'">За 3 месяца</div>
                  </div>
                </div>
                <div class="edit-container30 cursor-pointer"  @click="setAllTime">
                  <img src="@/assets/images/patients/reports/radio-selected.png" v-if="selectedFilter == 6" class="edit-container__icon30" >
                  <img src="@/assets/images/patients/reports/radio-empty.png" v-else class="edit-container__icon30" >
                  <div class="edit-container__input-container30">
                    <div :class="selectedFilter == 5 ? 'edit-container__text30_active' : 'edit-container__text30'">За всё время</div>
                  </div>
                </div>

                <!--              <button class="save_btn_common" @click="setToday">Сегодня</button>-->
                <!--              <button class="save_btn_common" @click="setLastDay">За 3 дня</button>-->
                <!--              <button class="save_btn_common" @click="setLastWeek">За неделю</button>-->
                <!--              <button class="save_btn_common" @click="setLastMonth">За месяц</button>-->
              </div>
              <!--            <div class="report_config">-->

              <!--&lt;!&ndash;              <button  v-if="role === 2" class="save_btn_common_big" @click="editExaminationResultsSettings()">Настройки отчетов</button>&ndash;&gt;-->
              <!--            </div>-->
              <div class="report_buttons mt-4">
                <button class="save_btn_common" @click="openComplexReportModal(1)" v-if="role === 2 && reportDetailedBlock == false">Сформировать подробный отчет</button>
                <button class="save_btn_common" @click="openComplexReportModal(2)" v-if="role === 2 && reportGeneralBlock == false">Сформировать обобщенный отчет</button>
                <button class="save_btn_common" @click="openComplexReportModal(3)" v-if="role === 2 && reportConciseBlock == false">Сформировать сокращенный отчет</button>
                <br/>
                <button class="save_btn_common" @click="openComplexReportModal(4)" v-if="reportPatientBlock == false">Сформировать отчет пациента</button>
                <button class="save_btn_common" @click="openComplexReportModal(5)" v-if="role === 2 && reportGraphicBlock == false">Сформировать графический отчет</button>
              </div>

            </div>
            <div class="col-3" style="position: relative;" >
              <div class="report_config"><b-link v-if="role === 2 && !permission.reportConfigBlock" class="reports_settings_link" @click="editExaminationResultsSettings()"><img src="@/assets/images/patients/reports/reports_settings.png"></b-link></div>
            </div>
<!--            <div class="instruction">-->
<!--              <strong>Выберите исследования из таблицы ниже для включения в обобщенный или сокращенный отчет</strong>-->
<!--            </div>-->
          </div>


          <div class="background_FFFFFF m-0 pt-4">
            <b-table v-if="role === 2 || role === 1" striped hover :items="reportParams | reportParamsFilter(dateRange)" :fields="fields" class="background_FFFFFF h-100"
                     :thead-class="'table-header'" :tbody-tr-class="rowClass" >
              <template v-slot:head(examinationDate)="data">
                <div class="column-head vertical-center">Дата ввода исследования</div>
              </template>
              <template v-slot:head(requestCode)="data">
                <div class="column-head vertical-center">Код заявки</div>
              </template>
              <template v-slot:head(paramFullName)="data">
                <div class="column-head vertical-center">Наименование аналита</div>
              </template>
              <template v-slot:head(val)="data">
                <div class="column-head vertical-center">Результат</div>
              </template>
              <template v-slot:head(paramReport)="data">
                <div class="column-head vertical-center">Интерпретация</div>
              </template>
              <template v-slot:head(buttons)="data">
                <div class="column-head vertical-center">
                  <nobr>
                    <b-link @click="deleteAllClientAnalits()" title="Удалить" class="delete_all_link">Удалить всё <img src="@/assets/images/patients/icon_delete_all.png"></b-link>
                  </nobr>

                </div>
              </template>
              <!-- A custom formatted column -->
              <template v-slot:cell(examinationDate)="data">
                <div class="vertical-center-container"><span class="vertical-center" >{{ new Date(data.item.examinationDate).toLocaleDateString() }}</span></div>
              </template>
              <template v-slot:cell(requestCode)="data">
                <div class="vertical-center-container"><span class="vertical-center" >{{ data.item.requestCode }}</span></div>
              </template>
              <template v-slot:cell(paramFullName)="data">
                <div class="vertical-center">
                  <b-icon :id="'param_def_' + data.item.paramId" icon="book"></b-icon>
                  <b-tooltip  :target="'param_def_' + data.item.paramId" triggers="hover">
                    <span v-html="data.item.paramDefinition"></span>
                  </b-tooltip> &nbsp;
                  {{data.item.paramName}}{{data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : '' }}
                </div>
              </template>
              <template v-slot:cell(val)="data">
                <div class="vertical-center">
                  <span v-if="data.item.paramType === 2 || (data.item.paramType === 7 && data.item.propertyName)">
                    <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.propertyName }}, {{ data.item.propertyValueName }}</b>
                  </span>
                          <span v-else>
                    <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.paramValue }} {{ data.item.measurementUnitName }}</b>
                  </span>
                  &nbsp;
                  <b-link v-if="(data.item.paramType === 1 || data.item.paramType === 3 || data.item.paramType === 10) && role != 1" @click="selectDynamicParam(data.item.paramName + (data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : ''), data.item.paramId)">
                    <b-icon icon="graph-up"></b-icon>
                  </b-link>
                  <b-link v-else @click="selectDynamicTableParam(data.item.paramName + (data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : ''), data.item.paramId)">
                    <b-icon icon="graph-up"></b-icon>
                  </b-link>

                </div>
              </template>
              <template v-slot:cell(paramReport)="data">
                <div class="vertical-center"><span v-html="data.item.paramReport"></span></div>
              </template>
              <template v-slot:cell(settings)="data">
                <div class="vertical-center">
                  <div><strong>Уровень отчета:</strong> {{data.item.reportLevelName}}</div>
                  <div><strong>Исключение из отчета нозологических форм:</strong>
                    <span v-if="!data.item.excludeSeldomDisease && !data.item.excludePriorityDisease">Включены все формы</span>
                    <span v-if="data.item.excludeSeldomDisease">Исключены редкие формы</span>
                    <span v-if="data.item.excludePriorityDisease">Исключены формы со слабыми связями с аналитом</span>
                  </div>
                  <div><strong>Учет влияния фармпрепаратов/-групп:</strong> {{data.item.reportViewName}}</div>
                </div>
              </template>
              <!--            <template v-slot:cell(checkboxes)="data">-->
              <!--              <div v-if="data.item.excludeSeldomDisease">Исключены из отчета редкие нозологические формы</div>-->
              <!--              <div v-if="data.item.excludePriorityDisease">Исключены из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов</div>-->
              <!--            </template>-->
              <template v-slot:cell(buttons)="data">
                <div class="vertical-center">
                  <nobr>

                    <b-link v-if="data.item.examinationFileId" class="ml-1" target="_blank" :to="'/rest/recognition/' + patientId + '/pdf/' + data.item.examinationFileId" title="Скачать лист назначений"><b-icon variant="success" icon="box-arrow-in-down" size="lg" ></b-icon></b-link>
                    <b-link v-if="(role == 2 || role == 3) && !permission.reportPreBlock" @click="openAnalitPdfReport(data.item)" class="ml-1" title="Просмотр предварительных отчетов для одного аналита" ><img src="@/assets/images/patients/icon_pdf.png"></b-link>
                    <b-link @click="editClientAnalit(data.item)" class="ml-1" title="Редактировать" ><img src="@/assets/images/patients/icon_edit.png"></b-link>
                    <b-link @click="deleteClientAnalit(data.item.paramClientId)" class="ml-1" title="Удалить"><img src="@/assets/images/patients/icon_delete.png"></b-link>
                  </nobr>
                </div>
              </template>
              <template v-slot:cell(selectDeselect)="data">
                <div class="vertical-center">
                  <nobr>
                    <b-link @click="selectDeselectItem(data.item)">
                      <b-icon title="Выбрать отчет" variant="success" :icon="data.item.isSelectedToReport === true ? 'toggle-on' : 'toggle-off'" size="lg"></b-icon>
                    </b-link>
                  </nobr>
                </div>
              </template>
            </b-table>


          </div>

        </b-tab>
        <b-tab title="Подробные отчеты" lazy  title-link-class="tab-link" class="background_FFFFFF" v-if="reportDetailedBlock == false && role === 2">
          <PatientComplexReports v-bind:patient-id="patientId" v-bind:report-type="1" ref="patientComplexReports1"></PatientComplexReports>
        </b-tab>
        <b-tab title="Обобщенные отчеты" lazy  title-link-class="tab-link" class="background_FFFFFF" v-if="reportGeneralBlock == false && role === 2">
          <PatientComplexReports v-bind:patient-id="patientId" v-bind:report-type="2" ref="patientComplexReports2"></PatientComplexReports>
        </b-tab>
        <b-tab title="Сокращенные отчеты" lazy title-item-class="mr-0" title-link-class="tab-link" class="background_FFFFFF" v-if="reportConciseBlock == false && role === 2">
          <PatientComplexReports v-bind:patient-id="patientId" v-bind:report-type="3" ref="patientComplexReports3"></PatientComplexReports>
        </b-tab>
        <b-tab title="Отчеты пациента" lazy title-item-class="mr-0" title-link-class="tab-link" class="background_FFFFFF" v-if="(reportPatientBlock == false && role === 2) || role === 1">
          <PatientComplexReports v-bind:patient-id="patientId" v-bind:report-type="4" ref="patientComplexReports4"></PatientComplexReports>
        </b-tab>
        <b-tab title="Графические отчеты" lazy title-item-class="mr-0" title-link-class="tab-link" class="background_FFFFFF" v-if="reportGraphicBlock == false && role === 2">
          <PatientComplexReports v-bind:patient-id="patientId" v-bind:report-type="5" ref="patientComplexReports5"></PatientComplexReports>
        </b-tab>
      </b-tabs>

      <b-modal id="dynamicParamInfoReport" no-close-on-backdrop no-close-on-esc hide-footer size="xl" :title="'Динамика аналита ' + this.currentParamName">
        <DynamicInfoChart v-bind:patient-id="patientId" v-bind:param-id="currentParamId"></DynamicInfoChart>
      </b-modal>
      <b-modal id="dynamicParamTableReport" no-close-on-backdrop no-close-on-esc hide-footer size="xl" :title="'Динамика аналита ' + this.currentParamName">
        <DynamicInfoReport v-bind:patient-id="patientId" v-bind:param-id="currentParamId"></DynamicInfoReport>
      </b-modal>

      <b-modal id="createClientReportParamModal" no-close-on-backdrop no-close-on-esc hide-footer size="xl" :title="currentAnalit ? 'Редактировать значение аналита' : 'Ввод значения аналита'">
        <ClientReportParamForm @save-success="afterSave" @save-cancel="cancelSave" v-bind:error="error" v-bind:patient-id="patientId" v-bind:current-analit="currentAnalit"></ClientReportParamForm>
      </b-modal>
    </div>
    <div class="row mx-0">
      <b-modal id="complexReportModal" hide-footer size="lg" :title="createComplexReportTitle">
        <form @submit.prevent="createComplexReport">
          <b-form-group label="Название отчета" label-cols="3">
            <b-form-input type="text" required size="255" v-model="blankName" @change="changeBlankName"></b-form-input>
          </b-form-group>
          <b-form-group label-cols="3" label="Комментарий к отчету" >
            <b-form-input type="text" size="255" v-model="blankComment"></b-form-input>
          </b-form-group>
          <div>
            <strong>Создать отчет для аналитов:</strong>
          </div>
          <div v-for="item in getSelectedReportParams()" :key="item.paramClientId">
            {{ item.examinationDate }}, {{item.paramName}}{{item.biomaterialTypeName ? ', ' + item.biomaterialTypeName : '' }}:
            <span v-if="item.paramType === 2 || (item.paramType === 7 && item.propertyName)">
              <b class="text-info">{{ item.propertyName }}, {{ item.propertyValueName }}</b>
            </span>
                <span v-else>
              <b class="text-info">{{ item.paramValue }}</b> <b>{{ item.measurementUnitName }}</b>
            </span>
          </div>
          <b-button v-if="!savingCreateReport" type="submit" class="btn-info" :disabled="savingCreateReport">
            Создать
          </b-button>
          <b-spinner v-if="savingCreateReport" variant="success"></b-spinner>
        </form>
      </b-modal>

      <b-modal id="examinationResultsSettings" v-if="!permission.reportConfigBlock" hide-footer hide-header size="xl" title="Настройка интерпретации результатов исследований">
        <div class="custom-modal-header w-100 mb-2">
          <div class="modal-title ">Настройка интерпретации результатов исследований</div>
          <div class="modal-close justify-content-end"><b-link @click="hideReportSettings" ><img src="@/assets/images/patients/icon_delete.png" title="Закрыть окно"></b-link></div>
        </div>
        <div class="container-fluid settings-modal">
          <div class="row pt-2">
            <div class="col">
              <span class="report-settings-title d-flex">
                Управление детализацией отчета
              </span>
              <div class="d-flex pt-3">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch id="excludeSeldomDiseaseCheckbox" name="excludeSeldomDiseaseCheckbox" v-model="clientReport.excludeSeldomDisease"></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeSeldomDiseaseCheckbox" class="pl-2">
                      <span class="report-settings-element" >Исключить из отчета редкие нозологические формы
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex pt-4">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch id="excludePriorityDiseaseCheckbox" name="excludePriorityDiseaseCheckbox" v-model="clientReport.excludePriorityDisease"></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludePriorityDiseaseCheckbox" class="pl-2">
                      <span class="report-settings-element" >Исключить из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <span class="report-settings-title d-flex">
                Управление рекомендациями в отчете
              </span>
              <div class="d-flex pt-3">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch v-model="clientReport.excludeBlackParam" id="excludeBlackParam" name="excludeBlackParam" @change="toogleExcludeBlackParam" ></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeBlackParam" class="pl-2">
                      <span class="report-settings-element" >Исключить детализирующие аналиты (черный цвет)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex pt-4">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch v-model="clientReport.excludeBlackGreenParam" id="excludeBlackGreenParam" name="excludeBlackGreenParam" @change="toogleExcludeBlackGreenParam" ></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeBlackGreenParam" class="pl-2">
                      <span class="report-settings-element" >Исключить уточняющие и детализирующие аналиты (черный и зеленый цвета)
                      </span>
                    </label>
                  </div>
                </div>
              </div>

            </div>
            <div class="col">
              <span class="report-settings-title d-flex">
                  Уровень отчета
              </span>
              <div class="d-flex pt-3">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="1" id="reportLevelRadio1" name="reportLevelRadio1" v-model="clientReport.reportLevel" />
                    <label for="reportLevelRadio1"></label>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="reportLevelRadio1" class="pl-2">
                      <span class="report-settings-element" >С детализацией нозологических форм, особых состояний и интерференций</span>
                    </label>
                  </div>
                  <div class="pl-2">
                    <div class="report-settings-subtext">
                      Дифференциальная диагностика с детализацией  нозологических форм и патологических состояний;
                      предметное распознавание особых состояний и факторов внешней среды;
                      исключение влияния конкретных лекарственных препаратов;
                      детализированное исключение лабораторных ошибок и интерференций.

                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex pt-1">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="2" id="reportLevelRadio2" name="reportLevelRadio2" v-model="clientReport.reportLevel" />
                    <label for="reportLevelRadio2"></label>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="reportLevelRadio2" class="pl-2">
                      <span class="report-settings-element" >Без детализации нозологических форм, особых состояний и интерференций</span>
                    </label>
                  </div>
                  <div class="pl-2">
                      <div class="report-settings-subtext">
                        Дифференциальная диагностика без детализации нозологических форм и патологических состояний;
                        групповое распознавание особых состояний и факторов внешней среды;
                        исключение влияния лекарственных препаратов на уровне фармакологических групп;
                        обобщённое исключение лабораторных ошибок и интерференций.

                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
<span class="report-settings-title d-flex">
                Вид части отчета по влиянию лекарственных препаратов на аналит
              </span>
              <div class="d-flex pt-3">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="1" id="reportViewRadio1" name="reportViewRadio1" v-model="clientReport.reportView" />
                    <label for="reportViewRadio1"></label>
                  </div>
                </div>
                <div>
                  <label for="reportViewRadio1" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет все лекарственные препараты
                    </span>
                  </label>
                </div>
              </div>
              <div class="d-flex pt-1">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="2" id="reportViewRadio2" name="reportViewRadio2" v-model="clientReport.reportView" />
                    <label for="reportViewRadio2"></label>
                  </div>
                </div>
                <div>
                  <label for="reportViewRadio2" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет только принимаемые пациентом лекарственные препараты
                    </span>
                  </label>
                </div>
              </div>
              <div class="d-flex pt-1">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="3" id="reportViewRadio3" name="reportViewRadio3" v-model="clientReport.reportView" />
                    <label for="reportViewRadio3"></label>
                  </div>
                </div>
                <div>
                  <label for="reportViewRadio3" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет все фармакологические группы
                    </span>
                  </label>
                </div>
              </div>
              <div class="d-flex pt-1">
                <div>
                  <div class="radio-item">
                    <input type="radio" value="4" id="reportViewRadio4" name="reportViewRadio4" v-model="clientReport.reportView" />
                    <label for="reportViewRadio4"></label>
                  </div>
                </div>
                <div>
                  <label for="reportViewRadio4" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет только те фармакологические группы,
                      в которые входит принимаемый пациентом лекарственный препарат
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="report-settings-title d-flex">
                Управление частотно-вероятностными событиями для обобщенных и сокращенных отчетов:
                нозологические формы, особые состояния, факторы внешней среды, лекарственные препараты и лабораторные ошибки
              </span>
              <div class="d-flex pt-3 col">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch v-model="clientReport.excludeLowProbabilityEvents" id="excludeLowProbabilityEvents" name="excludeLowProbabilityEvents" @change="toogleExcludeLowProbabilityEvents" ></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeLowProbabilityEvents" class="pl-2">
                      <span class="report-settings-element" >Исключить низковероятные события (черный цвет)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex pt-4 col">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch v-model="clientReport.excludeMiddleProbabilityEvents" id="excludeMiddleProbabilityEvents" name="excludeMiddleProbabilityEvents" @change="toogleExcludeMiddleProbabilityEvents"></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeMiddleProbabilityEvents" class="pl-2">
                      <span class="report-settings-element" >Исключить низковероятные  и умеренно вероятные события (черный и зеленый цвета)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="report-settings-title d-flex">
                  Включение в отчет классов нозологических форм на основе МКБ
                </span>

              <div class="d-flex pt-4 col-12">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch id="allDiseasesPCCCheckbox" name="allDiseasesPCCCheckbox" v-model="clientReport.allDisease"></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="allDiseasesPCCCheckbox" class="pl-2">
                                          <span class="report-settings-element" >Все классы нозологических форм
                                          </span>
                    </label>
                  </div>
                </div>
              </div>


              <div v-if="!clientReport.allDisease" class="d-flex pt-4">
                <div>
                  <div class="radio-item">
                  </div>
                </div>
                <div>
                  <div>
                    <label for="reportTypeRadio3" class="pl-2">
                                          <span class="report-settings-element" >
                            <b-form-checkbox-group switches style="column-count: 3" v-model="diseasePccList" :options="diseasePccListOptions" @change="changeDiseaseRcc"></b-form-checkbox-group>
                                          </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col">
              <span class="report-settings-title d-flex">
                  Исключение анкеты
                </span>

              <div class="d-flex pt-4 col-12">
                <div>
                  <div class="radio-item">
                    <b-checkbox switch id="excludeCheckListDiseasePCCCheckbox" name="excludeCheckListDiseasePCCCheckbox" v-model="clientReport.excludeCheckListDisease"></b-checkbox>
                  </div>
                </div>
                <div>
                  <div>
                    <label for="excludeCheckListDiseasePCCCheckbox" class="pl-2">
                                          <span class="report-settings-element" >Не учитывать анкету в отчетах
                                          </span>
                    </label>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>

      </b-modal>
    </div>
    <Loader v-bind:showLoader="loading" />
  </div>
</template>

<script>
import {host} from "@/constants";
import ClientReportParamForm from "@/components/patients/ClientReportParamForm";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import PatientComplexReports from "@/components/patients/PatientComplexReports";
import Loader from "@/components/Loader";
import DynamicInfoChart from "@/components/patients/DynamicInfoChart";
import DynamicInfoReport from "@/components/patients/DynamicInfoReport";
import moment from 'moment'

export default {
  props: ["patientId"],
  name: "PatientReports",
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      }
    }    ,
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      }
    }
  },
  filters: {
    reportParamsFilter(reportParams, dateRange) {
      // console.log(dateRange[0])
      // console.log(dateRange[1])
      const startDt = dateRange[0]//moment(dateRange[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
      const endDt = dateRange[1]//moment(dateRange[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      // console.log(startDt)
      // console.log(endDt)

      //return []
      return reportParams.filter(el => {
        return el.examinationDate >= startDt && el.examinationDate <= endDt
      })
    }
  },
  components: {
    DynamicInfoChart, DynamicInfoReport,
    Loader,
    PatientComplexReports,
    ClientReportParamForm, DatePicker//, DateRangePicker
  },
  watch: {
    'clientReport.reportType': function(newReportType)
    {
      if (!this.examinationResultsSettingsLoading)
        this.saveClientReport()
    },
    'clientReport.reportLevel': function(newReportLevel)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.reportView': function(newReportView)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.excludeSeldomDisease': function(newExcludeSeldomDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.excludePriorityDisease': function(newExcludePriorityDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.allDisease': function(newReportType) {
      if (!this.examinationResultsSettingsLoading) {
        this.saveClientReport()
        if (!newReportType && (!this.diseasePccList || this.diseasePccList.length == 0)) {
          this.diseasePccList = []
          this.diseasePccListOptions.forEach(el => {this.diseasePccList.push(el.value)})
          this.saveDiseaseRcc(this.diseasePccList)
        }
      }
    },
    'clientReport.excludeCheckListDisease': function(newExcludePriorityDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    }
    // 'clientReport.excludeBlackParam': function(newExcludeBlackParam)
    // {
    //   if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    // },
    // 'clientReport.excludeBlackGreenParam': function(newExcludeBlackGreenParam)
    // {
    //   if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    // },
    // 'clientReport.excludeLowProbabilityEvents': function(newExcludeLowProbabilityEvents)
    // {
    //   if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    // },
    // 'clientReport.excludeMiddleProbabilityEvents': function(newExcludeMiddleProbabilityEvents)
    // {
    //   if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    // }
  },
  data() {
    return {
      examinationResultsSettingsLoading: false,
      saving: false,
      dateRange: [this.$printDate2(this.addMonths(new Date(), -1)), this.$printDate2(new Date())],
      //dateRange: [this.addMonths(new Date(), -1), new Date()],
      momentFormat: {
        //[optional] Date to String
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        //[optional]  String to Date
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      error: null,
      createComplexReportTitle: '',
      blankNameFlg: true,
      blankName: 'Отчет от ' + new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', hour12: false
      }).format(new Date()),
      blankComment: '',
      reportType: 2,
      allDiseasesPCC:false,
      diseasePccListOptions: [],
      diseasePccList: [],
      savingCreateReport: false,
      clientReport: {
        reportType: null,
        reportLevel: null,
        reportView: null,
        excludeSeldomDisease: null,
        excludePriorityDisease: null,
        excludeCheckListDisease: null
      },

      fields: [
        {
          key: 'selectDeselect',
          label: '',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        // {
        //   key: 'createDate',
        //   label: 'Дата формирования отчета'
        // },
        {
          key: 'examinationDate',
          label: 'Дата ввода исследования',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'requestCode',
          label: 'Код заявки',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'paramFullName',
          label: 'Наименование аналита',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'val',
          label: 'Результат',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'paramReport',
          label: 'Интерпретация',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'buttons',
          label: '',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        }

      ],
      reportParams: [],
      currentAnalit: null,
      loading: false,
      selectedFilter: 4,
      currentParamId: 0,
      currentParamName: ''

    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.zoneId === 6 || item.zoneId === 16 || item.zoneId === 19 || item.zoneId === 25) return 'table-row-good'
      else return 'table-row'
    },
    changeBlankName() {
      this.blankNameFlg = false
    },
    toogleExcludeBlackParam(checked) {
      if (checked) {
        this.clientReport.excludeBlackGreenParam = false
      }
      this.saveClientReport()
    },
    toogleExcludeBlackGreenParam(checked) {
      if (checked) {
        this.clientReport.excludeBlackParam = false
      }
      this.saveClientReport()
    },
    toogleExcludeLowProbabilityEvents(checked) {
      if (checked) {
        this.clientReport.excludeMiddleProbabilityEvents = false
      }
      this.saveClientReport()
    },
    toogleExcludeMiddleProbabilityEvents(checked) {
      if (checked) {
        this.clientReport.excludeLowProbabilityEvents = false
      }
      this.saveClientReport()
    },
    changeDiseaseRcc(checked) {
      if (checked.length > 0) {
        this.saveDiseaseRcc(checked)
      } else {
        this.fetchPccList()
      }
    },
    saveDiseaseRcc(checked) {
      fetch(host + '/rest/patients/' + this.patientId + '/clientreport/diseases', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({diseases: checked}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              //this.$messageToast('Настройки отчетов')

            } else {

              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading = false
          }).catch(error => {
        this.$errorToast(error)
      })
    },
    editExaminationResultsSettings() {
      this.$bvModal.show('examinationResultsSettings')
    },
    hideReportSettings() {
      this.$bvModal.hide('examinationResultsSettings')
    },
    getSelectedReportParams() {
      return this.reportParams.filter(el => (el.isSelectedToReport))
    },
    openWindow(url) {
      let nw = window.open(url, "Report");
      console.log(nw);
      nw.focus();
    },
    createComplexReport() {
      let method = 'POST'
      let request = { complexReportName: this.blankName, complexReportComment: this.blankComment, reportType: this.reportType }
      let msgTitle = 'Отчёт '
      let reportTypeStr = 'ComplexDetailedReport'
      if (this.reportType == 1) {
        msgTitle = 'Подробный отчёт '
      } else if (this.reportType == 2) {
        msgTitle = 'Обобщённый отчёт '
        reportTypeStr = 'ComplexGeneralizedReport'
      } else if (this.reportType == 3) {
        msgTitle = 'Сокращённый отчёт '
        reportTypeStr = 'ComplexShortReport'
      } else if (this.reportType == 4) {
        msgTitle = 'Отчёт пациента '
        reportTypeStr = 'GraphicPatientReport'
      } else if (this.reportType == 5) {
        msgTitle = 'Графический отчёт '
        reportTypeStr = 'GraphicDoctorReport'
      }
      let toastId = 'Report_' + new Date().getTime()
      this.$reportCreationToast(toastId, msgTitle + request.complexReportName)
      const pid = this.patientId

      try {

        fetch(host + '/rest/patients/' + pid + '/reports/complex', {
          credentials: "include",
          method: method,
          body: JSON.stringify(request),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
            .then(response => response.json())
            .then(json => {
              this.$reportCreationHideToast(toastId)
              if (json.resultCode === 0) {



                if (this.$refs.patientComplexReports1) this.$refs.patientComplexReports1.fetchData()
                if (this.$refs.patientComplexReports2) this.$refs.patientComplexReports2.fetchData()
                if (this.$refs.patientComplexReports3) this.$refs.patientComplexReports3.fetchData()
                if (this.$refs.patientComplexReports4) this.$refs.patientComplexReports4.fetchData()
                if (this.$refs.patientComplexReports5) this.$refs.patientComplexReports5.fetchData()

                let urlStr = host + "/rest/reports/" + reportTypeStr + "/patient/" + pid + "/complexreport/" + json.entityId
                //console.log(urlStr)
                //this.openWindow(urlStr);
                //console.log('window open called')

                this.$reportToast('Created' + toastId,msgTitle + request.complexReportName, urlStr)

              } else {
                this.$errorToast('Ошибка создания отчёта ' + request.complexReportName + '. Попробуйте повторить позже.')
              }
            }).catch(error => {
                console.log(error)
                this.savingCreateReport = false
                this.$reportCreationHideToast(toastId)
                this.$errorToast('Ошибка создания отчёта ' + request.complexReportName + '. Попробуйте повторить позже.')
            })

        this.$bvModal.hide('complexReportModal')

        // let json = await response.json()
        //
        //
        //   //this.fetchPrescribedBlanks()
        // } else {
        //   this.$errorToast(json.resultCode + ' ' + json.message)
        // }

      } catch(error) {

      }
    },
    selectDynamicParam(paramName, paramId) {
      this.currentParamId = paramId
      this.currentParamName = paramName
      this.$bvModal.show('dynamicParamInfoReport')

    },
    selectDynamicTableParam(paramName, paramId) {
      this.currentParamId = paramId
      this.currentParamName = paramName
      this.$bvModal.show('dynamicParamTableReport')

    },
    openComplexReportModal(reportType) {
      if (reportType == 1) {
        this.createComplexReportTitle = 'Создание подробного отчета'
      } else if (reportType == 2) {
        this.createComplexReportTitle = 'Создание обобщенного отчета'
      } else if (reportType == 3) {
        this.createComplexReportTitle = 'Создание сокращенного отчета'
      } else if (reportType == 4) {
        this.createComplexReportTitle = 'Создание отчета пациента'
      } else if (reportType == 5) {
        this.createComplexReportTitle = 'Создание графического отчета'
      } else {
        this.createComplexReportTitle = 'Создание отчета'
      }
      this.reportType = reportType
      if (this.blankNameFlg) {
        this.blankName = 'Отчет от ' + new Intl.DateTimeFormat('ru-RU', {
          year: 'numeric', month: 'numeric', day: 'numeric',
          hour: 'numeric', minute: 'numeric', hour12: false
        }).format(new Date())
      }
      this.$bvModal.show('complexReportModal')
    },
    async deselectAll() {
      let response = await fetch(host + '/rest/patients/' + this.patientId + '/reportparamclients', {
        method: 'DELETE',
        credentials: 'include',
      })
      let json = await response.json()
      try {
        if (json.resultCode == 0) {
          for (let item of this.reportParams) {
            item.isSelectedToReport = false
          }
        }
      } catch(error) {
        this.error = error
        console.log(JSON.stringify(error))
      }
    },
    async selectDeselectItem(item) {

      if (!item.isSelectedToReport) {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/reportparamclients', {
          method: 'POST',
          body: JSON.stringify({paramClientId: item.paramClientId}),
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()
        try {
          if (json.resultCode == 0) {
            // this.reportParams.find( el => {
            //   if (el.paramClientId === item.paramClientId) {
            //     el.isSelectedToReport = true
            //     return true
            //   } else {
            //     return false
            //   }
            // })
            item.isSelectedToReport = true
          }
        } catch(error) {
          this.error = error
          console.log(JSON.stringify(error))
        }
      } else {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/reportparamclients/' + item.paramClientId, {
          method: 'DELETE',
          credentials: 'include',
        })
        let json = await response.json()
        try {
          if (json.resultCode == 0) {
            item.isSelectedToReport = false
          }
        } catch(error) {
          this.error = error
          console.log(JSON.stringify(error))
        }
      }
      console.log('isSelectedToReport = ' + JSON.stringify(this.reportParams))
      this.$forceUpdate()
    },
    async selectFiltered() {
      this.loading = true
      let selectedReport = []
      console.log('print date range = ' + this.dateRange)
      const startDt = this.dateRange[0]//moment(this.dateRange[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
      const endDt = this.dateRange[1]//moment(this.dateRange[1], 'DD.MM.YYYY').format('YYYY-MM-DD')

      for (let el of this.reportParams) {
        if (el.examinationDate >= startDt && el.examinationDate <= endDt
            && !selectedReport.find(el1 => (el1.paramId === el.paramId))) {
          selectedReport.push(el)
        }
      }
      // selectedReport = this.reportParams.filter(el => {
      //   return el.createDate >= this.dateRange[0] && el.createDate <= this.dateRange[1]
      // })
      selectedReport.forEach(item => {this.selectDeselectItem(item)})
      // for (let item of selectedReport) {
      //   await this.selectDeselectItem(item)
      // }
      this.loading = false
    },
    addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    },
    addDays(date, days) {
      //var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    },
    async setLastMonth() {
      this.selectedFilter = 4
      this.dateRange = [this.$printDate2(this.addMonths(new Date(), -1)), this.$printDate2(new Date())]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setLast3Month() {
      this.selectedFilter = 5
      this.dateRange = [this.$printDate2(this.addMonths(new Date(), -3)), this.$printDate2(new Date())]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setAllTime() {
      this.selectedFilter = 6
      this.dateRange = [this.$printDate2(new Date(2010, 0, 1)), this.$printDate2(new Date())]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setLastWeek() {
      this.selectedFilter = 3
      this.dateRange = [this.$printDate2(this.addDays(new Date(), -7)), this.$printDate2(new Date())]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setLastDay() {
      this.selectedFilter = 2
      this.dateRange = [this.$printDate2(this.addDays(new Date(), -3)), this.$printDate2(this.addDays(new Date(), 0))]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setToday() {
      this.selectedFilter = 1
      this.dateRange = [this.$printDate2(new Date()), this.$printDate2(new Date())]
      await this.deselectAll()
      await this.selectFiltered()
    },
    async setCustomRange(date, type) {
      console.log('setCustomRange called')
      await this.deselectAll()
      await this.selectFiltered()
    },
    openAnalitPdfReport(item = null) {
      let reportType = 'AnalitDoctor'
      if (item.paramType === 2) {
        reportType = 'QualAnalitDoctor'
      } else if (item.paramType === 7) {
        reportType = "SemiQuanAnalitDoctor"
      }
      window.open(host + "/rest/reports/" + reportType + "/patient/" + this.patientId + "/analit/" + item.paramClientId , "_blank");

    },
    editClientAnalit(item = null) {
      this.currentAnalit = item
      this.$bvModal.show('createClientReportParamModal')
    },
    deleteAllClientAnalits () {
      if (confirm('Вы уверены, что хотите удалить все аналиты?')) {
        this.loading = true;
        fetch(host + '/rest/params/clientreport/forclient/' + this.patientId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()
              } else {
              }
            }).catch(error => {
            })
      }
    },
    deleteClientAnalit(paramClientId) {

      this.error = null;
      if (confirm('Вы уверены, что хотите удалить аналит?')) {
        this.loading = true;
        fetch(host + '/rest/params/clientreport/' + paramClientId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.reportParams = this.reportParams.filter(item => item.paramClientId !== paramClientId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    cancelSave() {
      this.$bvModal.hide('createClientReportParamModal')
    },
    afterSave() {
      this.$bvModal.hide('createClientReportParamModal')
      this.loading = true
      setTimeout(() => {
        this.fetchData()
      }, 2000)

    },
    saveClientReport() {
      fetch(host + '/rest/patients/' + this.patientId + '/clientreport', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.clientReport),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              //this.$messageToast('Настройки отчетов')

            } else {

              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading = false
          }).catch(error => {
        this.$errorToast(error)
      })
    },
    fetchData() {
      fetch(host + '/rest/params/clientreport/' + this.patientId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {

            var reportParams = json.reportParams
            this.loading = false


            fetch(host + '/rest/patients/' + this.patientId + '/reportparamclients', {
              credentials: "include"
            })
                .then(response => response.json())
                .then(json => {
                  let reportClientParams = json.reportClientParams
                  for (let p of reportParams) {
                    if (reportClientParams.find(el => (el.paramClientId == p.paramClientId))) {
                      p.isSelectedToReport = true
                    } else {
                      p.isSelectedToReport = false
                    }
                  }
                  this.reportParams = reportParams
                  //console.log(JSON.stringify(this.reportParams))
                }).catch(error => {
              console.log(error)
              this.loading = false
            })




            //console.log(JSON.stringify(json))
          }).catch(error => {
        console.log(error)
        this.loading = false
      })

      fetch(host + '/rest/patients/' + this.patientId + '/clientreport' , {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.clientReport = json.clientReport

          }).catch(error => {
        console.log(error)
        this.examinationResultsSettingsLoading = false
      })

      fetch(host + '/rest/factors/Disease/pcc' , {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.diseasePccListOptions = json.options

          }).catch(error => {
        console.log(error)
      })

      this.fetchPccList()

    },
    fetchPccList() {
      fetch(host + '/rest/patients/' + this.patientId + '/clientreport/diseases' , {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.diseasePccList = json.diseases
            this.examinationResultsSettingsLoading = false

          }).catch(error => {
        console.log(error)
        this.examinationResultsSettingsLoading = false
      })
    }
  },
  mounted: function () {
    this.examinationResultsSettingsLoading = true
    this.fetchData()
  }
}
</script>

<style scoped>

</style>