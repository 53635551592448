<template>
  <b-container fluid>
    <template v-if="permission.paramPrescribedBlock">
      <b-row>
        <div><span class="patients m-auto pl-2" style="color: #606D6D!important;">Доступ запрещён</span></div>
      </b-row>

    </template>
    <template v-else>
      <b-row class="mt-0">
        <PrescribedBlanksTable v-bind:patient-id="patientId" v-bind:is-filtered="1" ref="prescribedBlanksTable"></PrescribedBlanksTable>
      </b-row>
      <b-row v-if="role === 2">
        <b-col cols="6">
          <span class="block-title-green">Выбор исследований</span>
        </b-col>
        <b-col cols="6">
          <span class="block-title-green">Назначение исследований</span>
        </b-col>
      </b-row>
      <b-row v-if="role === 2">
        <b-col cols="6">
          <b-tabs content-class="mt-0 background_E9F5EA_page_7" nav-class=" color_606D6D font-weight-bold" style="width: 100%;"
                  active-nav-item-class="background_E9F5EA_page_7 tab-link__active"
                  nav-item-class="color_606D6D"
                  active-tab-class="background_E9F5EA_page_7 " justified>
            <b-tab title=""  title-link-class="tab-link"  class="p-2">
              <template #title>
                <span  class="tab-title">Анкета пациента</span>
              </template>
              <PatientQuestionnaireRecommendations @selectParam="selectParam" @selectParams="selectParams" v-bind:patient-id="patientId"></PatientQuestionnaireRecommendations>
            </b-tab>
            <b-tab title="Рекомендации из отчета" title-item-class="mr-0"  title-link-class="tab-link mr-0"  class="p-2">
              <PatientRecommendRecommendations @selectParam="selectParamWithParent" @selectParams="selectParamsWithParent" v-bind:patient-id="patientId"></PatientRecommendRecommendations>
            </b-tab>
            <b-tab  class="p-2" title-link-class="tab-link" v-for="r in rubricators"  :key="r.rubricatorId" :title="r.text">
              <PatientRubricatorRecommendations @selectParam="selectParam" @selectParams="selectParams" v-bind:rubr="r"></PatientRubricatorRecommendations>
            </b-tab>
            <b-tab title=""  title-link-class="tab-link" class="p-2">
              <template #title>
                <span  class="tab-title">Поиск</span>
              </template>
              <PatientSearchRecommendations @selectParam="selectParam" @selectParams="selectParams"></PatientSearchRecommendations>
            </b-tab>
            <b-tab title="Персонализированные комплексы" title-item-class="mr-0"  title-link-class="tab-link mr-0"  class="p-2">
              <ParamsTemplateRecommendations v-bind:patient-id="patientId" @afterSelectParamsTemplate="getPatientPrescribedParams" ref="paramsTemplates"></ParamsTemplateRecommendations>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col cols="6">
          <b-container fluid>
            <b-row>
              <b-col cols="8">
                <button class="save_btn_common" @click="showModalBlankName()">Сформировать бланк назначений</button>
                <button class="save_btn_common" @click="showModalParamsTemplate()">Создать шаблон для выбранных назначений</button>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-link @click="deleteAllMeasurementUnit()" title="Удалить" class="delete_all_link">Удалить весь список <img src="@/assets/images/patients/icon_delete_all.png"></b-link>&nbsp;
              </b-col>


              <!--            <button class="save_btn_common" @click="deleteAllMeasurementUnit()">Удалить все</button>-->
            </b-row>
            <b-row v-for="(item) in recommendedParams" :key="item.paramId" class="prescribedRow">
              <b-col>
                <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
                <!--              <b-icon v-if="item.omsCode" variant="info" icon="award-fill" size="lg" ></b-icon>-->
              </b-col>
              <b-col cols="9">
                <b-icon :id="'param_ra_def_' + item.paramId" icon="book"></b-icon>
                <b-tooltip  :target="'param_ra_def_' + item.paramId" triggers="hover">
                  <span v-html="item.paramDefinition"></span>
                </b-tooltip> &nbsp;
                {{ item.paramName }}, {{ item.biomaterialTypeName }}
              </b-col>
              <b-col cols="2"><b-link @click="deletePrescribedAnalit(item)">Удалить</b-link></b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row v-if="role === 2">
        <b-modal id="prescribedBlankModal" hide-footer size="lg" title="Название бланка назначений">
          <form @submit.prevent="sendToPatient">
            <b-form-group label="Название бланка назначений" label-cols="3">
              <b-form-input type="text" required size="255" v-model="blankName"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="3" label="Комментарий к бланку назначений" >
              <b-form-input type="text" size="255" v-model="blankComment"></b-form-input>
            </b-form-group>
            <b-button type="submit" class="btn-info" :disabled="saving">
              {{ blankId ? 'Сохранить' : 'Отправить бланк назначений пациенту'}}<b-spinner v-if="saving" variant="light" small></b-spinner>
            </b-button>
          </form>
        </b-modal>


        <b-modal id="createParamsTemplate" hide-footer size="lg" title="Создание шаблона назначений">
          <form @submit.prevent="createParamsTemplate">
            <b-form-group label="Название шаблона" label-cols="3">
              <b-form-input type="text" required size="255" v-model="paramsTemplateName"></b-form-input>
            </b-form-group>
            <b-button type="submit" class="btn-info" :disabled="saving">
              Сохранить<b-spinner v-if="saving" variant="light" small></b-spinner>
            </b-button>
          </form>
        </b-modal>
      </b-row>
      <b-row><Loader v-bind:show-loader="showLoader"></Loader> </b-row>

    </template>

  </b-container>
</template>

<script>
import PatientQuestionnaireRecommendations from "@/components/patients/PatientQuestionnaireRecommendations";
import {host} from "@/constants";
import PatientRubricatorRecommendations from "@/components/patients/PatientRubricatorRecommendations";
import PatientSearchRecommendations from "@/components/patients/PatientSearchRecommendations";
import PrescribedBlanksTable from "@/components/patients/PrescribedBlanksTable";
import PatientRecommendRecommendations from "@/components/patients/PatientRecommendRecommendations";
import Loader from "@/components/Loader";
import ParamsTemplateRecommendations from "@/components/patients/ParamsTemplateRecommendations";
export default {
  props: ["patientId"],
  name: "PatientRecommendations",
  components: {
    ParamsTemplateRecommendations,
    Loader,
    PrescribedBlanksTable,
    PatientSearchRecommendations, PatientRubricatorRecommendations, PatientQuestionnaireRecommendations, PatientRecommendRecommendations},
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      }
    }
  },
  data() {
    return {
      overlayShow: true,
      recommendedParams: [],
      blankId: null,
      blankName: 'Назначения от ' + new Intl.DateTimeFormat('ru-RU').format(new Date()),
      blankComment: '',
      paramsTemplateName: '',
      saving: false,
      prescribedBlanks:[],
      rubricators: [],
      showLoader: false,
      prescribedBlanksFields:[
        {
          key: 'createDate',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'prescribedBlankName',
          label: 'Название',
          sortable: false
        },
        {
          key: 'prescribedBlankComment',
          label: 'Комментарий к бланку',
          sortable: false
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ]
    }
  },
  filters: {
    prescribedBlanksFilter: function (options) {
      return options.filter((el, index) => { return index < 3})

    }
  },
  methods: {
    async createParamsTemplate() {
      try {
        this.saving = true
        let response = await fetch(host + '/rest/params-templates', {
          method: "POST",
          credentials: "include",
          headers: {'Content-Type': 'application/json; charset=utf-8'},
          body: JSON.stringify({paramsTemplateName: this.paramsTemplateName, clientId: this.patientId})
        })
        let json = await response.json()
        if (json.resultCode === 0) {
          this.$messageToast('Шаблон назначений ' + this.paramsTemplateName)
          this.$bvModal.hide('createParamsTemplate')
          this.$refs.paramsTemplates.fetchParamsTemplatesSync()
        } else {
          this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
        }
        this.saving = false


      } catch(error) {
        console.log(error)
        this.saving = false
      }
    },
    selectParam(item, isToast = true) {
      if (this.recommendedParams.find(el => (el.paramId === item.paramId))) {
        if (isToast) this.$messageToast("Аналит уже есть в назначениях")
        return
      }
      fetch(host + '/rest/patients/' + this.patientId + '/params/prescribed', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(item),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.recommendedParams.push(item)
              if (isToast) this.$messageToast('Лист назначения')
            } else {
              this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
            }
          }).catch(error => {
            this.$errorToast("Ошибка: " + error)
          })

    },
    selectParams(items) {
      for (let item of items) {
        this.selectParam(item, false)
      }
    },
    async selectParamWithParent(item, isToast = true, isGetPrescribedParams = true) {
      let sendItem = {
        paramId: item.paramId,
        checkListParamState: item.checkListParamState,
        paramCount: item.paramCount,
        priorityReport: item.priorityReport
      }
      if (item.parentParamId) {
        sendItem.paramId = item.parentParamId
      }
      if (this.recommendedParams.find(el => (el.paramId === item.paramId || el.paramId === item.parentParamId))) {
        if (isToast) this.$messageToast("Аналит уже есть в назначениях")
        return
      }
      try {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/params/prescribed', {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(sendItem),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode === 0) {
          if (isGetPrescribedParams) {
            this.getPatientPrescribedParams()
          }

          if (isToast) this.$messageToast('Лист назначения')
        } else {
          this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
        }
      } catch (error) {
        this.$errorToast("Ошибка: " + error)
      }
    },
    async selectParamsWithParent(items) {
      this.showLoader = true
      for (let item of items) {
        await this.selectParamWithParent(item, false, false)
      }
      this.getPatientPrescribedParams()
      this.showLoader = false
    },
    getPatientPrescribedParams() {
      fetch(host + '/rest/patients/' + this.patientId + '/params/prescribed', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.recommendedParams = json.params

          }).catch(error => {
        console.log(error)

      })
    },
    async deletePrescribedAnalit(item) {

      try {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/params/prescribed/' + item.paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        await response.json()
      } catch (e) {
        console.log(e)
      }

      this.recommendedParams.splice(this.recommendedParams.indexOf(item), 1)
    },
    sendToPatient() {
      this.saving = true

      let method = 'POST'
      let request = { prescribedBlankName: this.blankName, prescribedBlankComment: this.blankComment }
      if (this.blankId) {
        method = 'PUT'
        request = { prescribedBlankName: this.blankName, prescribedBlankComment: this.blankComment, prescribedBlankId: this.blankId }
      }
      fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: method,
        body: JSON.stringify(request),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {

            this.saving = false
            if (json.resultCode === 0) {
              this.$messageToast('Бланк назначений')
              this.$bvModal.hide('prescribedBlankModal')
              //this.fetchPrescribedBlanks()
              this.$refs.prescribedBlanksTable.fetchPrescribedBlanks()
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
            console.log(error)
            this.saving = false
          })
    },
    showModalParamsTemplate() {
      this.$bvModal.show('createParamsTemplate')
    },
    showModalBlankName(item = null) {
      if (item) {
        this.blankId = item.prescribedBlankId
        this.blankName = item.prescribedBlankName
        this.blankComment = item.prescribedBlankComment
      } else {
        this.blankId = null
        this.blankName = 'Назначение от ' + new Intl.DateTimeFormat('ru-RU').format(new Date())
        this.blankComment = null
      }
      this.$bvModal.show('prescribedBlankModal')
    },
    fetchPrescribedBlanks() {
      fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: 'GET'
      })
          .then(response => response.json())
          .then(json => {

            this.saving = false
            if (json.resultCode === 0) {
              this.prescribedBlanks = json.blanks
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        console.log(error)
        this.saving = false
      })
    },
    showDetails(data) {
      this.overlayShow = true
      data.toggleDetails()

      if (!data.item.details) {
        fetch(host + '/rest/patients/' + this.patientId + '/prescribes/' + data.item.prescribedBlankId + '/params', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              data.item.details = json.params
              console.log(json)
              this.overlayShow = false
            }).catch(error => {
          this.overlayShow = false
        })
      } else {
        this.overlayShow = false
      }
    },
    openAnalitPdfReport(item = null) {
      window.open(host + "/rest/reports/PrescribedBlank/patient/" + this.patientId + "/prescribed/" + item.prescribedBlankId , "_blank");

    },
    deleteMeasurementUnit(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить бланк назначений "' + paramName + '"?')) {
        fetch(host + '/rest/patients/' + this.patientId + '/prescribes/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.prescribedBlanks = this.prescribedBlanks.filter(item => item.prescribedBlankId !== paramId)

              } else {

                this.error = json.message;
              }

            }).catch(error => {

              console.log(JSON.stringify(error))
            })
      }
    },
    deleteAllMeasurementUnit() {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить все назначения?')) {
        fetch(host + '/rest/patients/' + this.patientId + '/params/prescribed/' , {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              this.getPatientPrescribedParams()
            }).catch(error => {
          console.log(JSON.stringify(error))
        })
      }
    },
    fetchRubricators() {
      fetch(host + '/rest/rubricators/forDoctor', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.rubricators = json.options

          })
    }
  },
  mounted() {
    this.getPatientPrescribedParams()
    //this.fetchPrescribedBlanks()
    this.fetchRubricators()
  }
}
</script>

<style scoped>

.prescribedRow:hover {
  background: rgba(64, 238, 153, 0.50);
}

.mt-3 {
  width: 100%;
}

button {
  margin-right: 0.5rem;
}

</style>