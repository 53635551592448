<template>
  <b-container fluid>
    <b-form @submit.prevent="updatePhrases()">
      <b-row v-for="(item) in items" :key="item.reportPhraseId">
        <b-container fluid>
          <b-row class="pt-2">
            <b>{{ item.reportPhraseName }}</b>
          </b-row>
          <!-- -->
          <b-row class="pt-2" v-if="phrasesItems.find(el => el.diseaseId == item.diseaseId)">
            <b-col cols="2">
              Краткий заголовок
            </b-col>
            <b-col cols="10">
              <b-form-input type="text" required max="128" :id="'phraseShort'+item.diseaseId"
                            v-model="phrasesItems.find(el => el.diseaseId == item.diseaseId).diseasePatientReportShort"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="pt-2" v-if="phrasesItems.find(el => el.diseaseId == item.diseaseId)">
            <b-col cols="2">
              Полный заголовок
            </b-col>
            <b-col cols="10">
              <b-textarea rows="2" max-rows="7" :id="'phrase'+item.diseaseId"
                          v-model="phrasesItems.find(el => el.diseaseId == item.diseaseId).diseasePatientReport"></b-textarea>
            </b-col>
          </b-row>
          <!-- -->
          <b-row class="pt-2">
            <b-col cols="2">
              Текст фразы отчёта
            </b-col>
            <b-col cols="10">
              <b-textarea rows="5" max-rows="7" :id="'phrase'+item.reportPhraseId" v-model="item.reportPhraseText"></b-textarea>
            </b-col>
          </b-row>
        </b-container>


<!--        <b-form-group :id="'labelPhrase'+item.reportPhraseId" :label-for="'phrase'+item.reportPhraseId" class="w-100" :label="item.reportPhraseName">-->
<!--          <b-textarea rows="5" max-rows="7" :id="'phrase'+item.reportPhraseId" v-model="item.reportPhraseText"></b-textarea>-->
<!--        </b-form-group>-->
      </b-row>
      <b-row class="pt-4">
        <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>
      </b-row>

    </b-form>
    <Loader v-bind:showLoader="loading"/>
  </b-container>
</template>

<script>
import {host} from "@/constants"
import Loader from "../../Loader";
export default {
  name: "ZoneReportPhrases",
  props: ["reportType"],
  components: {Loader},
  data() {
    return {
      loading: false,
      items: [],
      phrasesItems: []
    }
  },
  mounted() {
    console.log('ZoneReportPhrases')
    this.fetchData()
  },
  methods: {
    updatePhrases() {
      this.loading = true
      fetch(host + '/rest/reports/phrases', {
        method: 'PUT',
        credentials: "include",
        body: JSON.stringify(this.items),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Фразы', 500)
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading=false
          }).catch(er => {
            this.$errorToast('Неизвестная ошибка')
            this.loading=false
          })

      if (this.reportType == 5) {
        this.loading = true
        fetch(host + '/rest/reports/phrases/disease', {
          method: 'PUT',
          credentials: "include",
          body: JSON.stringify({reportDiseasePhrases: this.phrasesItems}),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
            .then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.$messageToast('Фразы', 500)
              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }
              this.loading = false
            }).catch(er => {
          this.$errorToast('Неизвестная ошибка')
          this.loading = false
        })
      }


    },

    fetchData() {
      this.loading=true
      fetch(host + '/rest/reports/phrases/' + this.reportType, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.reportPhrases
            this.loading = false
            console.log(json)

          }).catch(er => {
        this.$errorToast('Неизвестная ошибка')
        this.loading=false
      })

      if (this.reportType == 5) {
        this.loading = true
        fetch(host + '/rest/reports/phrases/disease', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.phrasesItems = json.reportDiseasePhrases
              this.loading = false
              console.log(json)

            }).catch(er => {
          this.$errorToast('Неизвестная ошибка')
          this.loading = false
        })
      }

    }




  }
}
</script>

<style scoped>

</style>