<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="font-weight-bold">
      <b-tab title="Параметры аналита" active>
        <b-container fluid>
          <b-row class="newAttr">
            <b-button variant="info" to="/analitedit">Создать аналит</b-button>
          </b-row>
          <b-row class="newAttr">
            <div v-if="error" class="alert alert-danger">
              {{error}}
            </div>
          </b-row>
          <b-row class="newAttr">
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon variant="info" icon="search" size="lg"></b-icon>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="searchRequest" autocomplete="off"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row class="newAttr">
            <b-table striped hover :items="items | filterItems(searchRequest)" :fields="fields" :sort-by.sync="sortBy" ref="analitTable"
                     :filter="paramTypeFilter" :filter-function="filterAnalitList">
              <template v-slot:head(paramTypeName)="data">
                Тип аналита
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <b-icon variant="info" icon="funnel-fill" size="lg"></b-icon>
                  </template>
                  <b-dropdown-item-button @click="selectParamType(null)" :active="paramTypeFilter === null">все</b-dropdown-item-button>
                  <b-dropdown-item-button v-for="(item, index) of paramTypeOptions" :key="index" @click="selectParamType(item)" :active="paramTypeFilter == item.value.toString()">{{item.text}}</b-dropdown-item-button>
                </b-dropdown>

              </template>
              <!-- A custom formatted column -->
              <template v-slot:head(metabolomic)="data">
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <b-icon variant="info" icon="funnel-fill" size="lg"></b-icon>
                  </template>
                  <b-dropdown-item-button @click="selectMetabolomic(null)" :active="paramTypeFilter === null">все</b-dropdown-item-button>
                  <b-dropdown-item-button @click="selectMetabolomic('metabolomic')" :active="paramTypeFilter == true">метаболомные</b-dropdown-item-button>
                </b-dropdown>
              </template>
              <template v-slot:cell(metabolomic)="data">
                <img src="@/assets/images/Icon_Metabolomnie_Anliti_Final_20x20.png" v-if="data.item.metabolomic">

              </template>
              <template v-slot:cell(paramCode)="data">
                {{ data.item.paramCode }}
                <h3>
                  <b-icon v-if="data.item.emptyDummy" variant="danger" icon="dash-circle-fill" size="lg"></b-icon>
                  <b-icon v-if="data.item.debugOnly" variant="warning" icon="cpu" title="Отладочный аналит" size="lg"></b-icon>
                  <b-icon v-if="data.item.infectious" variant="warning" icon="disc" title="Инфекционный аналит" size="lg"></b-icon>
                </h3>
              </template>
              <template v-slot:cell(paramFullName)="data">
                  <b>{{ data.item.paramName }}</b><b v-if="data.item.biomaterialTypeName">, {{data.item.biomaterialTypeName}}</b>
              </template>
              <template v-slot:cell(individualCoefOfVariation)="data">
                {{ data.item.individualCoefOfVariation }} {{data.item.paramFormulaName}}
                <h3>
    <!--              <b-icon v-if="data.item.icvSwitchOff" variant="info" icon="file-earmark-excel" size="lg"></b-icon>-->
                  <b-icon v-if="data.item.icvIsAbsent" variant="info" icon="file-earmark" size="lg"></b-icon>
                </h3>
              </template>
              <template v-slot:cell(paramTypeName)="data">
                {{ data.item.paramTypeName }} <br />
                {{ data.item.formulaForCalculateParam }}
              </template>
              <template v-slot:cell(hiddenForSearch)="data">
                <h3>
                  <b-icon variant="info" :icon="data.item.hiddenForSearch ? 'eye-fill' : 'eye'" size="lg"></b-icon>
                </h3>
              </template>
              <template v-slot:cell(buttons)="data">
                <h3 class="buttons">
                  <nobr>
                    <b-link @click="rowClickHandler(data.item)">
                      <b-icon variant="info" icon="pencil" size="lg"></b-icon>
                    </b-link>
                    &nbsp;
                    <b-link @click="deleteAnalit(data.item.paramId, data.item.paramName)">
                      <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
                    </b-link>
                  </nobr>
                </h3>
              </template>

            </b-table>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab title="Приоритеты аналитов">
        <AnalitSortOrder v-bind:params="items"></AnalitSortOrder>
      </b-tab>
    </b-tabs>
    <Loader v-bind:showLoader="loading" />
<!--    <Loader v-if="loading" />-->
  </div>
</template>

<script>
  import Loader from '@/components/Loader'
  import {host} from '@/constants'
  import {mapGetters, mapActions} from 'vuex'
  import AnalitSortOrder from "@/components/admin/analits/AnalitSortOrder";
  export default {
    name: "AnalitList",
    components: {
      AnalitSortOrder,
      Loader
    },
    computed: {
      searchRequest: {
        get() {
          return this.$store.getters.searchRequest
        },
        set(value) {
          this.$store.commit('updateSearchRequest', value)
        }
      },
      paramTypeFilter: {
        get() {
          return this.$store.getters.paramTypeFilter
        },
        set(value) {
          this.$store.commit('updateParamTypeFilter', value)
        }
      }
    },
    data() {
      return {
        tabIndex: 0,
        fields: [
          {
            key: 'paramCode',
            label: 'Код аналита',
            sortable: true
          },
          {
            key: 'metabolomic',
            label: '',
            sortable: false
          },
          {
            key: 'paramFullName',
            label: 'Аналит',
            sortable: true
          },
          {
            key: 'paramSynonym',
            label: 'Синоним',
            sortable: true
          },
          {
            key: 'measurementUnitName',
            label: 'Единица измерения',
            sortable: true
          },
          {
            key: 'paramTypeName',
            label: 'Тип аналита',
            sortable: false
          },
          {
            key: 'hiddenForSearch',
            label: '',
            sortable: false
          },
          {
            key: 'biomaterialTypeName',
            label: 'Тип биоматериала',
            sortable: true
          },
          {
            key: 'omsCode',
            label: 'Приказ МЗ РФ №804',
            sortable: true
          },
          {
            key: 'individualCoefOfVariation',
            label: 'Способ расчета интервалов',
            sortable: true
          },
          // {
          //   key: 'paramDefinition',
          //   label: 'Описание',
          //   sortable: true
          // },
          {
            key: 'buttons',
            label: ' ',
            sortable: false
          }
        ],
        items: [],
        loading: true,
        //searchRequest: null,
        sortBy: 'paramCode',
        error: null,
        paramTypeOptions: []
      }
    },
    filters: {
      filterItems: function (items, searchRequest) {
        if (searchRequest !== null && searchRequest !== "" ) {
          return items.filter((el) => (el.searchString.toLowerCase()).includes(searchRequest.toLowerCase()))
        }
        return items
      }
    },
    methods: {
      selectParamType(item) {
        if (item) {
          this.paramTypeFilter = item.value.toString()
        } else {
          this.paramTypeFilter = null
        }

      },
      selectMetabolomic(item) {
        if (item) {
          this.paramTypeFilter = item
        } else {
          this.paramTypeFilter = null
        }

      },
      filterAnalitList(row, filter) {
        if (!filter) {
          return true
        } else if (filter && (row.paramType == filter)) {
          return true
        } else if (filter == 'metabolomic' && row.metabolomic) {
          return true
        } else {
          return false
        }
      },
      deleteAnalit(paramId, paramName) {
        this.error = null;
        if (confirm('Вы уверены, что хотите удалить аналит ' + paramName)) {
          this.loading = true;
          fetch(host + '/rest/params/' + paramId, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.paramId !== paramId)

                console.log('Exit Success ' + json)

              } else {

                console.log('Exit Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
            }).catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
              this.loading = false
            })
        }
      },
      rowClickHandler(item) {
        console.log(item.id)
        this.error = null
        this.$router.push('/analitedit/' + item.paramId)
      }
    },
    mounted() {
      fetch(host + '/rest/params', {
        credentials: "include"
      }).then(response => response.json())
        .then(json => {
          console.log(json)
          this.items = json.params
          this.loading = false
        })
      fetch(host + '/rest/dicts/paramtype')
          .then(response => response.json())
          .then(json => {
            this.paramTypeOptions = json.options
          })
    }
  }
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}

</style>