<template>
  <div class="col-12 wrapper">
    <div>
      <label v-if="paramType === 4 || paramType === 8 || paramType === 9" class="editLabel" for="childrenParams">Аналиты для комплексного теста</label>
      <label v-else-if="paramType === 5" class="editLabel" for="childrenParams">Аналиты для связанного теста</label>
      <label v-else-if="paramType === 6" class="editLabel" for="childrenParams">Аналиты для интервального теста</label>
      <br>
      <treeselect name="childrenParams" id="childrenParams" placeholder=""
                  clearable searchable
                  open-on-click disableFuzzyMatching
                  :noResultsText="'Не найдено...'"
                  :options="childrenParamOptions | searchQueryFilter(childrenParams, childrenSearchQuery)"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="selectedChild" @search-change="childrenSearchChange"/>
      <b-button @click="addSelectedAnalit()" variant="info" :disabled="saving">
        Добавить аналит
      </b-button>
    </div>


    <b-button @click="saveCurrentGroupData()" variant="info" :disabled="saving">
      Сохранить изменения
      <b-spinner v-if="saving" variant="light" small></b-spinner>
    </b-button>
    <drop-list
        :items="childrenParams"
        class="list"
        @reorder="$event.apply(childrenParams)"
    >
      <template v-slot:item="{item}">
        <drag class="item" :key="item.childParamId">
          <div style="width: 90%;">{{item.paramName}}, {{item.biomaterialTypeName}}</div>
          <div style="width: 10%"><b-button style="float: right;" @click="deleteChild(item)" variant="info-outline" align="right"><b-icon variant="info" icon="x-circle" size="lg"></b-icon></b-button></div>
        </drag>
      </template>
      <template v-slot:feedback="{data}">
        <div class="item feedback" :key="data">{{data}}</div>
      </template>
    </drop-list>
  </div>
</template>

<script>
import {Drag, DropList} from "vue-easy-dnd";
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  props: ["analitId", "paramType"],
  name: "ChildParamsSortOrder",
  data() {
    return {
      childrenParams: [],
      childrenParamOptions: [],
      childrenSearchQuery: '',
      saving: false,
      selectedChild: null,
      normalizeTreeselect(node) {
        return {
          id: node.paramId,
          label: node.paramName + (node.biomaterialTypeName ? ', ' + node.biomaterialTypeName : '') + ' ' +  node.paramCode
        }
      }
    }
  },
  filters: {
    searchQueryFilter(childrenParamOptions, childrenParams, childrenSearchQuery) {
      if (!childrenSearchQuery) return []

      return childrenParamOptions.filter( (el, index, []) => ( childrenParams.findIndex(el1 => (el1.childParamId === el.paramId)) < 0 &&
          el.searchString.toLowerCase().indexOf(childrenSearchQuery.toLowerCase()) >= 0
      ))

    }
  },
  components: {
    DropList, Drag, Treeselect
  },
  mounted() {
    fetch(host + '/rest/params/' + this.analitId + '/children', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.childrenParams = json.children
        })

    fetch(host + '/rest/params', {
      credentials: "include"
    }).then(response => response.json())
        .then(json => {
          this.childrenParamOptions = json.params

        })


  },
  methods: {
    childrenSearchChange(searchQuery, instanceId) {
      this.childrenSearchQuery = searchQuery
    },
    deleteChild(item) {
      this.childrenParams.splice(this.childrenParams.indexOf(item), 1)
    },
    addSelectedAnalit() {
      let child = this.childrenParamOptions.find(el => (el.paramId === this.selectedChild))

      if (child) {
        this.childrenParams.unshift({
          childParamId: child.paramId,
          paramCode: child.paramCode,
          paramName: child.paramName,
          biomaterialTypeId: child.biomaterialTypeId,
          biomaterialTypeName: child.biomaterialTypeName,
          priorityReport: 0
        })

        this.selectedChild = null
      }
    },
    async saveCurrentGroupData() {

      this.saving = true
      //const groupName = this.currentGroup.text
      for (const [i, v] of this.childrenParams.entries()) {
        v.priorityReport = i
      }
      console.log(JSON.stringify(this.currentGroup))
      fetch(host + '/rest/params/' + this.analitId + '/children', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({children: this.childrenParams}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Зависимосимые аналиты')
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.saving=false
          })
          .catch(error => {
            this.$errorToast('Ошибка сохранения данных')
            this.saving=false
          })
    },
  }
}
</script>

<style >
.v-application--wrap,
.v-content,
.v-content__wrap {
  height: 100%;
}

.drop-in {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
}
</style>

<style scoped >

.wrapper .list {
  border: none;
  margin: 2rem auto;
  width: 100%;

}
.wrapper .list .item {
  padding: 0.3rem;
  margin: 1rem;
  color: rgb(23, 162, 184);
  border: 1px solid rgb(23, 162, 184);
  border-radius: 3px;
  font-weight: bold;
  background-color: rgb(255,255,255);
  display: flex;
  align-items: center;
  justify-content: left;
}
.wrapper .list .item .feedback {
  background-color: rgb(255, 220, 220);
  border: 2px dashed black;
}

.wrapper .list .item .drag-image {
  background-color: rgb(220, 255, 220);
  transform: translate(-50%, -50%);
}
</style>