<template>
  <div>
    <b-form @submit.prevent="onSubmit">

      <div class="row mx-0 mt-4 py-3  px-2 background_border_page_7">

        <div class="col-lg-2 col-12 m-auto px-0 text-center text-lg-left"><span class="attribute_search_page_7">Бланк назначений</span></div>
        <b-form-select id="propertyValue" v-model="selectedBlank" :options="prescribedBlanks" :select-size="1" @change="prescribedBlankSelect"
                       text-field="prescribedBlankName" value-field="prescribedBlankId"
                       class="custom-select_2 border-0 w-100 col-12 col-lg-8 parametrs padding_0"></b-form-select>

        <div class="col col-sm-1 col-xl-3 d-flex justify-content-around m-auto">

        </div>

      </div>



      <div class="background_E9F5EA_page_7">
        <div class="row mx-0 analit-simple"  v-for="(item, i) in childParams" :key="item.paramId">

          <div :class="(item.paramType === 4 || item.paramType === 5 || item.paramType === 6 || item.paramType === 8 || item.paramType === 9 || item.paramType === 10)? 'analit-complex col-lg-12 col-sm-12 d-flex py-3 just_border' : 'analit-simple col-lg-3 col-sm-5 d-flex py-3 just_border'">
            <button type="button" class="border-0 outline_none_background" @click="toogleItemOpenChild(item)" v-if="item.paramType === 4 || item.paramType === 5 || item.paramType === 6 || item.paramType === 8 || item.paramType === 9 || item.paramType === 10">


            </button>
            <b-link class="attribute_left_page_7" v-if="(item.paramType === 4 || item.paramType === 5 || item.paramType === 6 || item.paramType === 8 || item.paramType === 9 || item.paramType === 10)" @click="toogleItemOpenChild(item)">
              <img style="vertical-align: middle;" v-if="isShowButton(item, i)" src="@/assets/images/patients/icon_delete.png">
              <img style="vertical-align: middle;" v-else src="@/assets/images/patients/icon_open.png">
              {{ item.paramName }}, {{ item.biomaterialTypeName }}
            </b-link>
            <span v-else class="attribute_left_page_7">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
          </div>




          <div :class="(item.paramType == 1 || item.paramType === 3) ? 'col-lg-6 col-sm-7 row_padding just_border': 'col-lg-9 col-sm-7 row_padding just_border'" v-if="!(item.paramType == 4 || item.paramType === 5 || item.paramType === 6 || item.paramType == 8 || item.paramType == 9 || (item.paramType === 10 && !isShowButton(item, i)))">
            <div class="row text-center mb-3 mb-lg-0 h-100"  >
              <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 1 || item.paramType === 3|| item.paramType === 10 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
                <div class="px-0"><span class="attribute_center_page_7">Значение</span></div>
                <div class="px-0 row_margin">
                  <b-input-group class="border-0 w-100 row_padding "  >
                    <div v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)" class="border-0 analit-input-prepend-titr" >1:</div>

                    <input :class="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0) ? 'border-0 w-70 analit-input-titr text-left' : 'border-0 w-100 analit-input'" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)">
                  </b-input-group>


<!--                  <b-input-group prepend="1:" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)" class="border-0 w-100 analit-input">-->
<!--                    <b-form-input v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)"></b-form-input>-->
<!--                  </b-input-group>-->
<!--                  <input v-else class="border-0 w-100 analit-input" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)">-->

                </div>
              </div>
              <div  class="col-md-6 col-6 px-0" v-if="(item.paramType === 1 || item.paramType === 3 || item.paramType === 10 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
                <div class="px-0"><span class="attribute_center_page_7">Единицы измерения</span></div>
                <div class="px-0 row_margin">
                  <select v-model="item.measurementUnitId" @change="onMeasurementUnitSelect(item)" class="custom-select border-0 w-100 analit-input">
                    <option v-for="option in item.measurementOptions" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-12 col-12 px-0" v-if="(item.paramType === 2 || (item.paramType === 7 && item.propertyValueOptions && item.propertyValueOptions.length > 0))">
                <div class="px-0">
<!--                  <span class="attribute_center_page_7">Выбор значения</span>-->
                </div>
                <div class="px-0 row_margin py-3">
                  <b-form-select id="propertyValue" v-model="item.propertyValue" :options="item.propertyValueOptions" :select-size="1" @change="propertyValueSelect($event, item)"
                                 class="custom-select border-0 w-100 analit-input"></b-form-select>
                </div>
              </div>

              <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)">
                <div class="px-0"><span class="attribute_center_page_7">Титры</span></div>
                <div class="px-0 row_margin">
                  <b-form-select id="titres" v-model="item.titres" :options="item.titreOptions" :select-size="1" @change="titresSelect($event, item)"
                                 class="custom-select border-0 w-100 analit-input"></b-form-select>
                </div>
              </div>

              <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.plusMinusOptions && item.plusMinusOptions.length > 0)">
                <div class="px-0"><span class="attribute_center_page_7">Кресты</span></div>
                <div class="px-0 row_margin">
                  <b-form-select id="measure" v-model="item.plusMinus" :options="item.plusMinusOptions" :select-size="1" @change="plusMinusSelect($event, item)"
                                 class="custom-select border-0 w-100 analit-input"></b-form-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-12 text-center just_border" v-if="item.paramType == 1 || item.paramType === 3 || (item.paramType === 10 && isShowButton(item, i))">
            <div class="row h-100">
              <div class="col-12 border_right_4px_pg_7">
                <div>
                      <span class="attribute_center_page_7">Референтный диапазон
                      </span>
                </div>
                <div class="d-flex justify-content-center mb-3 mb-lg-0">
                  <div><input id="startValueUser" autocomplete="off" type="text" v-mask="mask" v-model="item.startValueUser" :disabled="item.notAllowToChangeRD" class="border-0 w-100 analit-input"></div>
                  <div class="px-1"> - </div>
                  <div><input id="finishValueUser" autocomplete="off" type="text" v-mask="mask" v-model="item.finishValueUser" :disabled="item.notAllowToChangeRD" class="border-0 w-100 analit-input"></div>
                  <div class="px-1">
                    <b-link title="Установить первоначальные значения" @click="resetUserScale(item)"><b-icon  icon="x-circle" variant="success"  size="lg" ></b-icon></b-link>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-12 col-sm-12 container-fluid" v-if="isShowButton(item, i)">
            <ClientReportComplexParamForm v-bind:param="item" v-bind:patient-id="patientId" v-if="(item.paramType == 4 || item.paramType === 5 || item.paramType === 6 || item.paramType == 8 || item.paramType == 9 || item.paramType === 10)" ref="clientComplexReport"></ClientReportComplexParamForm>
          </div>

        </div>

      </div>

      <div  class="background_E9F5EA_page_7">
        <div class="row mx-0 py-4 border_top_2px_pg_7">

          <div class="col-12  col-lg-4 d-flex">
            <div class="position-relative">
<!--              <input v-model="examinationDate" autocomplete="off" type="date" class="border-0 width_border_page_7 date1_page_7" min="1900-01-01">-->
            </div>
            <div class="px-3">
              <span class="date_research_form">&nbsp;</span>
            </div>
          </div>
          <div class="col-12 col-lg-4 text-lg-center d-flex pt-4 pt-lg-0">

            <button class="border-0 save_btn_page_5" type="submit" :disabled="saving">
                    <span class="text_save_page_5">Сохранить
                    </span>
              <b-spinner v-if="saving" variant="light" small></b-spinner>
            </button>
            <button class="border-0 save_btn_page_5 ml-2" type="button" @click="onCancel">
                    <span class="text_save_page_5">Отменить
                    </span>
            </button>
          </div>
          <div class="col-12 col-lg-4 d-flex justify-content-lg-end pt-4 pt-lg-0">
          </div>


        </div>
      </div>
    </b-form>

  </div>
</template>

<script>
import {host, currencyMask} from "@/constants";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ClientReportComplexParamForm from "@/components/patients/ClientReportComplexParamForm";

const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 10,
  prefix: '',
  suffix: ''
})

export default {
  props: {patientId: String},
  name: "ClientReportPrescribedParamForm",
  data() {
    return {
      saving: false,
      mask: currencyMask,
      childParams:[],
      vMask: valueMask,
      selectOptions: [],
      selectedBlank: null,
      prescribedBlanks: [],

      examinationDate: '' + new Date().getFullYear() + '-' + (new Date().getMonth() < 9 ? '0' + (new Date().getMonth()+1) : (new Date().getMonth()+1)) + '-' + (new Date().getDate() < 10 ? '0' + (new Date().getDate()) : (new Date().getDate())),

    }
  },
  components: {
    ClientReportComplexParamForm
  },
  methods: {
    async onCancel() {
      if (confirm('Введенные данные будут потеряны. Вы уверены что хотите закрыть форму без сохранения данных?')) {
        this.$emit('save-cancel')
      }
    },
    isComplexParam(paramType) {
      return (paramType === 4 || paramType === 5 || paramType === 6 || paramType === 8 || paramType === 9 || paramType === 10)
    },
    isShowButton(item, i) {
      return (this.isComplexParam(item.paramType) && item.openedChild)
    },
    async prescribedBlankSelect(newVal) {
      let response = await fetch(host + '/rest/patients/' + this.patientId + '/prescribes/' + newVal + '/params', {
        credentials: "include"
      })
      let json = await response.json()

      this.childParams = json.params
      for(let p of this.childParams) {
        p.openedChild = false
        if (p.paramType == 1 || p.paramType == 3) {
          this.measurementUnitSelect(p)
        }
        await this.fetchDicts(p)
      }
      this.$forceUpdate()
    },
    async onMeasurementUnitSelect(event) {
      await this.measurementUnitSelect(event)
      this.$forceUpdate()
    },
    async measurementUnitSelect(p) {
      //fetch(host + '/rest/params/' + this.selected.paramId + '/patient/' + this.patientId + '/scale/' + this.measurementUnitId, {
      let response = await fetch(host + '/rest/params/' + p.paramId + '/patient/' + this.patientId + '/scale/' + p.measurementUnitId, {
        credentials: "include"
      })
      let json = await response.json()

      if (json.resultCode == 0) {
        p.scaleSelect = json.scaleSelect
        p.startValueUser = p.scaleSelect.startValue
        p.finishValueUser = p.scaleSelect.finishValue
        p.isUserScale = false
      } else {
        p.scaleSelect = {}
        p.isUserScale = false
      }

    },
    async fetchDicts(param) {
      console.log('fetchDicts')
      if (!param || param.paramType === 4 || param.paramType === 8 || param.paramType === 9) return

      param.propertyValue = ''
      param.titres = ''
      param.plusMinus = ''
      param.paramValue = ''
      param.examinationDate = ''

      let response = await fetch(host + '/rest/dicts/parammeasurementunit/' + param.paramId, {
        credentials: "include"
      })
      let json = await response.json()

      param.measurementOptions = json.options



      response = await fetch (host + '/rest/params/' + param.paramId + '/properties/values/dict', {
        credentials: "include"
      })
      json = await response.json()
      param.propertyValueOptions = [{value: null, text: ''}]
      param.titreOptions = []
      param.plusMinusOptions = []
      param.isInterval = false
      param.isTitres = false
      for (let p of json.options) {
        if (p.text) {
          param.propertyValueOptions.push(
              {value: p, text: p.propertyName + ', ' + p.text }
          )
        }
        if (p.titres) {
          param.titreOptions.push(
              {value: p.titres, text: p.titres }
          )
        }
        if (p.plusMinus) {
          param.plusMinusOptions.push(
              {value: p.plusMinus, text: p.plusMinus }
          )
        }
        if (p.startValue || p.finishValue) {
          param.isInterval = true
        }
        if (p.titres) {
          param.isTitres = true
        }
      }

    },
    plusMinusSelect(newVal, item) {
      console.log('Item is ' + JSON.stringify(item))
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
    },
    titresSelect(newVal, item) {
      console.log('Item is ' + JSON.stringify(item))
      item.plusMinus = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
    },
    propertyValueSelect(newVal, item) {
      console.log('Item is ' + JSON.stringify(item))
      item.plusMinus = null
      item.titres = null
      item.paramValue = null
    },
    paramValueSelect(newVal, item) {
      console.log('Item is ' + JSON.stringify(item))
      item.plusMinus = null
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
    },
    toogleItemOpenChild(item) {
      item.openedChild = item.openedChild ? false : true
      this.$forceUpdate()
    },
    async fetchPrescribedBlanks() {

      let response = await fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: 'GET'
      })
      let json = await response.json()
      this.saving = false
      if (json.resultCode === 0) {
        this.prescribedBlanks = json.blanks
        if (this.prescribedBlanks && this.prescribedBlanks.length > 0) {
          this.selectedBlank = this.prescribedBlanks[0].prescribedBlankId
          await this.prescribedBlankSelect(this.selectedBlank)
        }
      } else {
        this.$errorToast(json.resultCode + ' ' + json.message)
      }


    },
    resetUserScale(item) {
      item.startValueUser = item.scaleSelect.startValue
      item.finishValueUser = item.scaleSelect.finishValue
      item.isUserScale = false
      this.$forceUpdate()
    },
    async onSubmit() {

      this.saving = true

      for (let [index, item] of this.childParams.entries()) {
        if (item.paramType == 4 || item.paramType == 8 || item.paramType == 9) {

        } else if ((item.propertyValue && item.propertyValue.value) || item.paramValue || item.plusMinus || item.titres) {
          let rq = null
          let method = 'POST'

          rq = {
            clientId: this.patientId,
            param: {
              paramId: item.paramId,
              paramValue: item.paramValue,
              measurementUnitId: item.measurementUnitId,
              examinationDate: this.examinationDate,
              propertyId: item.propertyValue.propertyId,
              propertyValueId: item.propertyValue.value,
              plusMinus: item.plusMinus,
              titres: item.titres,
              startValueUser: item.startValueUser,
              finishValueUser: item.finishValueUser

            }
          }

          try {
            console.log(JSON.stringify(rq))
            let response = await fetch(host + '/rest/params/clientreport', {
              method: method,
              credentials: 'include',
              body: JSON.stringify(rq),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            })
            let json = await response.json()

            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
            } else {
              console.log('Login Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }

          } catch (error) {
            console.log(error)
          }

        }

      }

      if (this.$refs.clientComplexReport && this.$refs.clientComplexReport.length > 0) {
        for (let ref of this.$refs.clientComplexReport) {
          await ref.onSubmit(this.examinationDate)
        }
      }

      this.saving = false

      this.$emit('save-success')
    }
  },
  async mounted() {
    await this.fetchPrescribedBlanks()

    this.$forceUpdate()
  }
}
</script>

<style scoped>

</style>