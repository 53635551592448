<template>
  <div class="col-2 col-md-3 col-xl-2 py-5 backgrround">
    <ul class="mt-0 px-0 col-12 list_type_none">

      <li class="py-4" v-if="role == 2 || role == 3"  >
        <router-link to="/patients" active-class=""  class="backgrround">
          <div class="d-flex left_menu_hover pt-2 ">
            <div class="col-1 pl-2">
              <img src="@/assets/images/dashicons_arrow-dowвn-alt2.png">
            </div>
            <div class="ml-3  d-none d-md-block col-9 valign-center">
                  <span class="patients_left ">К списку пациентов
                  </span>
            </div>
            <div class="col mx-n4  py-2 d-none d-md-block">
              <i class="fa fa-caret-left arrow" aria-hidden="true"></i>
            </div>
          </div>
        </router-link>

      </li>

      <template v-for="link in extMenuLinks" >
        <li class="py-4"  v-if="role === link.role && ((link.name == 'anketa' && !permission.questionaryBlock) || link.name != 'anketa')
                                                     && ((link.name == 'recommendations' && !permission.paramPrescribedBlock) || link.name != 'recommendations')">
          <router-link :to="link.url" active-class=""  class="backgrround">
            <div :class="$route.matched.some(({ name }) => name === link.name)? 'd-flex left_menu_hover pt-2 left_menu_hover__active' : 'd-flex left_menu_hover pt-2' ">
              <div class="col-1 pl-2">

                <img :src="link.icon">

              </div>
              <div class="ml-3  d-none d-md-block col-9 valign-center">
                  <span class="patients_left ">{{ link.title }}
                  </span>
              </div>
              <div class="col mx-n4  py-2 d-none d-md-block">
                <i class="fa fa-caret-left arrow" aria-hidden="true"></i>
              </div>
            </div>
          </router-link>

        </li>
      </template>
<!--      <li class="py-4">-->
<!--        <a href="#" class="backgrround">-->
<!--          <div class="d-flex left_menu_hover pt-2">-->
<!--            <div class="col-1 pl-2">-->

<!--              <img src="images/Group 64.png">-->

<!--            </div>-->
<!--            <div class="ml-3  d-none d-md-block col-9">-->
<!--								<span class="personal_data">ЛИЧНЫЕ ДАННЫЕ-->
<!--								</span>-->
<!--            </div>-->
<!--            <div class="col mx-n4  py-2 d-none d-md-block">-->
<!--              <i class="fa fa-caret-left arrow aria-hidden="true"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="py-4">-->
<!--        <a href="#" class="backgrround">-->
<!--          <div class="d-flex left_menu_hover pt-2">-->
<!--            <div class="col-1 pl-2">-->

<!--              <img src="images/Icon_Spisok_Pacientov.png">-->

<!--            </div>-->
<!--            <div class="ml-3 d-none d-md-block col-9">-->
<!--								<span class="research_complexes">КОМПЛЕКСЫ ИСЛЕДОВАНИЙ-->
<!--								</span>-->
<!--            </div>-->
<!--            <div class="col mx-n4 py-2 d-none d-md-block">-->
<!--              <i class="fa fa-caret-left arrow" aria-hidden="true"></i>-->
<!--            </div>-->

<!--          </div>-->
<!--        </a>-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    extMenuLinks: Array
  },
  name: "CabinetSidebar",
  data() {
    return {
      links: this.extMenuLinks ? this.extMenuLinks : [
        {role: 2, url: '/patients', name:'patients', title: 'Пациенты', icon: require('@/assets/images/Icon_Pacient.png')},
        {role: 2, url: '/doctorinfo', name: 'patientsedit', title: 'Личные данные', icon: require('@/assets/images/Group645.png')},
        {role: 1, url: '/patients/' + this.userId, name: 'patientsedit', title: 'Личные данные', icon1: require('@/assets/images/Icon_Pacient.png')}
      ]
    }
  },
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      }
    },
    userId: {
      get() {
        return this.$store.getters.userId
      }
    }
  }
}
</script>

<style scoped>

</style>