<template>
  <div class="row mx-0 h-100">


    <CabinetSidebar v-bind:ext-menu-links="extMenuLinks" />

    <!------------------------------ПАЦИЕНТЫ КОМП ВЕРСИЯ-------------------------------------------------->
    <div class="col-10 col-md-9 col-xl-10">
      <div class="row mx-0">
        <div class="col-12">
          <div class="row border-bottom py-3">
            <div class="col-12 col-lg-4 justify-content-center justify-content-lg-start  d-flex  pt-1">
              <div><img width="35px" :src="currentImg"></div>
              <div><span class="patients m-auto pl-2">{{currentTitle}}</span></div>
            </div>
            <div class="col-12 col-lg-3 d-flex m-auto pr-4 py-1">
<!--              <div class="justify-content-end">-->
<!--                <button v-if="role === 2" class="border-0 btn_new_pacient">-->
<!--                  <img src="@/assets/images/dashicons_arrow-dowвn-alt2.png">-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="" style="padding-top: 3px; padding-left: 5px;">-->
<!--                <router-link v-if="role === 2" tag="a" to="/patients">-->
<!--									<span class="new_patient">вернуться к списку пациентов-->
<!--									</span>-->
<!--                </router-link>-->
<!--              </div>-->
            </div>
            <div class="col-12 col-lg  d-flex pl-lg-4 py-1">
            </div>
            <div class="col-12 col-lg d-flex  pl-lg-5 py-1 justify-content-end">
              <div>
                <button class="border-0 btn_new_pacient">
                  <img src="@/assets/images/grouppage8.png">
                </button>
              </div>
              <div class="" style="padding-top: 5px; padding-left: 5px;">
                <a href="#" @click="openQuestionaryPdfReport">
									<span class="new_patient">Скачать анкету
									</span>
                </a>
              </div>
            </div>
          </div>
          <div class="row py-4 mx-0">


            <div class="col-lg-5 d-flex pl-0 pt-3">
              <div class="d-flex">
                <div class="m-auto">
                  <div class="border-0 btn_trns" :to="{name: 'patientsedit', params: { patientId: this.patientId } }">
                    <div>
                      <img src="@/assets/images/Group 142.png" />
                    </div>
                  </div>
                </div>
                <div class="pl-1 m-auto">
                  <span class="FIO text-decoration-none"> {{patient.firstname}} {{patient.middlename}} {{patient.lastname}}
                  </span>
                </div>
                <span class="ID m-auto font_sz pl-2"> (ID {{patient.clientCode}}) </span>
              </div>


            </div>


            <div class="col-lg col-6 pl-lg-2 border_right_3px_pg7 d-flex">
              <div>
                <div>
                  <span class="atribute_page_8">
                    Возраст
                  </span>
                </div>
                <div>
                  <span class="human_attributes">
                    {{patient.ageText}}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="patient.genderCode === 2 && patient.ageNumber >= 10" class="col-lg col-6 pl-lg-2 border_right_3px_pg7 d-flex justify-content-lg-center">
              <div>
                <div>
                  <span class="atribute_page_8">Репродуктивный статус</span>
                </div>
                <div>
                  <span v-if="dynamicInfo.pregnant" class="human_attributes">Беременность, триместр {{dynamicInfo.pregnantTrimester}}</span>
                  <span v-else-if="dynamicInfo.beforeMenarche" class="human_attributes">До менархе (10-16 лет)</span>
                  <span v-else-if="dynamicInfo.postpartumWoman" class="human_attributes">Родильница</span>
                  <span v-else-if="dynamicInfo.postmenopause" class="human_attributes">Постменопауза</span>
                  <span v-else-if="dynamicInfo.menstrualCyclePhaseName" class="human_attributes">Фаза цикла {{ dynamicInfo.menstrualCyclePhaseName  }}</span>
                  <span v-else class="human_attributes">Не указан</span>
                </div>
              </div>
            </div>
            <div class="col-lg col-6 border_right_page_7 d-flex justify-content-lg-center">
              <div>
                <div>
										<span class="atribute_page_8">
											Пол
										</span>
                </div>
                <div>
										<span class="human_attributes">
											{{patient.gender}}
										</span>
                </div>
              </div>
            </div>
            <div class="col-lg col-6 d-flex justify-content-lg-end ">
              <div>
                <div>
										<span class="atribute_page_8">
											Регион
										</span>
                </div>
                <div>
										<span class="human_attributes">
											{{patient.region}}
										</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12">
          <strong>Диагноз</strong>
        </div>
        <div class="col-12">Основное заболевание:
          {{patient.diagnosisMain}}
        </div>
        <div class="col-12">Сопутствующие заболевания:
          {{patient.diagnosisAdditional}}
        </div>
        <div class="col-12">Осложнения:
          {{patient.complications}}
        </div>
      </div>
      <div v-if="openFileRecognize">
      </div>
      <hr width="100%" color="gray">
      <router-view @updateData="fetchData"></router-view>
    </div>
  </div>
</template>

<script>
  import DynamicPatientInfoForm from "@/components/DynamicPatientInfoForm"
  import PatientReportParams from "@/components/patients/PatientReportParams"
  import Loader from '@/components/Loader'
  import {host} from '@/constants'
  import CabinetSidebar from "@/components/CabinetSidebar";
  export default {
    props: ['patientId'],
    name: "Patient",
    components: {
      DynamicPatientInfoForm, PatientReportParams, Loader, CabinetSidebar
    },
    computed: {
      role: {
        get() {
          return this.$store.getters.role
        }
      },
      permission: {
        get() {
          return this.$store.getters.permission
        }
      },
      currentTitle: {
        get () {
          return this.$route.meta.title
        }
      },
      currentImg: {
        get () {
          return this.$route.meta.img
        }
      }
    },
    data() {
      return {
        fileToRecognize: null,
        modelResult: null,
        saving: false,
        patient: Object,
        loading: true,
        dynamicInfo: Object,
        openFileRecognize: false,
        link: null,
        extMenuLinks:[]
      }
    },
    methods: {
      getLink() {
        fetch(host + '/rest/register/link/' + this.patientId, {
          method: 'GET',
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              console.log('Response ' + JSON.stringify(json))
              if (json.resultCode === 0) {
                this.link = json.url
              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }
            }).catch(error => {
              this.$errorToast(json.resultCode + ' ' + json.message)
            })

      },
      async sendFileToRecognize() {
        this.saving = true
        console.log((this.fileToRecognize))

        if (this.fileToRecognize && this.fileToRecognize.type === 'application/pdf') {

          var reader  = new FileReader();
          var curThis = this

          reader.onloadend = function () {

            console.log(reader.result)
            let rq = {name: curThis.fileToRecognize.name, type:curThis.fileToRecognize.type, content: reader.result.substring(reader.result.indexOf('base64,') + 7 )}
            console.log(JSON.stringify(rq))

            fetch(host + '/rest/recognition', {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(rq),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            }).then(response => response.json())
                .then(json => {
                  console.log('Response ' + JSON.stringify(json))
                  curThis.modelResult = json.modelResult
                  curThis.saving = false
                  //this.$forceUpdate()
                }).catch(error => {

              console.log(error)
              curThis.saving = false
            })

          }

          reader.readAsDataURL(this.fileToRecognize);

        }
      },
      openQuestionaryPdfReport() {
        let reportType = 'Questionary'

        window.open(host + "/rest/reports/" + reportType + "/patient/" + this.patientId, "_blank");

      },
      fetchData() {
        fetch(host + '/rest/patients/' + this.patientId, {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.patient = json.patient
              console.log(json)
              this.loading = false
            })

        fetch(host + '/rest/patients/dynamic/' + this.patientId, {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.dynamicInfo = json.clientInfo

            })
      }
    },
    mounted: function () {
      this.fetchData()

      console.log('Permission ' + JSON.stringify(this.permission))
      this.extMenuLinks = [
          {role: 2, url: '/patientsedit/' + this.patientId, name: 'patientsedit', title: 'Данные пациента', isBlocked: false, icon: require('@/assets/images/Icon_DanniePacienta_36х36-01.png')},
              {role: 2, url: '/patients/' + this.patientId, name: 'anketa', title: 'Анкета пациента', isBlocked: this.permission.questionaryBlock, icon: require('@/assets/images/icon_Final-01.png')},
              {role: 2, url: '/patients/' + this.patientId + '/recommendations', name: 'recommendations', title: 'Назначения', isBlocked: this.permission.paramPrescribedBlock, icon: require('@/assets/images/icon_Final-02.png')},
              {role: 2, url: '/patients/' + this.patientId + '/examinations', name: 'examinations', title: 'Ввод результатов', isBlocked: false, icon: require('@/assets/images/icon_Final-03.png')},
              {role: 2, url: '/patients/' + this.patientId + '/reports', name: 'reports', title: 'Отчеты', isBlocked: false, icon: require('@/assets/images/icon_otcheti-01.png')},
              {role: 2, url: '/patients/' + this.patientId + '/files', name: 'files', title: 'Архив', isBlocked: false, icon: require('@/assets/images/icon_Final-04.png')},
              {role: 1, url: '/patientsedit/' + this.patientId, name: 'patientsedit', title: 'Личные данные', isBlocked: false, icon: require('@/assets/images/Icon_DanniePacienta_36х36-01.png')},
              {role: 1, url: '/patients/' + this.patientId, title: 'Анкета', name: 'anketa', isBlocked: false, icon: require('@/assets/images/icon_Final-01.png')},
              {role: 1, url: '/patients/' + this.patientId + '/recommendations', name: 'recommendations', title: 'Назначения', isBlocked: false, icon: require('@/assets/images/icon_Final-02.png')},
              {role: 1, url: '/patients/' + this.patientId + '/examinations', name: 'examinations', title: 'Ввод результатов', isBlocked: false, icon: require('@/assets/images/icon_Final-03.png')},
              {role: 1, url: '/patients/' + this.patientId + '/reports', name: 'reports', title: 'Отчеты', isBlocked: false, icon: require('@/assets/images/icon_otcheti-01.png')}
              //{role: 1, url: '/patients/' + this.patientId + '/examinations', name: '', title: 'Исследования', icon: require('@/assets/images/icon_Final-03.png')},
              //{role: 1, url: '/patients/' + this.patientId + '/files', name: 'files', title: 'Архив', isBlocked: false, icon: require('@/assets/images/icon_Final-04.png')}
          ]
    }
  }
</script>

<style scoped>

@import '../assets/css/patient.css';

</style>