<template>
  <div>
    <b-navbar >

      <b-navbar-brand>
        <b-button variant="outline-info" @click="$emit('toogle-sidebar')"><b-icon icon="layout-sidebar"></b-icon> </b-button>
        LAB</b-navbar-brand>
      <b-navbar-nav class="nav-pills" v-if="isAuthorized">
        <!--        <b-nav-item v-if="role==3" to="/analits">Аналиты</b-nav-item>-->
<!--        <b-nav-item v-if="role==3" to="/measurementunits">Единицы измерения</b-nav-item>-->
<!--        <b-nav-item v-if="role==3" to="/rubricators">Рубрикаторы</b-nav-item>-->
<!--        <b-nav-item v-if="role==3" to="/factors">Элементы интерпретации</b-nav-item>-->
<!--        <b-nav-item v-if="role==3" to="/zones" exact>Интервалы</b-nav-item>-->
<!--        <b-nav-item v-if="role==2" to="/patients">Пациенты</b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" right>
        <div v-if="isAuthorized" class="userInfo">
          <p><strong>{{username}}</strong></p>
          <p><small>{{rolename}}</small></p>
        </div>
        <b-button v-if="!isAuthorized" class="btn-info" v-b-modal.loginModal right>Войти</b-button>
        <b-button v-if="isAuthorized" class="btn-info" @click="exitClick" v-b-modal.loginModal right>Выйти</b-button>
      </b-navbar-nav>
    </b-navbar>
    <b-modal v-if='!isAuthorized' id="loginModal" hide-footer title="Вход в систему">
      <LoginForm @submit-form="doLogin" v-bind:error="error"></LoginForm>
    </b-modal>
    <hr />

  </div>
</template>

<script>
  import LoginForm from "./LoginForm"
  import {host, roles} from '@/constants'
  export default {
    computed: {
      isAuthorized: {
        get() {
          return this.$store.getters.isAuthorized
        },
        set(value) {
          this.$store.commit('updateIsAuthorized', value)
        }
      },
      role: {
        get() {
          return this.$store.getters.role
        },
        set(value) {
          this.$store.commit('updateRole', value)
        }
      },
      rolename: {
        get() {
          return this.$store.getters.rolename
        },
        set(value) {
          this.$store.commit('updateRolename', value)
        }
      },
      username: {
        get() {
          return this.$store.getters.username
        },
        set(value) {
          this.$store.commit('updateUsername', value)
        }
      }
      ,
      userId: {
        get() {
          return this.$store.getters.userId
        },
        set(value) {
          this.$store.commit('updateUserId', value)
        }
      },
      activationDaysLeftCount: {
        get() {
          return this.$store.getters.activationDaysLeftCount
        },
        set(value) {
          this.$store.commit('updateActivationDaysLeftCount', value)
        }
      },
      reportDetailedBlock: {
        get() {
          return this.$store.getters.reportDetailedBlock
        },
        set(value) {
          this.$store.commit('updateReportDetailedBlock', value)
        }
      },
      reportGeneralBlock: {
        get() {
          return this.$store.getters.reportGeneralBlock
        },
        set(value) {
          this.$store.commit('updateReportGeneralBlock', value)
        }
      },
      reportConciseBlock: {
        get() {
          return this.$store.getters.reportConciseBlock
        },
        set(value) {
          this.$store.commit('updateReportConciseBlock', value)
        }
      },
      reportPatientBlock: {
        get() {
          return this.$store.getters.reportPatientBlock
        },
        set(value) {
          this.$store.commit('updateReportPatientBlock', value)
        }
      },
      reportGraphicBlock: {
        get() {
          return this.$store.getters.reportGraphicBlock
        },
        set(value) {
          this.$store.commit('updateReportGraphicBlock', value)
        }
      },
      permission: {
        get() {
          return this.$store.getters.permission
        },
        set(value) {
          this.$store.commit('updatePermission', value)
        }
      }
    },
    name: "Header",
    data() {
      return {

        error: null,

        links: [
          {role: 3, url: '/analits', title: 'Аналиты', exact: true},
          {role: 3, url: '/measurementunits', title: 'Единицы измерения'},
          {role: 3, url: '/rubricators', title: 'Рубрикаторы'},
          {role: 3, url: '/factors', title: 'Элементы заключения и рекомендаций'},
          {role: 3, url: '/zones', title: 'Элементы интерпретации'},
          {role: 3, url: '/scales', title: 'Альтернативные шкалы'},
          {role: 3, url: '/checklists', title: 'Анкета'},
          {role: 3, url: '/reports/settings', title: 'Настройки отчётов'},
          {role: 3, url: null, title: '|', delimeter: true},
          {role: 3, url: '/doctors', title: 'Врачи'},
          {role: 3, url: '/clinics', title: 'Клиники'},
          {role: 3, url: '/specializations', title: 'Специализации'},
          {role: 3, url: '/pins', title: 'Pin-коды'},
          {role: 2, url: '/patients', title: 'Пациенты'}
        ]
      }
    },
    components: {
      LoginForm
    },
    methods: {
      exitClick () {
        fetch(host + '/rest/session', {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
          .then(json => {
            this.isAuthorized = false
            this.role = null
            this.activationDaysLeftCount = null
            this.$router.push('/')
            browser.cookies.remove( {
                name: 'JSESSIONID'
            })
          }).catch(error => {
          console.log(JSON.stringify(error))
        })

      },
      doLogin(loginForm) {
        this.error = null
        console.log(JSON.stringify(loginForm))
        fetch(host + '/rest/session', {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(loginForm),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
        .then(json => {
          if (json.resultCode == 0) {
            this.isAuthorized = true
            this.role = json.role
            this.rolename = roles.filter(t => t.id == this.role)[0].name
            this.username = json.username
            this.userId = json.userId
            this.activationDaysLeftCount = json.activationDaysLeftCount
            this.reportDetailedBlock = json.reportDetailedBlock
            this.reportGeneralBlock = json.reportGeneralBlock
            this.reportConciseBlock = json.reportConciseBlock
            this.reportPatientBlock = json.reportPatientBlock
            this.reportGraphicBlock = json.reportGraphicBlock
            this.permission = json.permission
            this.$bvModal.hide('loginModal')
            console.log('Role: ' + roles.filter(t => t.id == this.role)[0].name)
            if (this.role == 3) {
              this.$router.push('/analits')
            } else if (this.role == 2) {
              this.$router.push('/patients')
            }  else {
              this.$router.push('/patients/' + this.userId)
            }

            console.log('Login Success ' + JSON.stringify(json))
          } else {

            console.log('Login Failed ' + JSON.stringify(json))
            this.error = json.message;
          }
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      }
    },
    mounted() {

      fetch(host + '/rest/session', {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
        .then(json => {
          if (json.resultCode == 0) {
            this.isAuthorized = true
            this.role = json.role
            this.rolename = roles.filter(t => t.id == this.role)[0].name
            this.username = json.username
            this.userId = json.userId
            this.activationDaysLeftCount = json.activationDaysLeftCount
            console.log('Role: ' + roles.filter(t => t.id == this.role)[0].name)
            //this.$router.push('/patients')
            console.log('Login Success ' + JSON.stringify(json))
          } else {

            console.log('Login Failed ' + json)
            this.isAuthorized = false
            this.role = 0
            this.activationDaysLeftCount = null
          }
        }).catch(error => {
        console.log(JSON.stringify(error))
      })
    }
  }
</script>

<style scoped>
  .userInfo {
    align-content: end;
    padding-right: 2rem;
  }
  .userInfo p {
    padding: 0rem;
    margin: 0rem 0rem -0.5rem 0rem;
    text-align: right;
  }
  .userInfo p small {
    font-size: 70%!important;
  }

  .nav-active-custom {
    color: #fff!important;
    background-color: #17a2b8 !important;
  }


</style>