<template>
  <b-container fluid>
    <b-row>

    </b-row>
    <b-row class="newAttr">
      <b-col>
        <label>Родительские патологии</label>
        <br>
        <b-button class="btn-info" @click="addNewParent()">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Добавить
        </b-button>

        <b-modal :id="'parentDisease'" hide-footer size="lg" title="Выбор патологии">

          <b-container fluid>
            <b-row class="newAttr pt-2">
              <b-col cols="3"><label for="parentDisease">Патологии</label></b-col>
              <b-col cols="9">
                <treeselect v-if="parentDisease.diseaseId" name="parentDisease" id="parentDisease" placeholder=""
                            clearable searchable required
                            open-on-click close-on-select
                            :noResultsText="'Не найдено...'"
                            :noOptionsText="'Список опций пуст'"
                            :options="factorOptions | searchSingleQueryFilter(parentDisease.diseaseId, childrenSearchQuery)"
                            :max-height="200"
                            :normalizer="normalizeTreeselect"
                            v-model="parentDisease.diseaseId" style="font-size: 15px!important;" @search-change="childrenSearchChange">
                </treeselect>
                <treeselect v-else name="parentDisease" id="parentDisease" placeholder=""
                            multiple clearable searchable required
                            open-on-click close-on-select :flat="true"
                            :noResultsText="'Не найдено...'"
                            :noOptionsText="'Список опций пуст'"
                            :options="factorOptions | searchQueryFilter(parentDisease.diseasesIds, childrenSearchQuery, parentDiseases)"
                            :max-height="200"
                            :normalizer="normalizeTreeselect"
                            v-model="parentDisease.diseasesIds" style="font-size: 15px!important;" @search-change="childrenSearchChange">
                </treeselect>
              </b-col>
            </b-row>


            <b-row class="newAttr pt-2">
              <b-col cols="3"><label for="levelOfConnection">Уровень связи</label></b-col>
              <b-col cols="9"><b-form-input name="levelOfConnection" id="levelOfConnection" type="number" required  v-model="parentDisease.levelOfConnection" autocomplete="off"></b-form-input></b-col>
            </b-row>
            <b-row class="newAttr pt-2">
              <b-button variant="info" @click="saveParentDisease()" :disabled="loading">Сохранить</b-button>
            </b-row>

          </b-container>
        </b-modal>





      </b-col>
    </b-row>
    <b-row>
      <b-table striped hover :items="parentDiseases" :fields="fields">
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="editParentDiseaseValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteParentDiseaseValue(data.item)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
<!--        <template v-slot:cell(param)="data">-->
<!--          <router-link :to="'/analitedit/' + data.item.paramId">{{ data.item.paramName }}, {{ data.item.biomaterialTypeName }}</router-link>-->
<!--        </template>-->
<!--        <template v-slot:cell(property)="data">-->
<!--          {{ ((data.item.propertyName ? data.item.propertyName :'')  + (data.item.propertyValueName? (', ' + data.item.propertyValueName) : '' )) }}-->
<!--        </template>-->
      </b-table>
    </b-row>

    <b-row class="newAttr">
      <b-col>
        <label >Дочерние патологии</label>

        <br>
        <b-button class="btn-info" @click="addNewChild()">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Добавить
        </b-button>

        <b-modal :id="'childDisease'" hide-footer size="lg" title="Выбор патологии">

          <!--        <FactorBandEdit @save-success="afterSave" v-bind:analit-id="this.analitId" v-bind:factor-band="currentFactorBand"-->
          <!--                        v-bind:factor-value="currentFactorValue" v-bind:factor-text="currentFactorText" v-bind:param-type="this.paramType"></FactorBandEdit>-->
          <!--        -->


          <b-container fluid>
            <b-row class="newAttr pt-2">
              <b-col cols="3"><label for="parentDisease">Патологии</label></b-col>
              <b-col cols="9">
                <!--              <b-form-select v-if="currentParamScaleDisease.oldDiseaseId != null" id="factorId" v-model="currentParamScaleDisease.diseaseId" :options="factorOptions" required :select-size="1"></b-form-select>-->
                <treeselect v-if="childDisease.diseaseId" name="childDisease" id="childDisease" placeholder=""
                            clearable searchable required
                            open-on-click close-on-select
                            :noResultsText="'Не найдено...'"
                            :noOptionsText="'Список опций пуст'"
                            :options="factorOptions | searchSingleQueryFilter(childDisease.diseaseId, childrenSearchQuery)"
                            :max-height="200"
                            :normalizer="normalizeTreeselect"
                            v-model="childDisease.diseaseId" style="font-size: 15px!important;" @search-change="childrenSearchChange">
                </treeselect>
                <treeselect v-else name="childDisease" id="childDisease" placeholder=""
                            multiple clearable searchable required
                            open-on-click close-on-select :flat="true"
                            :noResultsText="'Не найдено...'"
                            :noOptionsText="'Список опций пуст'"
                            :options="factorOptions | searchQueryFilter(parentDisease.diseasesIds, childrenSearchQuery, parentDiseases)"
                            :max-height="200"
                            :normalizer="normalizeTreeselect"
                            v-model="childDisease.diseasesIds" style="font-size: 15px!important;" @search-change="childrenSearchChange">
                </treeselect>
              </b-col>
            </b-row>


            <b-row class="newAttr pt-2">
              <b-col cols="3"><label for="levelOfConnection">Уровень связи</label></b-col>
              <b-col cols="9"><b-form-input name="levelOfConnection" id="levelOfConnection" type="number" required  v-model="childDisease.levelOfConnection" autocomplete="off"></b-form-input></b-col>
            </b-row>
            <b-row class="newAttr pt-2">
              <b-button variant="info" @click="saveChildDisease()" :disabled="loading">Сохранить</b-button>
            </b-row>

          </b-container>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-table striped hover :items="childrenDiseases" :fields="fields">
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="editChildDiseaseValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteChildDiseaseValue(data.item)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
<!--        <template v-slot:cell(param)="data">-->
<!--          <router-link :to="'/analitedit/' + data.item.paramId">{{ data.item.paramName }}, {{ data.item.biomaterialTypeName }}</router-link>-->
<!--        </template>-->
<!--        <template v-slot:cell(property)="data">-->
<!--          {{ ((data.item.propertyName ? data.item.propertyName :'')  + (data.item.propertyValueName? (', ' + data.item.propertyValueName) : '' )) }}-->
<!--        </template>-->
      </b-table>
    </b-row>

  </b-container>
</template>

<script>

import {host, yearMask} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "DiseaseParentChildList",
  props: {
    currentRubricator: Object,
    currentRubricatorValue: Object
  },
  data() {
    return {
      loading: false,
      parentDiseases: [],
      childrenDiseases: [],
      fields: [
        {
          key: 'diseaseCode',
          label: 'Код патологии'
        },
        {
          key: 'diseaseName',
          label: 'Наименование '
        },
        {
          key: 'levelOfConnection',
          label: 'Уровень связи',
          sortable: false
        },
        {
          key: 'buttons',
          label: '',
          sortable: false
        }
      ],
      factorOptions:[],
      parentDisease: {
        diseaseId: null,
        levelOfConnection: 3,
        diseasesIds: []
      },
      childDisease: {
        diseaseId: null,
        levelOfConnection: 3,
        diseasesIds: []
      },
      childrenSearchQuery: '',
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  components: {
    Treeselect
  },
  methods: {
    childrenSearchChange(searchQuery, instanceId) {
      console.log('childrenSearchChange' + searchQuery)
      this.childrenSearchQuery = searchQuery
    },

    async deleteChildDiseaseValue(item) {
      if (confirm('Вы уверены, что хотите удалить параметр?')) {
        this.loading = true;
        let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/children/' + item.diseaseId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode == 0) {
          console.log('Delete Success ' + json)

        } else {

          console.log('Delete Failed ' + json)
          this.error = json.message;
        }
        this.loading = false
        await this.fetchParentChildDisease()
        //     catch(error => {
        //   this.error = error
        //   console.log(JSON.stringify(error))
        //   this.loading = false
        // })
      }
    },
    async deleteParentDiseaseValue(item) {
      if (confirm('Вы уверены, что хотите удалить параметр?')) {
        this.loading = true;
        let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/parents/' + item.diseaseId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode == 0) {
          console.log('Delete Success ' + json)

        } else {

          console.log('Delete Failed ' + json)
          this.error = json.message;
        }
        this.loading = false
        await this.fetchParentChildDisease()
        //     catch(error => {
        //   this.error = error
        //   console.log(JSON.stringify(error))
        //   this.loading = false
        // })
      }
    },
    fetchDicts() {
      fetch(host + '/rest/factors/Disease', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.factorOptions = json.options
          })
    },
    async fetchParentChildDisease() {
      let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/parents/full', {
        credentials: "include"
      })
      let json = await response.json()
      this.parentDiseases = json.children

      response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/children/full', {
        credentials: "include"
      })
      json = await response.json()
      this.childrenDiseases = json.children

    },
    addNewParent() {
      this.parentDisease = {
        diseaseId: null,
        levelOfConnection: 3,
        diseasesIds: []
      }
      this.$bvModal.show('parentDisease')
    },
    editParentDiseaseValue(item) {
      this.parentDisease = item
      this.$bvModal.show('parentDisease')
    },
    addNewChild() {
      this.childDisease = {
        diseaseId: null,
        levelOfConnection: 3,
        diseasesIds: []
      }
      this.$bvModal.show('childDisease')
    },
    editChildDiseaseValue(item) {
      this.childDisease = item
      this.$bvModal.show('childDisease')
    },
    async saveParentDisease() {
      this.loading = true
      let method = 'POST'
      if (this.parentDisease.diseaseId) {
        method = 'PUT'
      }
      let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/parents', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.parentDisease),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await response.json()

      if (json.resultCode != 0) {

        console.log('updateParents Save Failed ' + json)
        this.error = json.resultCode + ' ' + json.message;
        throw this.error
      }
      this.$bvModal.hide('parentDisease')
      this.loading = false
      await this.fetchParentChildDisease()
    },
    async saveChildDisease() {
      this.loading = true
      let method = 'POST'
      if (this.childDisease.diseaseId) {
        method = 'PUT'
      }
      let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/children', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.childDisease),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await response.json()

      if (json.resultCode != 0) {

        console.log('updateParents Save Failed ' + json)
        this.error = json.resultCode + ' ' + json.message;
        throw this.error
      }
      this.$bvModal.hide('childDisease')
      this.loading = false
      await this.fetchParentChildDisease()
    }

  },
  filters: {

    searchQueryFilter: function (options, selected, searchQuery, paramScaleDiseaseOptions) {
      console.log('searchQueryFilter' + searchQuery)
      if (!searchQuery || searchQuery.length <= 2) {
        return []
      }

      let result = []

      result = options.filter((el, index, []) => {
        return (selected.find(sel => (sel == el.value)) || ((searchQuery) && (el.text).toLowerCase().includes(searchQuery.toLowerCase()) ))
            && !paramScaleDiseaseOptions.find(el1 => el1.diseaseId == el.value)
      })

      return result
    },
    searchSingleQueryFilter: function (options, selected, searchQuery) {
      console.log('searchQueryFilter ' + searchQuery + selected)
      if (searchQuery && searchQuery.length <= 2) {
        return []
      }

      let result = []

      result = options.filter((el, index, []) => {
        return (selected === el.value) || ((searchQuery) && (el.text).toLowerCase().includes(searchQuery.toLowerCase()) )
      })

      return result
    }
  },
  async mounted() {
    await this.fetchParentChildDisease()
    this.fetchDicts()
  }
}
</script>

<style scoped>

</style>