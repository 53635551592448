<template>
  <div class="row mx-0 h-100">
<!--    <img src="@/assets/images/BAnner2.png" class="w-100">-->

    <CabinetSidebar v-bind:ext-menu-links="extMenuLinks" />


    <!------------------------------ПАЦИЕНТЫ КОМП ВЕРСИЯ-------------------------------------------------->
    <div class="col d-none d-lg-block">
      <b-form @submit.prevent="onSubmit">
      <div class="row ">

        <div class="col-12 px-4">
          <div class="row border-bottom py-3">
            <div class="col-12 col-lg-4 justify-content-center justify-content-lg-start  d-flex  pt-1">
              <div><img width="35px" :src="currentImg"></div>
              <div><span class="patients m-auto pl-2">{{currentTitle}}</span></div>
            </div>
            <div class="col-12 col-lg-3 d-flex m-auto pr-4 py-1">
<!--              <div class="justify-content-end">-->
<!--                <button v-if="role === 2" class="border-0 btn_new_pacient">-->
<!--                  <img src="@/assets/images/dashicons_arrow-dowвn-alt2.png">-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="" style="padding-top: 3px; padding-left: 5px;">-->
<!--                <router-link v-if="role === 2" tag="a" to="/patients">-->
<!--									<span class="new_patient">вернуться к списку пациентов-->
<!--									</span>-->
<!--                </router-link>-->
<!--              </div>-->
            </div>
            <div class="col-12 col-lg  d-flex pl-lg-4 py-1">
            </div>
            <div class="col-12 col-lg d-flex  pl-lg-5 py-1">
<!--              <div>-->
<!--                <button class="border-0 btn_new_pacient">-->
<!--                  <img src="@/assets/images/grouppage8.png">-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="m-lg-auto">-->
<!--                <a href="#" @click="openQuestionaryPdfReport">-->
<!--									<span class="new_patient">Скачать анкету-->
<!--									</span>-->
<!--                </a>-->
<!--              </div>-->
            </div>
          </div>




<!--          <div class="border-bottom pb-2">-->
<!--            <div class="pt-3">-->

<!--              <button class="border-0 add_button"><img src="@/assets/images/icon.png"></button>-->


<!--              <b-link :to="'/patients/'+patientId" class=""><span class="patients pt-2">{{currentTitle}}</span></b-link>-->

<!--            </div>-->
<!--            <div class="col-12 col-lg-3 d-flex m-auto pr-4 py-1">-->
<!--              <div class="">-->
<!--                <button v-if="role === 2" class="border-0 btn_new_pacient">-->
<!--                  <img src="@/assets/images/dashicons_arrow-dowвn-alt2.png">-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="m-lg-auto">-->
<!--                <router-link v-if="role === 2" tag="a" to="/patients">-->
<!--									<span class="new_patient">вернуться к списку пациентов-->
<!--									</span>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Фамилия</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 488.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input   class="edit-container__input-text" v-model="patient.lastname" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Имя</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 488.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input class="edit-container__input-text" v-model="patient.firstname" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Отчество</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 488.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input  class="edit-container__input-text" v-model="patient.middlename" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-xl-4 px-4 py-2">
          <p class="second_name edit__title">Код клиента</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 489.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input  class="edit-container__input-text" placeholder="ID" v-model="patient.clientCode" autocomplete="off" :disabled="role != 2">
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-4 col-xl-4 px-4 py-2">
          <p class="second_name edit__title">Дата рождения</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 490.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <b-form-input id="activationDate" class="edit-container__input-date" type="date" placeholder=""  :min="'1901-01-01'" :max="(new Date()).toISOString().split('T')[0]" v-model="patient.birthday" autocomplete="off"></b-form-input>
            </div>
          </div>
        </div>

        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Пол</p>
          <div class="edit-container-justify-start" style>
            <div class="edit-container cursor-pointer"  @click="selectGender(1)">
              <img src="@/assets/images/patients/edit-form/radio-selected.png" v-if="patient.genderCode == 1" class="edit-container__icon" >
              <img src="@/assets/images/patients/edit-form/radio-empty.png" v-else class="edit-container__icon" >
              <div class="edit-container__input-container">
                <div class="edit-container__text">Мужской</div>
              </div>
            </div>
            <div class="edit-container cursor-pointer"  @click="selectGender(2)">
              <img src="@/assets/images/patients/edit-form/radio-selected.png" v-if="patient.genderCode == 2" class="edit-container__icon" >
              <img src="@/assets/images/patients/edit-form/radio-empty.png" v-else class="edit-container__icon" >
              <div class="edit-container__input-container">
                <div class="edit-container__text">Женский</div>
              </div>
            </div>




<!--            <div class="col-5 p-0">-->
<!--              <a href="#">-->
<!--                <div :class="'pol_p text-center pt-2 ' + (patient.genderCode == 1 ? 'pol_p&#45;&#45;hover' : '')" @click="selectGender(1)">-->
<!--                  Мужской-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="col-5 pol p-0">-->
<!--              <a href="#">-->
<!--                <div :class="'pol_p text-center pt-2 ' + (patient.genderCode == 2 ? 'pol_p&#45;&#45;hover' : '')" @click="selectGender(2)">-->
<!--                  Женский-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->
          </div>
        </div>
        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Телефон</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 491.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input v-model="patient.phoneNumber" autocomplete="off" v-mask="'+ # (###) ###-##-##'"  placeholder="+ 7 (999) 999-99-99"  class="edit-container__input-text">
            </div>
          </div>
        </div>

        <div class="col-4 py-2 px-4">
          <p class="second_name edit__title">Почта</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 492.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <input v-model="patient.email" autocomplete="off" class="edit-container__input-text">
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-xl-4 px-4 py-2">
          <p class="second_name edit__title">Регион</p>
          <div class="edit-container">
            <img src="@/assets/images/patients/edit-form/Group 492.png" class="edit-container__icon" >
            <div class="edit-container__input-container">
              <select  class="edit-container__input-text" v-model="patient.regionCode">
                <option v-for="item of mainRussiaRegionFunction(regionOptions)" :key="item.value" :value="item.value">{{item.text}}</option>
              </select>
            </div>

          </div>
        </div>


        <div class="col-12 py-2 px-4">
          <p class="second_name">&nbsp;</p>
        </div>
        <div class="col-12 py-2 px-4">
          <p class="second_name internal-title">ДИАГНОЗ</p>
        </div>

        <div class="col-12 py-2 px-4">
          <p class="second_name edit__title">Основное заболевание</p>
          <div class="edit-container">
            <input   class="edit-container__input-text" v-model="patient.diagnosisMain" autocomplete="off">

          </div>
        </div>

        <div class="col-12 py-2 px-4">
          <p class="second_name edit__title">Сопутствующие заболевания</p>
          <div class="edit-container">
            <input   class="edit-container__input-text" v-model="patient.diagnosisAdditional" autocomplete="off">

          </div>
        </div>

        <div class="col-12 py-2 px-4">
          <p class="second_name edit__title">Осложнения</p>
          <div class="edit-container">
            <input  class="edit-container__input-text" v-model="patient.complications" autocomplete="off">

          </div>
        </div>


        <div class="col mt-5 d-flex justify-content-center margin_button">
          <a class="button_save_text" href="#">
            <div class="text-center pt-1 m-auton button_save" >
              <button type="submit"  style="border: none; background: transparent; color: #FFFFFF;" >
                Сохранить
              </button>
            </div>
          </a>
        </div>


      </div>
      </b-form>
    </div>


    <!------------------------------ПАЦИЕНТЫ MOB ВЕРСИЯ-------------------------------------------------->
    <div class="col d-lg-none">
      <div class="row mx-0">
        <div class="col-12 px-4">
          <div class="border-bottom pb-2">
            <div class="pt-3">

              <button class="border-0 add_button"><img src="@/assets/images/icon.png"></button>


              <span class="patients pt-2">Новый пациент</span>

            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Фамилия</p>
          <div class="password pass_icon ">
            <input   class="col input_second_name pl-5 position-relative ">
            <a href="#" class=" pass_eyes img_pass_eyes" ></a>
          </div>

        </div>



        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Имя</p>
          <div class="password pass_icon">
            <input   class="col pl-5 input_second_name position-relative">
            <a href="#" class=" pass_eyes img_pass_eyes" ></a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Отчество</p>
          <div class="password pass_icon">
            <input  class="col pl-5 input_second_name position-relative">
            <a href="#" class=" pass_eyes img_pass_eyes" ></a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="id_name">Код клиента</p>
          <input class="row mx-0 col-12 my-3 input_second_name mx-0 id_p" placeholder="ID" :disabled="role != 2" />




        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Дата рождения</p>
          <div class="row mx-0">
            <div class="col password pass_icon">
              <select placeholder="ГГГГ"  class="col  customs pl-5 position-relative">
                <option class="option_p" value="Option 1">ГГГГ</option>
              </select>
              <a href="#" class=" pass_data img_pass_data" ></a>

            </div>
            <div class="col password pass_icon">
              <select placeholder="ММ"  class="col customs pl-5 position-relative">
                <option class="option_p" value="Option 1">ММ</option>
              </select>

            </div>
            <div class="col password pass_icon">
              <select placeholder="ДД"  class="col customs pl-5 position-relative">
                <option class="option_p" value="Option 1">ГГГГ</option>
              </select>

            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 py-2 px-4">
          <p class="second_name">Пол</p>
          <div class="row mx-0 justify-content-around">
            <div class="col-5 p-0">
              <a href="#">
                <div class="pol_p text-center pt-2">
                  Мужской
                </div>
              </a>
            </div>
            <div class="col-5 pol p-0">
              <a href="#">
                <div class="pol_p text-center pt-2">
                  Женский
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Телефон</p>
          <div class="password pass_icon">
            <input  placeholder="+ 7 (_ _ _) _ _ _ _ _ _ _"  class="col input_phone pl-5 position-relative">
            <a href="#" class=" pass_eyes img_pass_phone" ></a>
          </div>

        </div>



        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Почта</p>
          <div class="password pass_icon">
            <input  class="col input_second_name pl-5 position-relative">
            <a href="#" class=" pass_eyes img_pass_mail" ></a>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 py-2 px-4">
          <p class="second_name">Регион</p>
          <div class="password pass_icon">
            <select  class="col pl-5 input_second_name customs  position-relative"></select>
            <a href="#" class=" pass_region img_pass_region" ></a>

          </div>
        </div>

        <div class="col button_div">
          <a class="button_save_text" href="#">
            <div class="text-center mt-5 button_save" >
              Сохранить

            </div>
          </a>
        </div>

      </div>
    </div>






<!--    <div class="col d-none d-lg-block">-->
<!--      <div class="row ">-->
<!--        <div class="col-12 px-4">-->
<!--    <Loader v-bind:showLoader="loading" />-->
<!--&lt;!&ndash;    <Loader v-if="loading" />&ndash;&gt;-->
<!--    <b-form @submit.prevent="onSubmit">-->
<!--      <h3 v-if="patientId">Редактирование пациента</h3>-->
<!--      <h3 v-else>Создание пациента</h3>-->
<!--      <div v-if="error" class="alert alert-danger">-->
<!--        {{error}}-->
<!--      </div>-->
<!--      <b-form-group id="clientCode" label-for="clientCode" label="Код клиента">-->
<!--        <b-form-input id="clientCode" type="text" placeholder="Введите код клиента" v-model="patient.clientCode" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="firstname" label-for="firstname" label="Имя">-->
<!--        <b-form-input id="firstname" type="text" placeholder="Например, Иван" v-model="patient.firstname" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="middlename" label-for="middlename" label="Отчество">-->
<!--        <b-form-input id="middlename" type="text" placeholder="Например, Иванович" v-model="patient.middlename" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="lastname" label-for="lastname" label="Фамилия">-->
<!--        <b-form-input id="lastname" type="text" placeholder="Например, Иванов" v-model="patient.lastname" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="birthday" label-for="birthday" label="Дата рождения">-->
<!--        <b-form-input id="birthday" type="date" placeholder="" v-model="patient.birthday" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="genderCode" label-for="genderCode" label="Пол">-->
<!--        <b-form-select id="genderCode" v-model="patient.genderCode" :options="genderOptions" :select-size="genderOptions.length"></b-form-select>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="regionCode" label-for="regionCode" label="Регион">-->
<!--        <b-form-select id="regionCode" v-model="patient.regionCode" :options="regionOptions | mainRussiaRegions" :select-size="1"></b-form-select>-->
<!--      </b-form-group>-->
<!--      <b-button variant="info" type="submit" >Сохранить</b-button>-->
<!--      &nbsp;-->
<!--      <b-button variant="outline-info" @click="clickBackButton" >Назад</b-button>-->
<!--    </b-form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
  import Loader from '@/components/Loader'
  import {host} from '@/constants'
  import CabinetSidebar from "@/components/CabinetSidebar";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/ru';

  export default {
    props: ['patientId'],
    name: "PatientEditForm",
    data() {
      return {
        extMenuLinks:[
          {role: 2, url: '/patientsedit/' + this.patientId, name: 'patientsedit', title: 'Данные пациента', icon: require('@/assets/images/Icon_DanniePacienta_36х36-01.png')},
          {role: 2, url: '/patients/' + this.patientId, name: 'anketa', title: 'Анкета пациента', icon: require('@/assets/images/icon_Final-01.png')},
          {role: 2, url: '/patients/' + this.patientId + '/recommendations', name: 'recommendations', title: 'Назначения', icon: require('@/assets/images/icon_Final-02.png')},
          {role: 2, url: '/patients/' + this.patientId + '/examinations', name: 'examinations', title: 'Ввод результатов', icon: require('@/assets/images/icon_Final-03.png')},
          {role: 2, url: '/patients/' + this.patientId + '/reports', name: 'reports', title: 'Отчеты', icon: require('@/assets/images/icon_otcheti-01.png')},
          {role: 2, url: '/patients/' + this.patientId + '/files', name: 'files', title: 'Архив', icon: require('@/assets/images/icon_Final-04.png')},
          {role: 1, url: '/patientsedit/' + this.patientId, name: 'patientsedit', title: 'Личные данные', icon: require('@/assets/images/Icon_DanniePacienta_36х36-01.png')},
          {role: 1, url: '/patients/' + this.patientId, title: 'Анкета', name: 'anketa', icon: require('@/assets/images/icon_Final-01.png')},
          {role: 1, url: '/patients/' + this.patientId + '/recommendations', name: 'recommendations', title: 'Назначения', icon: require('@/assets/images/icon_Final-02.png')},
          {role: 1, url: '/patients/' + this.patientId + '/examinations', name: 'examinations', title: 'Ввод результатов', isBlocked: false, icon: require('@/assets/images/icon_Final-03.png')},
          {role: 1, url: '/patients/' + this.patientId + '/reports', name: 'reports', title: 'Отчеты', isBlocked: false, icon: require('@/assets/images/icon_otcheti-01.png')}
          //{role: 1, url: '/patients/' + this.patientId + '/examinations', name: '', title: 'Исследования', icon: require('@/assets/images/icon_Final-03.png')},
          //{role: 1, url: '/patients/' + this.patientId + '/files', name: 'files', title: 'Архив', icon: require('@/assets/images/icon_Final-04.png')}
        ],
        patient: {
          clientCode: new Date().getTime() % 1000000000,
          firstname: '',
          middlename: '',
          lastname: '',
          birthday: '',
          genderCode: 1,
          regionCode: 6611,
          diagnosisMain: '',
          diagnosisAdditional: '',
          complications:''
        },
        loading: true,
        error: null,
        regionOptions: [],
        genderOptions: [
          {
            value: 1,
            text: "Мужской"
          },
          {
            value: 2,
            text: "Женский"
          }
        ]
      }
    },
    computed: {
      role: {
        get() {
          return this.$store.getters.role
        }
      },
      currentTitle: {
        get () {
          return this.$route.meta.title
        }
      },
      currentImg: {
        get () {
          return this.$route.meta.img
        }
      }
    },
    components: {
      Loader, CabinetSidebar,DatePicker
    },
    filters: {
      mainRussiaRegions: function (options) {
        let result = []
        for (let o of options) {
          if (o.parentRegionId === 1286 && o.isMain === true) {
            result.push(o)
          }
        }

        return result
      }
    },
    methods: {
      disabledBeforeTodayAndAfterAWeek(date) {
        const today = new Date();
        today.setHours(23, 59, 59, 999);

        return date > today || date < new Date('1901-01-01');
      },
      mainRussiaRegionFunction(options) {
        let result = []
        for (let o of options) {
          if (o.parentRegionId === 1286 && o.isMain === true) {
            result.push(o)
          }
        }

        return result
      },
      selectGender(gender) {
        this.patient.genderCode = gender
      },
      clickBackButton () {
        this.$router.go(-1)
      },
      onSubmit(){
        this.loading = true

        console.log(JSON.stringify(this.patient))
        let method = 'POST'
        if (this.patientId) {
          method = 'PUT'
        }
        console.log(method)

        fetch(host + '/rest/patients', {
          method: method,
          credentials: 'include',
          body: JSON.stringify(this.patient),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Login Success ' + json)
              let patientId = ''
              if (this.patientId) {
                patientId = this.patientId
              } else {
                patientId = json.entityId
                this.$router.push('/patients/' + json.entityId)
              }
              this.$messageToast('Данные пациента')
              //this.$router.push('/patients/' + patientId)
            } else {
              console.log('Login Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }
          }).catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
          })

        this.loading = false
      }
    },
    mounted() {
      fetch(host + '/rest/dicts/region')
        .then(response => response.json())
        .then(json => {
          this.regionOptions = json.options
          console.log(json)
        })

      if (this.patientId) {
        fetch(host + '/rest/patients/' + this.patientId, {
          credentials: "include"
        })
          .then(response => response.json())
          .then(json => {

            this.patient = json.patient
            this.loading = false
            console.log(json)
            console.log(JSON.stringify(this.patient))
          })
      } else {
        setTimeout(() => {
          this.loading = false
        }, 200)

      }
    }
  }
</script>

<style scoped>

@import '../../assets/css/patient_edit.css';
@import '~bootstrap/dist/css/bootstrap.min.css';

.birthdate_class {
  font-family: MyriadProRegular!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 21px!important;
  line-height: 16px!important;
  color: #829E9E!important;
}

</style>