<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <b-container>
        <b-row class="newAttr">
          <b-col cols="2"><b>Тип</b>:</b-col>
          <b-col cols="8">
            <b-form-input v-if="scaleCreateState" type="text" required v-model="scaleCreateName" id="scaleCreateName" placeholder="Введите значение" autocomplete="off" ></b-form-input>
            <b-form-select v-else id="zoneId" v-model="localParamScale.scaleRangeId" :options="scaleOptions"
                           :select-size="1"></b-form-select>
          </b-col>
          <b-col cols="2">
            <b-button v-if="scaleRangeId!==1" variant="info" @click="switchScaleCreateState()">
              <b-icon v-if="scaleCreateState" icon="dash-square" aria-hidden="true"></b-icon>
              <b-icon v-else icon="plus-square" aria-hidden="true"></b-icon>
            </b-button>
          </b-col>
        </b-row>
<!--        <b-row v-if="scaleCreateState" class="newAttr">-->
<!--          <b-col>Фраза для отчета врача:</b-col>-->
<!--        </b-row>-->
<!--        <b-row v-if="scaleCreateState">-->
<!--          <b-col>-->
<!--            <ckeditor v-model="scaleCreateReportText"-->
<!--                      :config="editorConfig"></ckeditor>-->
<!--          </b-col>-->

<!--        </b-row>-->
        <b-row class="newAttr">
          <b-col><label for="sexId"><b>Пол</b></label></b-col>
          <b-col><label for="menstrualCyclePhaseId" v-if="localParamScale.sexId === 2"><b>Фаза цикла</b></label></b-col>
<!--          <b-col><label for="zoneId" v-if="localParamScale.scaleId === 1">Интервал</label></b-col>-->

        </b-row>
        <b-row>
          <b-col>
            <b-form-select id="sexId" v-model="localParamScale.sexId" :options="genderOptions" required
                           :select-size="1"></b-form-select>
          </b-col>
          <b-col>
            <b-form-select id="menstrualCyclePhaseId" :disabled="localParamScale.postmenopause || localParamScale.postpartumWoman || localParamScale.pregnant" v-if="localParamScale.sexId === 2" v-model="localParamScale.menstrualCyclePhaseId" :options="menstrualCyclePhaseOptions"
                           :select-size="1"></b-form-select>
<!--            <b-form-select id="zoneId" v-if="localParamScale.scaleId === 1" v-model="localParamScale.zoneId"-->
<!--                           :options="zoneOptions" required :select-size="1"></b-form-select>-->
          </b-col>
        </b-row>
        <b-row class="newAttr" v-if="localParamScale.sexId === 2">
          <b-col><b-form-checkbox v-model="localParamScale.postmenopause" name="postmenopause" @change="togglePostmenopause" switch>Постменопауза</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="localParamScale.postpartumWoman" name="postpartumWoman" @change="togglePostpartumWoman" switch>Родильница</b-form-checkbox></b-col>
          <b-col><b-form-checkbox v-model="localParamScale.pregnant" name="pregnant" @change="togglePregnant" switch>Беременность</b-form-checkbox></b-col>
        </b-row>
        <b-row class="newAttr" v-if="localParamScale.sexId === 2 && localParamScale.pregnant">
          <b-col>
            <label for="startPregnancyWeek"><b>Неделя беременности:</b> От</label>
          </b-col>
          <b-col>
            <label for="finishPregnancyWeek">До</label>
          </b-col>
        </b-row>
        <b-row v-if="localParamScale.sexId === 2 && localParamScale.pregnant">
          <b-col>
            <b-form-input v-mask="yMask" id="startPregnancyWeek" type="text" required size="4"
                          v-model="localParamScale.startPregnancyWeek" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="yMask" id="finishPregnancyWeek" type="text" required size="4"
                          v-model="localParamScale.finishPregnancyWeek" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="newAttr">
          <b-col>
            <label for="startYear"><b>Возраст от:</b> Год</label>
          </b-col>
          <b-col>
            <label for="startMonth">Месяц</label>
          </b-col>
          <b-col>
            <label for="startDay">День</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input v-mask="yMask" id="startYear" type="text" required size="4"
                          v-model="localParamScale.startYear" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="dmMask" id="startMonth" type="text" required size="3"
                          v-model="localParamScale.startMonth" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="dmMask" id="startDay" type="text" required size="3"
                          v-model="localParamScale.startDay" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="newAttr">
          <b-col>
            <label for="finishYear"><b>Возраст до:</b> Год</label>
          </b-col>
          <b-col>
            <label for="finishMonth">Месяц</label>
          </b-col>
          <b-col>
            <label for="finishDay">День</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input v-mask="yMask" id="finishYear" type="text" required size="4"
                          v-model="localParamScale.finishYear" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="dmMask" id="finishMonth" type="text" required size="3"
                          v-model="localParamScale.finishMonth" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="dmMask" id="finishDay" type="text" required size="3"
                          v-model="localParamScale.finishDay" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="newAttr">
          <b-col>
            <label v-if="" for="startValue">
              <b v-if="scaleRangeId===1">Референтный диапазон:</b>
              <b v-else>Целевой диапазон:</b>
              От
            </label>

          </b-col>
          <b-col>
            <label for="finishValue">До</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input v-mask="vMask" id="startValue" type="text" required size="3"
                          v-model="localParamScale.startValue" autocomplete="off"></b-form-input>
          </b-col>
          <b-col>
            <b-form-input v-mask="vMask" id="finishValue" type="text" required size="3"
                          v-model="localParamScale.finishValue" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
<!--        <b-row class="newAttr" v-if="scaleId===1">-->
<!--          <b-col cols="3">-->
<!--            <label v-if="" for="startValueHide">-->
<!--              <b>Скрытая нижняя граница:</b>-->
<!--            </label>-->
<!--          </b-col>-->
<!--          <b-col cols="1">-->
<!--            <b-form-checkbox v-model="startValueHideFlg" name="postmenopause" switch>-->
<!--            </b-form-checkbox>-->
<!--          </b-col>-->
<!--          <b-col cols="8">-->
<!--            <b-form-input v-mask="vMask" id="startValueHide" type="text" required size="3"-->
<!--                          v-model="localParamScale.startValueHide" v-if="startValueHideFlg" autocomplete="off"></b-form-input>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="newAttr" v-if="scaleId===1">-->
<!--          <b-col cols="3">-->
<!--            <label v-if="" for="finishValueHide">-->
<!--              <b>Скрытая верхняя граница:</b>-->
<!--            </label>-->
<!--          </b-col>-->
<!--          <b-col cols="1">-->
<!--            <b-form-checkbox v-model="finishValueHideFlg" name="postmenopause" switch>-->
<!--            </b-form-checkbox>-->
<!--          </b-col>-->
<!--          <b-col cols="8">-->
<!--            <b-form-input v-mask="vMask" id="finishValueHide" type="text" required size="3"-->
<!--                          v-model="localParamScale.finishValueHide" v-if="finishValueHideFlg" autocomplete="off"></b-form-input>-->
<!--          </b-col>-->
<!--        </b-row>-->


        <b-row class="newAttr">
          <b-col cols="4"><b>Патологические состояния и причины отклонений</b>:</b-col>
          <b-col cols="8">
                          <treeselect name="childrenParams" id="childrenParams" placeholder=""
                                      clearable searchable
                                      open-on-click
                                      :noResultsText="'Не найдено...'"
                                      :noOptionsText="'Список опций пуст'"

                                      :max-height="200"
                                      :normalizer="normalizeTreeselect"
                                      v-model="localParamScale.diseaseId"

                                      :options="diseaseOptions | searchQueryFilter(localParamScale, childrenSearchQuery)"

                                       @search-change="childrenSearchChange"

                          />


          </b-col>
        </b-row>

        <b-row class="newAttr" >
          <b-col cols="4">
            <label v-if="" for="priorityReport">
              <b>Приоритет:</b>
            </label>
          </b-col>
          <b-col cols="8">
            <b-form-input  id="priorityReport" type="number" required size="8"
                          v-model="localParamScale.priorityReport"  autocomplete="off"></b-form-input>
          </b-col>
        </b-row>





<!--        <b-row class="newAttr" v-if="localParamScale.scaleId !== 1">-->
<!--          <b-col>-->
<!--            <label for="reportText"><b>Заключение:</b></label>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row v-if="localParamScale.scaleId !== 1">-->
<!--          <b-col>-->
<!--            <ckeditor v-model="localParamScale.reportText"-->
<!--                      :config="editorConfig"></ckeditor>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row class="newAttr">
          <b-col>
            <b-button variant="info" type="submit">Сохранить</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const dayMonthMask = createNumberMask({
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 2,
  prefix: '',
  suffix: ''
})
const yearMask = createNumberMask({
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 3,
  prefix: '',
  suffix: ''
})
const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 4,
  prefix: '',
  suffix: ''
})
export default {
  props: {
    analitId: String,
    paramScaleRange: Object,
    scaleRangeId: Number,
    measurementUnitId: Number
  },
  name: "ParamScaleRangeEdit",
  components: {Treeselect},
  data() {
    return {
      childrenSearchQuery:null,
      error: null,
      startValueHideFlg: false,
      finishValueHideFlg: false,
      scaleCreateState: false,
      scaleCreateName: null,
      scaleCreateReportText: null,
      dmMask: dayMonthMask,
      yMask: yearMask,
      vMask: valueMask,
//      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      localParamScale: this.paramScaleRange ? this.paramScaleRange : {
        scaleRangeId: this.scaleRangeId,
        sexId: 0,
        startYear: 0,
        startMonth: 0,
        startDay: 0,
        finishYear: 0,
        finishMonth: 0,
        finishDay: 0,
        startValue: 0,
        finishValue: 0,
        diseaseId: null,
        priorityReport: 1,
        menstrualCyclePhaseId: null,
        startPregnancyWeek: 0,
        finishPregnancyWeek: 0,
        postpartumWoman: false,
        postmenopause: false,
        pregnant: false,
        measurementUnitId: this.measurementUnitId

      },
      genderOptions: [{value: 0, text: 'мужской и женский'}, {value: 1, text: 'мужской'}, {value: 2, text: 'женский'}],
      zoneOptions: [],
      scaleOptions: [],
      menstrualCyclePhaseOptions: [{value: null, text: ''}],
      diseaseOptions:[],
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text + (node.diseaseCode ? ', ' + node.diseaseCode : '')
        }
      }
    }
  },
  methods: {
    childrenSearchChange(searchQuery, instanceId) {
      this.childrenSearchQuery = searchQuery
    },
    togglePostmenopause(checked) {
      if (checked) {
        this.localParamScale.pregnant = false
        this.localParamScale.postpartumWoman = false
        this.localParamScale.menstrualCyclePhaseId = null
      }
    },
    togglePostpartumWoman(checked) {
      if (checked) {
        this.localParamScale.pregnant = false
        this.localParamScale.postmenopause = false
        this.localParamScale.menstrualCyclePhaseId = null
      }
    },
    togglePregnant(checked) {
      if (checked) {
        this.localParamScale.postpartumWoman = false
        this.localParamScale.postmenopause = false
        this.localParamScale.menstrualCyclePhaseId = null
      }
    },
    switchScaleCreateState() {
      this.scaleCreateState = !this.scaleCreateState
    },
    fetchDictsData() {
      fetch(host + '/rest/factors/Disease', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.diseaseOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/dicts/scalerange')
          .then(response => response.json())
          .then(json => {
            this.scaleOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/dicts/menstrualcyclephase')
          .then(response => response.json())
          .then(json => {
            this.menstrualCyclePhaseOptions = this.menstrualCyclePhaseOptions.concat(json.options)
            console.log(json)
          })
    },
    async createNewScale() {
      let method = 'POST'
      let rq = {text: this.scaleCreateName}
      let response = await fetch(host + '/rest/scaleranges', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(rq),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await response.json()
      if (json.resultCode === 0) {
        this.localParamScale.scaleRangeId = json.entityId
      } else {
        this.error = json.resultCode + ' ' + json.message

      }
      return json.resultCode
    },
    async onSubmit() {
      // if (!this.startValueHideFlg) {
      //   this.localParamScale.startValueHide = null
      // }
      // if (!this.finishValueHideFlg) {
      //   this.localParamScale.finishValueHide = null
      // }
      if (this.scaleCreateState) {
        let result = await this.createNewScale()
        if (result !== 0)
          return
      }
      let method = 'POST'
      if (this.paramScaleRange) {
        method = 'PUT'
      }

      fetch(host + '/rest/params/' + this.analitId + '/scaleranges', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.localParamScale),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              console.log('Save Success ' + JSON.stringify(json))
              this.$emit('save-success')
            } else {
              console.log('Save Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
          })

    }
  },
  filters: {
    filterScaleOptions: function (options, scaleId) {
      console.log('filterScaleOptions' + JSON.stringify(options))
      if (scaleId !== 1) {
        let result = options.filter(item => item.value !== 1)
        return result
      } else {
        return options
      }

    },

    searchQueryFilter: function (options, diseases, searchQuery) {


      console.log('searchQueryFilter ' + searchQuery + (searchQuery ? ' length ' + searchQuery.length : '') + ' string ' + JSON.stringify(diseases))
      let result = []
      if (searchQuery && searchQuery.length >= 2) {
        console.log('filter')
      //   result = options
      // } else {
        result = options.filter((el, index, []) => {
          return diseases.diseaseId === el.value || ((searchQuery) && (el.text + el.diseaseCode).toLowerCase().includes(searchQuery.toLowerCase()) )

        })
      } else {
        result = options.filter((el, index, []) => {
          return diseases.diseaseId === el.value

        })
      }


      return result
    },

  },

  mounted() {
    this.fetchDictsData()
    if (this.paramScale && this.paramScale.startValueHide) {
      this.startValueHideFlg = true;
    }
    if (this.paramScale && this.paramScale.finishValueHide) {
      this.finishValueHideFlg = true;
    }
  }
}
</script>

<style scoped>
.ck-content {
  height: 200px;
}

.newAttr {
  padding-top: 1rem;
}

</style>