<template>
  <div class="main-flex-container">
    <!-----------------ШАПКА МОБ ВЕРСИЯ--------------------------->
    <CabinetHeader v-if="isAuthorized"></CabinetHeader>
    <header v-else>
      <div class="d-block d-lg-none text-center">
        <div class="row mx-0">
          <div class="col-12">
            <div class="row">
              <div class="shadow col-12 pb-1">
                <div class="row">
                  <div class="col-2 ml-md-n4 mt-2">
                    <button class="btn_menu">
                      <i class="fa fa-bars fa-4x px-2"></i>
                    </button>
                  </div>
                  <div class="col-9 mt-1">
                    <img src="../assets/images/Logo_4P.png" class="mob_img" >
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="d-flex justify-content-center">
                  <img src="../assets/images/Icon_car 1.png">
                  <div class="ml-3 mt-3">
                    <div class="ml-2 h6 support">Поддержка</div>
                    <div>
                      <button class="ml-2 pb-1 btn_call_back">
                        <span class="call_back">Перезвонить</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-3">
                  <div>
                    <img src="@/assets/images/Vector1.png">
                    <a href="tel:+7 495 998 32 32" class="tl"> +7 495 998 32 32</a>
                  </div>
                  <div class="mt-1">
                    <img src="@/assets/images/Vector (2).png">
                    <a href="mailto:info@callisto-pro.ru" class="ml"> info@callisto-pro.ru</a>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0 d-lg-none line">
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <div><button class="btn_proposal_to_the_doctor mob_btn">
                  <span class="proposal_to_the_doctor mob_text_btn"> Предложение для врача </span>
                </button></div>
              </div>

              <div class="col-6">
                <div><button class="btn_sign_up_for_training mob_btn">
                  <span class="sign_up_for_training mob_text_btn"> Записаться на обучение </span>
                </button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-----------------ШАПКА КОМП ВЕРСИЯ--------------------------->
      <div class="row d-none d-lg-flex mx-0">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-4 col-xl-4 mt-2 col-md-12 header__logo">
              <router-link to="/"><img src="@/assets/images/Logo_Kalisto.svg" ></router-link>
            </div>
            <div class="col-lg-2 col-xl-2 mt-2 col-md-6 text-center header__buttons">
              <button class="btn_proposal_to_the_doctor">
                <span class="proposal_to_the_doctor"> Предложение для врача </span>
              </button>
            </div>
            <div class="col-lg-2 col-xl-2 mt-2 col-md-6 text-center header__buttons">
              <button class="btn_sign_up_for_training">
                <span class="sign_up_for_training"> Записаться на обучение </span>
              </button>
            </div>
            <div class="col-lg-2 col-xl-2 d-flex col-md-6 justify-content-center ">
              <img src="../assets/images/Icon_car 1.png" >
              <div class="ml-3 mt-3">
                <div class="ml-2 h6 support"> Поддержка </div>
                <div>
                  <button class="ml-2 pb-1 btn_call_back">
                    <span class="call_back">Перезвонить</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-xl-2 mt-3 d-none text-right d-md-block col-md-6 ">
              <div class="row">
                <div class="px-0 col col-lg-4 col-md-3 col-sm-2 text-right">
                  <img src="@/assets/images/Vector1.png">&nbsp;
                </div>
                <div class="px-0 col text-left">
                  <a href="tel:+7 495 998 32 32" class="tl"> +7 495 998 32 32</a>
                </div>
              </div>
              <div class="row">
                <div class="px-0 mt-1 col col-lg-4 col-md-3 col-sm-2 text-right">
                  <img src="@/assets/images/Vector (2).png">&nbsp;
                </div>
                <div class="px-0 mt-1 col text-left">
                  <a href="mailto:info@callisto-pro.ru" class="ml"> info@callisto-pro.ru</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <hr class="mt-lg-0 mb-0 mb-lg-3 line_2">-->
    </header>

    <!-------------------------------------------------->

    <div class="main-content">
<!--      <div v-if="!isAuthorized" class="row mb-md-3 d-none d-lg-flex mx-0">-->
<!--        <div class="col-12">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-6 col-xl-6 mt-2 col-md-12">-->
<!--              <div class="row">-->
<!--                <div class="dropdown">-->
<!--                  <a href="#" class="ml-4 dropbtn"> Меню 1 <div class="arrow-up ml-2 d-inline-block"></div></a>-->
<!--                  <div class="dropdown-content">-->
<!--                    <a href="#">Подраздел 1</a>-->
<!--                    <a href="#">Подраздел 2</a>-->
<!--                    <a href="#">Подраздел 3</a>-->
<!--                    <a href="#">Подраздел 4</a>-->
<!--                    <a href="#">Подраздел 5</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <a href="#" class="ml-4 "> Меню меню 2 <div class="arrow-up ml-2 d-inline-block"></div></a>-->
<!--                <a href="#" class="ml-4 "> Меню 3 <div class="arrow-up ml-2 d-inline-block"></div></a>-->
<!--                <a href="#" class="ml-4 "> Меню меню 4 <div class="arrow-up ml-2 d-inline-block"></div></a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg col-md-6 text-center">-->
<!--              <button class="btn_proposal_to_the_doctor">-->
<!--                <span class="proposal_to_the_doctor"> Предложение для врача </span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="col-lg-auto  col-md-6">-->
<!--              <button class="btn_sign_up_for_training">-->
<!--                <span class="sign_up_for_training"> Записаться на обучение </span>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="isAuthorized" class="position-relative">
        <img src="@/assets/images/BAnner.png" class="w-100 banner_mob">

      </div>
      <div v-if="!isAuthorized" class="position-relative oblast">
        <img src="@/assets/images/BAnner.png" class="position-absolute w-100 ban" >
        <div class="mt-4 form mob_banner">
          <LoginIndex ></LoginIndex>
<!--          <form action="#" class="mx-4 my-4">-->
<!--            <div class="log" >Логин</div>-->
<!--            <input type="text" class="mt-2 log_in">-->
<!--            <div class="mt-2 pass"><span>Пароль</span></div>-->
<!--            <div class="password pass_icon">-->
<!--              <input id="password-input" type="password" name="password" class="mt-2 position-relative pass_in">-->
<!--              <a href="#" class="password-control pass_eyes img_pass_eyes"  onclick="return show_hide_password(this);"></a>-->
<!--            </div>-->
<!--            <div class="text-center mt-3 remember_me"><input type="checkbox"> Запомнить меня </div>-->
<!--            <a href="#"><div class="text-center mt-3 mb-4 forgot_pass">Забыли пароль?</div></a>-->
<!--            <div class="mt-3"><button class="btn_enter"><span class="enter">Войти</span></button></div>-->
<!--            <a href="#"><div class="text-center mt-3 check_in" >Регистрация</div></a>-->
<!--          </form>-->

<!--          <script>-->
<!--            $('body').on('click', '.password-control', function(){-->
<!--              if ($('#password-input').attr('type')=='password'){-->
<!--                $('#password-input').attr('type', 'text');-->
<!--              } else {-->
<!--                $('#password-input').attr('type', 'password');-->
<!--              }-->
<!--              return false;-->
<!--            });-->
<!--          </script>-->
        </div>
      </div>

      <div class="row justify-content-around mx-0">
        <div class="my-4 mx-2 info">
          <div class="info__head">
            <a class="info__link text_decor_none">
              Для врача
<!--              <img src="../assets/images/main/arrow_down.png" />-->
            </a>
          </div>
          <div class="info__body my-2">
            <figure><img src="@/assets/images/main/1_for_doctor.png" />
            <div class="info_buttons">
              <button class="info__button my-2" @click="openDoctorManual">Инструкция</button>
              <button class="info__button my-2" @click="openAbout">О программе</button>
            </div>
            </figure>
          </div>
        </div>

        <div class="my-4 mx-2 info">
          <div class="info__head">
            <a @click="openCommonReportMenu" class="info__link">
              Примеры обобщенного отчета
                            <img src="@/assets/images/main/arrow_down.png" />
            </a>
            <div :class="commonReportMenuFlg ? 'info__menu info__menu-visible' : 'info__menu'">
              <a @click="openCommonReportMenu" class="info__link">
                Примеры обобщенного отчета
                              <img src="@/assets/images/main/arrow_up.png" />
              </a>
              <div class="info__menuhr"></div>
              <div class="info__menuitem text-left" v-for="item in reportCommonMenuItems" :key="item.key" @click="openMenuItem(item)">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="info__body my-2">
            <img src="@/assets/images/main/2_example_report_common.png" />

          </div>
        </div>

        <div class="my-4 mx-2 info">
          <div class="info__head">
            <a @click="openShortReportMenu" class="info__link">
              Примеры сокращенного отчета
                            <img src="@/assets/images/main/arrow_down.png" />
            </a>
            <div :class="shortReportMenuFlg ? 'info__menu info__menu-visible' : 'info__menu'">
              <a @click="openShortReportMenu" class="info__link">
                Примеры сокращенного отчета
                              <img src="@/assets/images/main/arrow_up.png" />
              </a>
              <div class="info__menuhr"></div>
              <div class="info__menuitem text-left" v-for="item in reportShortMenuItems" :key="item.key" @click="openMenuItem(item)">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="info__body my-2">
            <img src="@/assets/images/main/3_example_report_short.png" />
          </div>
        </div>

        <div class="my-4 mx-2 info">
          <div class="info__head">
            <a @click="openGraphicReportMenu" class="info__link">
              Примеры графических отчётов
                            <img src="@/assets/images/main/arrow_down.png" />
            </a>
            <div :class="graphicReportMenuFlg ? 'info__menu info__menu-visible' : 'info__menu'">
              <a @click="openGraphicReportMenu" class="info__link">
                Примеры графических отчета
                              <img src="@/assets/images/main/arrow_up.png" />
              </a>
              <div class="info__menuhr"></div>
              <div class="info__menuitem text-left" v-for="item in reportGraphicMenuItems" :key="item.key" @click="openMenuItem(item)">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="info__body my-2">
            <img src="@/assets/images/main/4_example_report_graphic.png" />
          </div>
        </div>


      </div>
    </div>

    <footer>
      <div class="row mx-0 oblast_foot ">
        <div class="col-md-6 mt-1"><p class="foot">© 2021-2023 Каллисто. Все права защищены</p></div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Лицензиии и сертификаты</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Условия конфиденциальности</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md text-md-center"><p class="foot"><a href="#"  class="foot"> Контактная информация </a></p></div>
      </div>
    </footer>
  </div>
</template>

<script>
import LoginIndex from "@/components/LoginIndex";
import CabinetHeader from "@/components/CabinetHeader";
import {host, roles} from "@/constants";
export default {
  name: "IndexLayout",
  data() {
    return {
      commonReportMenuFlg: false,
      shortReportMenuFlg: false,
      graphicReportMenuFlg: false,
      reportCommonMenuItems: [
        {key: 1, label: "Для беременной женщины", url: host + "/files/ReportCommon1.pdf"},
        {key: 2, label: "Для мужчины 30 лет", url: host + "/files/ReportCommon2.pdf"},
        {key: 3, label: "Oтчёт с исключением неспецифических связей", url: host + "/files/ReportCommon3.pdf"},
        {key: 4, label: "Для беременной женщины", url: host + "/files/ReportCommon4.pdf"},
        {key: 5, label: "Для мужчины 30 лет", url: host + "/files/ReportCommon5.pdf"}
        // {key: 6, label: "", url: host + "/files/ReportCommon6.pdf"},
        // {key: 7, label: "", url: host + "/files/ReportCommon7.pdf"},
        // {key: 8, label: "", url: host + "/files/ReportCommon8.pdf"},
        // {key: 9, label: "", url: host + "/files/ReportCommon9.pdf"},
        // {key: 10, label: "", url: host + "/files/ReportCommon10.pdf"}
      ],
      reportShortMenuItems: [
        {key: 1, label: "Для беременной женщины", url: host + "/files/ReportShort1.pdf"},
        {key: 2, label: "Для мужчины 30 лет", url: host + "/files/ReportShort2.pdf"},
        {key: 3, label: "Oтчёт с исключением неспецифических связей", url: host + "/files/ReportShort3.pdf"},
        {key: 4, label: "Для беременной женщины", url: host + "/files/ReportShort4.pdf"},
        {key: 5, label: "Для мужчины 30 лет", url: host + "/files/ReportShort5.pdf"}
        // {key: 6, label: "", url: host + "/files/ReportShort6.pdf"},
        // {key: 7, label: "", url: host + "/files/ReportShort7.pdf"},
        // {key: 8, label: "", url: host + "/files/ReportShort8.pdf"},
        // {key: 9, label: "", url: host + "/files/ReportShort9.pdf"},
        // {key: 10, label: "", url: host + "/files/ReportShort10.pdf"}
      ],
      reportGraphicMenuItems: [
        {key: 1, label: "Для беременной женщины", url: host + "/files/ReportGraphic1.pdf"},
        {key: 2, label: "Для мужчины 30 лет", url: host + "/files/ReportGraphic2.pdf"},
        {key: 3, label: "Oтчёт с исключением неспецифических связей", url: host + "/files/ReportGraphic3.pdf"},
        {key: 4, label: "Для беременной женщины", url: host + "/files/ReportGraphic4.pdf"},
        {key: 5, label: "Для мужчины 30 лет", url: host + "/files/ReportGraphic5.pdf"}
        // {key: 6, label: "", url: host + "/files/ReportGraphic6.pdf"},
        // {key: 7, label: "", url: host + "/files/ReportGraphic7.pdf"},
        // {key: 8, label: "", url: host + "/files/ReportGraphic8.pdf"},
        // {key: 9, label: "", url: host + "/files/ReportGraphic9.pdf"},
        // {key: 10, label: "", url: host + "/files/ReportGraphic10.pdf"}
      ]
    }
  },
  components: {CabinetHeader, LoginIndex},
  computed: {
    isAuthorized: {
      get() {
        return this.$store.getters.isAuthorized
      },
      set(value) {
        this.$store.commit('updateIsAuthorized', value)
      }
    },
    role: {
      get() {
        return this.$store.getters.role
      },
      set(value) {
        this.$store.commit('updateRole', value)
      }
    },
    rolename: {
      get() {
        return this.$store.getters.rolename
      },
      set(value) {
        this.$store.commit('updateRolename', value)
      }
    },
    username: {
      get() {
        return this.$store.getters.username
      },
      set(value) {
        this.$store.commit('updateUsername', value)
      }
    },
    userId: {
      get() {
        return this.$store.getters.userId
      },
      set(value) {
        this.$store.commit('updateUserId', value)
      }
    },
    activationDaysLeftCount: {
      get() {
        return this.$store.getters.activationDaysLeftCount
      },
      set(value) {
        this.$store.commit('updateActivationDaysLeftCount', value)
      }
    },
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      },
      set(value) {
        this.$store.commit('updateReportDetailedBlock', value)
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      },
      set(value) {
        this.$store.commit('updateReportGeneralBlock', value)
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      },
      set(value) {
        this.$store.commit('updateReportConciseBlock', value)
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      },
      set(value) {
        this.$store.commit('updateReportPatientBlock', value)
      }
    },
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      },
      set(value) {
        this.$store.commit('updateReportGraphicBlock', value)
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      },
      set(value) {
        this.$store.commit('updatePermission', value)
      }
    }
  },
  methods: {
    openDoctorManual() {
      window.open(host + '/files/DoctorManual.pdf','_blank');
    },
    openAbout() {
      window.open(host + '/files/About.pdf','_blank');
    },
    openMenuItem( item) {
      window.open(item.url, '_blank')
    },
    openCommonReportMenu() {
      this.commonReportMenuFlg = !this.commonReportMenuFlg
    },
    openShortReportMenu() {
      console.log(this.shortReportMenuFlg)
      this.shortReportMenuFlg = !this.shortReportMenuFlg
      console.log(this.shortReportMenuFlg)
    },
    openGraphicReportMenu() {
      console.log(this.graphicReportMenuFlg)
      this.graphicReportMenuFlg = !this.graphicReportMenuFlg
      console.log(this.graphicReportMenuFlg)
    }
  },
  mounted() {

    fetch(host + '/rest/session', {
      method: 'GET',
      credentials: 'include',
      headers: {'Content-Type': 'application/json; charset=utf-8'}
    }).then(response => response.json())
        .then(json => {
          if (json.resultCode == 0) {
            this.isAuthorized = true
            this.role = json.role
            this.rolename = roles.filter(t => t.id == this.role)[0].name
            this.username = json.username
            this.userId = json.userId
            this.activationDaysLeftCount = json.activationDaysLeftCount
            this.reportDetailedBlock = json.reportDetailedBlock
            this.reportGeneralBlock = json.reportGeneralBlock
            this.reportConciseBlock = json.reportConciseBlock
            this.reportPatientBlock = json.reportPatientBlock
            this.reportGraphicBlock = json.reportGraphicBlock
            this.permission = json.permission
            console.log('Role: ' + roles.filter(t => t.id == this.role)[0].name)
            //this.$router.push('/patients')
            console.log('Login Success ' + JSON.stringify(json))
          } else {

            console.log('Login Failed ' + json)
            this.isAuthorized = false
            this.role = 0
          }
        }).catch(error => {
      console.log(JSON.stringify(error))
    })
  }
}
</script>

<style >

@import '../assets/css/main_page.css';


.info {
  background:#FFFFFF;
  border-radius: 9px;
  width: 275px;
}



.info__head{
  width: 275px;
  height: 26px;
  text-align: center;
  position: relative;

}

.info__link {

  font-family: MyriadProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  /* or 16px */

  text-transform: uppercase;

  color: #2FB671;

}
.text_decor_none {
  text-decoration: none!important;
}
.text_decor_none:hover {
  text-decoration: none!important;
}

.info__link:hover {

  font-family: MyriadProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;

  /* or 16px */

  text-transform: uppercase;

  color: #2FB671;

}

.info__body {
  position: relative;
}

.info__body div {
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: 2;
  display: block;
  width: 275px;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  opacity: 0;
}

figure:hover div {
  bottom: 80px;
  opacity: 1;
}



.info__button {
  box-sizing: border-box;

  width: 153px;
  height: 34px;

  background: #FFFFFF;
  border: 1.5px solid #2FB671;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;

  font-family: MyriadProRegular;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #2FB671;
}

.info__button:hover {

  width: 153px;
  height: 34px;

  background: linear-gradient(91.33deg, #128E91 0.6%, #2FB671 99.4%);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  color: #FFFFFF;
}

.info__menu {

  position: absolute;
  width: 275px;
  height: 379px;
  left: 0px;
  top: 0px;

  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  opacity: 0.95;
  z-index: -1;

  transition: z-index 0.3s ease-in-out;
  -webkit-transition: z-index 0.3s ease-in-out;
  display: none;
}

.info__menu-visible {
  display: block;
  z-index: 2;
}

.info__menuhr{

  width: 244px;
  height: 0px;
  margin-left: 15px;
  margin-top: 10px;

  border: 1px solid rgba(130, 158, 158, 0.5);
}



</style>