<template>
  <b-container fluid>
<!--    <b-row>-->
<!--      <b-col cols="12">&nbsp;</b-col>-->
<!--    </b-row>-->
<!--    <b-form-group id="ig1" label-for="param" label="Наименование аналита">-->
<!--      <b-form-input v-model="searchRequest" autocomplete="off" type="text" @update="filterParams"></b-form-input>-->
<!--    </b-form-group>-->

<!--    <hr width="100%" color="gray">-->
    <b-row>
      <b-col cols="12">
        <b-radio-group  :options="radioOptions" v-model="radioValue" @change="fetchReportList"></b-radio-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group label-cols="3" label="Выберите отчет">
          <b-select :options="complexReports" v-model="complexReportId" :value-field="'complexReportId'" :text-field="'complexReportNameComment'" @change="fetchRecommendedParams"></b-select>
        </b-form-group>
      </b-col>
    </b-row>
<!--    <b-row>-->
<!--      {{radioValue}}-->
<!--    </b-row>-->
    <b-row>
      <b-col cols="6">
        <button class="save_btn_common w-100" @click="addAllParams()">Назначить все исследования</button>
        <button class="save_btn_common w-100" @click="addBaseParams()">Назначить базовые исследования<b-spinner v-if="savingSelectAll" variant="light" small></b-spinner></button>
        <button class="save_btn_common w-100" @click="addSpecifyingParams()">Назначить уточняющие исследования<b-spinner v-if="savingSelectAll" variant="light" small></b-spinner></button>
        <button class="save_btn_common w-100 pl-2 pr-2" @click="addDetailedParams()">Назначить детализирующие исследования<b-spinner v-if="savingSelectAll" variant="light" small></b-spinner></button>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col cols="5" class="text-right">
        <button class="save_btn_common w-100" @click="fetchRecommendedParams()">Обновить список исследований<b-spinner v-if="savingUpdate" variant="light" small></b-spinner></button>
        <button class="save_btn_common_orange w-100" @click="deleteRecommendedAnalits()">Очистить список исследований<b-spinner v-if="savingDelete" variant="light" small></b-spinner></button>
      </b-col>


<!--      <button class="save_btn_common" @click="clearRecommendedAnalits()">Очистить список исследований</button>-->
    </b-row>
    <b-row v-if="recommendedParams701 && recommendedParams701.length > 0">
<!--      <span class="title title701">Строго рекомендуется назначить дополнительные лабораторные исследования</span>-->
      <img src="@/assets/images/plashki/5_Cetnay_plashka-01.png" width="100%" />
    </b-row>
    <b-row v-if="recommendedParams701 && recommendedParams701.filter(el => (el.priorityReport == 1)).length > 0"><span style="font-size: 20px;"><strong>Базовые исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams701.filter(el => (el.priorityReport == 1))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 701)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams701 && recommendedParams701.filter(el => (el.priorityReport == 2)).length > 0"><span style="font-size: 20px;"><strong>Уточняющие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams701.filter(el => (el.priorityReport == 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 701)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams701 && recommendedParams701.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2)).length > 0"><span style="font-size: 20px;"><strong>Детализирующие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams701.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 701)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams702 && recommendedParams702.length > 0">
      <img src="@/assets/images/plashki/5_Cetnay_plashka-02.png" width="100%" />
<!--      <span class="title title702">Рекомендуется назначить дополнительные лабораторные исследования</span>-->
    </b-row>
    <b-row v-if="recommendedParams702 && recommendedParams702.filter(el => (el.priorityReport == 1)).length > 0"><span style="font-size: 20px;"><strong>Базовые исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams702.filter(el => (el.priorityReport == 1))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 702)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams702 && recommendedParams702.filter(el => (el.priorityReport == 2)).length > 0"><span style="font-size: 20px;"><strong>Уточняющие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams702.filter(el => (el.priorityReport == 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 702)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams702 && recommendedParams702.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2)).length > 0"><span style="font-size: 20px;"><strong>Детализирующие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams702.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 702)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams703 && recommendedParams703.length > 0">
      <img src="@/assets/images/plashki/5_Cetnay_plashka-03.png" width="100%" />
<!--      <span class="title title703">Желательно назначить дополнительные лабораторные исследования</span>-->
    </b-row>
    <b-row v-if="recommendedParams703 && recommendedParams703.filter(el => (el.priorityReport == 1)).length > 0"><span style="font-size: 20px;"><strong>Базовые исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams703.filter(el => (el.priorityReport == 1))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 703)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams703 && recommendedParams703.filter(el => (el.priorityReport == 2)).length > 0"><span style="font-size: 20px;"><strong>Уточняющие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams703.filter(el => (el.priorityReport == 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 703)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams703 && recommendedParams703.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2)).length > 0"><span style="font-size: 20px;"><strong>Детализирующие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams703.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 703)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams704 && recommendedParams704.length > 0">
      <img src="@/assets/images/plashki/5_Cetnay_plashka-04.png" width="100%" />
<!--      <span class="title title704">Рекомендуется повторить лабораторные исследования</span>-->
    </b-row>
    <b-row v-if="recommendedParams704 && recommendedParams704.filter(el => (el.priorityReport == 1)).length > 0"><span style="font-size: 20px;"><strong>Базовые исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams704.filter(el => (el.priorityReport == 1))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 704)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams704 && recommendedParams704.filter(el => (el.priorityReport == 2)).length > 0"><span style="font-size: 20px;"><strong>Уточняющие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams704.filter(el => (el.priorityReport == 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 704)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row v-if="recommendedParams704 && recommendedParams704.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2)).length > 0"><span style="font-size: 20px;"><strong>Детализирующие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in recommendedParams704.filter(el => (el.priorityReport !== 1 && el.priorityReport !== 2))" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
      </b-col>
      <b-col cols="8" >
        <b-icon :id="'param_rec_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_rec_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span class="priorityGreen">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
        <span :class="(item.priorityReport == 1 ? 'priorityRed' : (item.priorityReport == 2 ? 'priorityGreen' : 'priorityBlack'))">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(item, 704)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  computed: {
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      }
    },
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      }
    }
  },
  props: ["patientId"],
  name: "PatientRecommendRecommendations",
  data() {
    return {
      searchRequest: null,
      recommendedParams701: [],
      recommendedParams702: [],
      recommendedParams703: [],
      recommendedParams704: [],
      params: [],
      radioOptions: [
        // {value: 0, text:"Все отчеты"},
        {value: 1, text: "Подробные отчеты"},
        {value: 2, text: "Обобщенные отчеты"},
        {value: 3, text: "Сокращенные отчеты"},
        {value: 4, text: "Отчеты пациента"}
      ],
      radioValue: 0,
      complexReports: [],
      complexReportId: 0,
      savingSelectAll: false,
      savingUpdate: false,
      savingDelete: false,

    }
  },
  mounted() {
    this.fetchReportList()
    this.radioOptions = []
    if (this.reportDetailedBlock == false) {
      this.radioOptions.push( {value: 1, text: "Подробные отчеты"})
    }
    if (this.reportGeneralBlock == false) {
      this.radioOptions.push( {value: 2, text: "Обобщенные отчеты"})
    }
    if (this.reportConciseBlock == false) {
      this.radioOptions.push( {value: 3, text: "Сокращенные отчеты"})
    }
    if (this.reportPatientBlock == false) {
      this.radioOptions.push( {value: 4, text: "Отчеты пациента"})
    }
    if (this.reportGraphicBlock == false) {
      this.radioOptions.push( {value: 5, text: "Графические отчеты"})
    }
  },
  methods: {
    deleteRecommendedAnalits() {
      this.savingDelete = true
      this.recommendedParams701 = []
      this.recommendedParams702 = []
      this.recommendedParams703 = []
      this.recommendedParams704 = []
      this.savingDelete = false
    },
    deleteRecommendedAnalit(item, index) {
      if (index == 701) {
        this.recommendedParams701 = this.recommendedParams701.filter(el => (item.paramId !== el.paramId))
      } else if (index == 702) {
        this.recommendedParams702 = this.recommendedParams702.filter(el => (item.paramId !== el.paramId))
      } else if (index == 703) {
        this.recommendedParams703 = this.recommendedParams703.filter(el => (item.paramId !== el.paramId))
      } else if (index == 704) {
        this.recommendedParams704 = this.recommendedParams704.filter(el => (item.paramId !== el.paramId))
      }

    },
    addBaseParams() {
      let params = this.mainRecommendedParams()
      this.$emit('selectParams', params)

      this.recommendedParams701 = this.recommendedParams701.filter(el => (el.priorityReport !== 1 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams702 = this.recommendedParams702.filter(el => (el.priorityReport !== 1 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams703 = this.recommendedParams703.filter(el => (el.priorityReport !== 1 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams704 = this.recommendedParams704.filter(el => (el.priorityReport !== 1 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
    },
    addSpecifyingParams() {
      let params = this.specifyingRecommendedParams()
      this.$emit('selectParams', params)

      this.recommendedParams701 = this.recommendedParams701.filter(el => (el.priorityReport !== 2 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams702 = this.recommendedParams702.filter(el => (el.priorityReport !== 2 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams703 = this.recommendedParams703.filter(el => (el.priorityReport !== 2 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams704 = this.recommendedParams704.filter(el => (el.priorityReport !== 2 && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
    },
    addDetailedParams() {
      let params = this.detailedRecommendedParams()
      this.$emit('selectParams', params)

      this.recommendedParams701 = this.recommendedParams701.filter(el => ( (el.priorityReport == 1 || el.priorityReport == 2)  && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams702 = this.recommendedParams702.filter(el => ( (el.priorityReport == 1 || el.priorityReport == 2) && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams703 = this.recommendedParams703.filter(el => ( (el.priorityReport == 1 || el.priorityReport == 2) && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
      this.recommendedParams704 = this.recommendedParams704.filter(el => ( (el.priorityReport == 1 || el.priorityReport == 2) && params.findIndex(p => ( ( p.parentParamId && el.parentParamId && p.parentParamId === el.parentParamId) || p.paramId === el.parentParamId || p.parentParamId === el.paramId || p.paramId === el.paramId  )  ) < 0 ))
    },
    mainRecommendedParams() {
      let mainRecommendedParams = []
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams701)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams702)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams703)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams704)

      console.log(JSON.stringify(mainRecommendedParams))

      mainRecommendedParams = mainRecommendedParams.filter((el, index, arr) => {
        //console.log(el.paramId + ' ' + el.priorityReport + ' ' + el.parentParamId + ' ' + index + ' ' + arr.findIndex(el1 => ((el1.parentParamId === el.parentParamId || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId))))
        if (el.priorityReport === 1
            && (arr.findIndex(el1 =>  (el1.priorityReport === 1 && ( ( el1.parentParamId && el.parentParamId && el1.parentParamId === el.parentParamId) || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId)  )  ) === index)) {
          return true
        } else {
          return false
        }

      })

      console.log(JSON.stringify(mainRecommendedParams))
      return mainRecommendedParams
    },
    specifyingRecommendedParams() {
      let mainRecommendedParams = []
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams701)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams702)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams703)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams704)

      console.log(JSON.stringify(mainRecommendedParams))

      mainRecommendedParams = mainRecommendedParams.filter((el, index, arr) => {
        //console.log(el.paramId + ' ' + el.priorityReport + ' ' + el.parentParamId + ' ' + index + ' ' + arr.findIndex(el1 => ((el1.parentParamId === el.parentParamId || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId))))
        if (el.priorityReport === 2
            && (arr.findIndex(el1 =>  (el1.priorityReport === 2 && ( ( el1.parentParamId && el.parentParamId && el1.parentParamId === el.parentParamId) || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId)  )  ) === index)) {
          return true
        } else {
          return false
        }

      })

      console.log(JSON.stringify(mainRecommendedParams))
      return mainRecommendedParams
    },
    detailedRecommendedParams() {
      let mainRecommendedParams = []
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams701)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams702)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams703)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams704)

      console.log(JSON.stringify(mainRecommendedParams))

      mainRecommendedParams = mainRecommendedParams.filter((el, index, arr) => {
        //console.log(el.paramId + ' ' + el.priorityReport + ' ' + el.parentParamId + ' ' + index + ' ' + arr.findIndex(el1 => ((el1.parentParamId === el.parentParamId || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId))))
        if (el.priorityReport !== 1 && el.priorityReport !== 2
            && (arr.findIndex(el1 =>  (el1.priorityReport !== 1 && el1.priorityReport !== 2 && ( ( el1.parentParamId && el.parentParamId && el1.parentParamId === el.parentParamId) || el1.paramId === el.parentParamId || el1.parentParamId === el.paramId || el1.paramId === el.paramId)  )  ) === index)) {
          return true
        } else {
          return false
        }

      })

      console.log(JSON.stringify(mainRecommendedParams))
      return mainRecommendedParams
    },
    mainAllParams() {
      let mainRecommendedParams = []
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams701)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams702)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams703)
      mainRecommendedParams = mainRecommendedParams.concat( this.recommendedParams704)

      mainRecommendedParams = mainRecommendedParams.filter((el, index, arr) => {
        if (arr.findIndex(el1 => (( el1.parentParamId && el.parentParamId && el1.parentParamId === el.parentParamId) || el1.paramId == el.parentParamId || el1.parentParamId == el.paramId || el1.paramId == el.paramId)) == index) {
          return true
        } else {
          return false
        }

      })

      return mainRecommendedParams
    },
    editClientAnalit(item = null) {
      if (item) {
        this.$emit('selectParam', item)
        if (item.parentParamId) {
          this.recommendedParams701 = this.recommendedParams701.filter(el => (el.parentParamId !== item.parentParamId))
          this.recommendedParams702 = this.recommendedParams702.filter(el => (el.parentParamId !== item.parentParamId))
          this.recommendedParams703 = this.recommendedParams703.filter(el => (el.parentParamId !== item.parentParamId))
          this.recommendedParams704 = this.recommendedParams704.filter(el => (el.parentParamId !== item.parentParamId))
        } else {
          this.recommendedParams701 = this.recommendedParams701.filter(el => (el.paramId !== item.paramId))
          this.recommendedParams702 = this.recommendedParams702.filter(el => (el.paramId !== item.paramId))
          this.recommendedParams703 = this.recommendedParams703.filter(el => (el.paramId !== item.paramId))
          this.recommendedParams704 = this.recommendedParams704.filter(el => (el.paramId !== item.paramId))
        }
      }

    },
    addAllParams() {
      this.$emit('selectParams', this.mainAllParams())

      this.recommendedParams701 = []
      this.recommendedParams702 = []
      this.recommendedParams703 = []
      this.recommendedParams704 = []

    },
    fetchReportList() {
      //console.log(this.radioValue)
      if (this.radioValue != 0) {
        fetch(host + '/rest/patients/' + this.patientId + '/reports/complex/' + this.radioValue, {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.complexReports = json.reports
            }).catch(error => {
          console.log(error)
        })
      }
    },
    fetchRecommendedParams() {
      this.savingUpdate = true
      fetch(host + '/rest/patients/' + this.patientId + '/reportrecommendedparams/' + this.complexReportId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.recommendedParams701 = json.params.filter((el, i, arr) => (el.priorityReportRecommended == 701 && arr.findIndex(el1 => (el1.priorityReportRecommended == 701 && el1.paramId === el.paramId )) == i ))
            this.recommendedParams702 = json.params.filter((el, i, arr) => (el.priorityReportRecommended == 702 && arr.findIndex(el1 => (el1.priorityReportRecommended == 702 && el1.paramId === el.paramId )) == i ))
            this.recommendedParams703 = json.params.filter((el, i, arr) => (el.priorityReportRecommended == 703 && arr.findIndex(el1 => (el1.priorityReportRecommended == 703 && el1.paramId === el.paramId )) == i ))
            this.recommendedParams704 = json.params.filter((el, i, arr) => ( (
                (el.priorityReportRecommended != 701 && el.priorityReportRecommended != 702 && el.priorityReportRecommended != 703)  || !el.priorityReportRecommended)  && arr.findIndex(el1 => (((el1.priorityReportRecommended != 701 && el1.priorityReportRecommended != 702 && el1.priorityReportRecommended != 703) || !el1.priorityReportRecommended) && el1.paramId === el.paramId )) == i  ))
            //console.log(JSON.stringify(this.recommendedParams))
            this.savingUpdate = false
          })
    }
  }

}
</script>

<style scoped>

.priorityRed {
  color: #e17f27;
}

.priorityGreen {
  /*color: #0f6667;*/
  color: #039022;
}

.priorityBlack {
  color: #282828;
}
.title {
  border-radius: 15px;
  min-height: 30px;
  width: 100%;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 10px 5px 10px;
}

.title701 {
  background-color: #9a1b1e;
}
.title702 {
  background-color: #e62d24;
}
.title703 {
  background-color: #eaad35;
}
.title704 {
  background-color: #75c695;
}

</style>