<template>
  <span v-if="zoneId == 1">
    <img height="24px" src="@/assets/images/plashki/arrow-dark-red-down-double.png">
  </span>
  <span v-else-if="zoneId == 2">
    <img height="24px" src="@/assets/images/plashki/arrow-red-down-double.png">
  </span>
  <span v-else-if="zoneId == 3">
    <img height="24px" src="@/assets/images/plashki/arrow-orange-down.png">
  </span>
  <span v-else-if="zoneId == 4">
    <img height="24px" src="@/assets/images/plashki/arrow-yellow-down.png">
  </span>
  <span v-else-if="zoneId == 5">
    <img height="24px" src="@/assets/images/plashki/arrow-dashed-green-down.png">
  </span>
  <span v-else-if="zoneId == 7 || zoneId == 17 || zoneId == 26">
    <img height="24px" src="@/assets/images/plashki/arrow-dashed-green-up.png">
  </span>
  <span v-else-if="zoneId == 8 || zoneId == 23">
    <img height="24px" src="@/assets/images/plashki/arrow-yellow-up.png">
  </span>
  <span v-else-if="zoneId == 9 || zoneId == 20 || zoneId == 22">
    <img height="24px" src="@/assets/images/plashki/arrow-orange-up.png">
  </span>
  <span v-else-if="zoneId == 10 || zoneId == 21">
    <img height="24px" src="@/assets/images/plashki/arrow-red-up-double.png">
  </span>
  <span v-else-if="zoneId == 11">
    <img height="24px" src="@/assets/images/plashki/arrow-dark-red-up-double.png">
  </span>
  <span v-else-if="zoneId == 18">
    <img height="24px" src="@/assets/images/plashki/plus-red.png">
  </span>
  <span v-else>
    <img height="24px" src="@/assets/images/plashki/arrow-green-horizontal.png">
  </span>
</template>

<script>
export default {
  props: ["zoneId"],
  name: "ZoneArrow",
  mounted() {
    console.log(this.zoneId)
  }
}
</script>

<style scoped>

</style>