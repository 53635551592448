<template>
  <div>

    <div class=" background_E9F5EA_page_7 padding100" v-for="item in elements" :key="item.paramId">

      <div class="row mx-0 analit-simple">
        <div class="col-lg-6 col-6 col-sm-12 d-flex py-3 ">{{ item.param.name }}, {{item.param.unit}}</div>
        <div class="col-6 col-lg-6 col-sm-12">
        <treeselect class="custom-select_2 border-0 w-100  parametrs padding_0"
                    placeholder=""
                    clearable searchable
                    open-on-click close-on-select disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :noOptionsText="'Список опций пуст'"
                    :options="selectOptions | searchQueryFilter(item, item.childrenSearchQuery)"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="item.paramIdFile" style="font-size: 15px!important;" @select="onParamSelect1($event, item)" @search-change="childrenSearchChange($event, item)">
          <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
            {{node.label | nodeLabelFilter}}
          </label>
          <label slot="value-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
            {{node.label | nodeLabelFilter}}
          </label>
        </treeselect>
<!--        <b-button v-if="item.paramType === 4 || item.paramType === 8 || item.paramType === 9" variant="info" @click="toogleItemOpenChild(item)"> {{item.openedChild === true ? '-' : '+'}}</b-button>-->
        </div>

      </div>

      <div class="row mx-0">
        <div :class="(item.paramType == 1 || item.paramType === 3) ? 'col-lg-6 col-sm-7 row_padding': 'col-lg-9 col-sm-7 row_padding'"
             v-if="!(item.paramType == 4 || item.paramType === 5 || item.paramType === 6 || item.paramType == 8 || item.paramType == 9)">
          <div class="row text-center mb-3 mb-lg-0 h-100"  >
            <div class="col-md-6 col-6 px-0"
              v-if="(item.paramType === 1 || item.paramType === 3 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
              <div class="px-0"><span class="attribute_center_page_7">Значение</span></div>
              <div class="px-0 row_margin">
                <b-input-group prepend="1:" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)" class="border-0 w-100 h_30_border_radius">
                  <b-form-input v-mask="mask" class="text-left" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)"></b-form-input>
                </b-input-group>
                <input class="border-0 w-100 h_30_border_radius" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.param.value" autocomplete="off" @change="paramValueSelect($event, item)">
                <!--              <input type="text" >-->

              </div>
            </div>
            <div  class="col-md-6 col-6 px-0"
                  v-if="(item.paramType === 1 || item.paramType === 3 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
              <div class="px-0"><span class="attribute_center_page_7">Единицы измерения</span></div>
              <div class="px-0 row_margin">
                <!--              <b-form-select id="measure" v-model="item.measurementUnitId" :options="item.measurementOptions" :select-size="1" size="1"-->
                <!--                             class="custom-select border-0 pt-0 w-75 inside_the_field h_30"></b-form-select>-->

                <select v-model="item.param.unit" @change="onMeasurementUnitSelect(item)" class="custom-select border-0 pt-0 w-100 inside_the_field h_30">
                  <option v-for="option in item.measurementOptions" v-bind:value="option.text" >
                    {{ option.text }}
                  </option>
                </select>

              </div>
            </div>

            <div class="col-md-12 col-12 px-0" v-if="(item.paramType === 2 || (item.paramType === 7 && item.propertyValueOptions && item.propertyValueOptions.length > 0))">
              <div class="px-0"><span class="attribute_center_page_7">Выбор значения</span></div>
              <div class="px-0 row_margin">
                <b-form-select id="propertyValue" v-model="item.propertyValue" :options="item.propertyValueOptions" :select-size="1" @change="propertyValueSelect($event, item)"
                               class="custom-select border-0 pt-0 w-100 inside_the_field h_30"></b-form-select>
              </div>
            </div>

            <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)">
              <div class="px-0"><span class="attribute_center_page_7">Титры</span></div>
              <div class="px-0 row_margin">
                <b-form-select id="titres" v-model="item.titres" :options="item.titreOptions" :select-size="1" @change="titresSelect($event, item)"
                               class="custom-select border-0 pt-0 w-100 inside_the_field h_30"></b-form-select>
              </div>
            </div>

            <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.plusMinusOptions && item.plusMinusOptions.length > 0)">
              <div class="px-0"><span class="attribute_center_page_7">Кресты</span></div>
              <div class="px-0 row_margin">
                <b-form-select id="measure" v-model="item.plusMinus" :options="item.plusMinusOptions" :select-size="1" @change="plusMinusSelect($event, item)"
                               class="custom-select border-0 pt-0 w-100 inside_the_field h_30"></b-form-select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="item.paramType == 1 || item.paramType === 3" class="col-lg-3 col-sm-12 text-center " >
          <div class="row h-100">
            <div class="col-12 ">
              <div>
									<span class="attribute_center_page_7">Референтный диапазон
									</span>
              </div>
              <div  class="d-flex justify-content-center mb-3 mb-lg-0">
                <div><input id="startValueUser" autocomplete="off" type="text" v-mask="mask" v-model="item.startValueUser" :disabled="item.notAllowToChangeRD" @change="setUserScale(item)"  class="border-0 w-100 h_30_border_radius"></div>
                <div class="px-1"> - </div>
                <div><input id="finishValueUser" autocomplete="off" type="text" v-mask="mask" v-model="item.finishValueUser" :disabled="item.notAllowToChangeRD" @change="setUserScale(item)"  class="border-0 w-100 h_30_border_radius"></div>
                <div class="px-1">
                  <b-link title="Установить первоначальные значения" @click="resetUserScale(item)"><b-icon  icon="x-circle" variant="success"  size="lg" ></b-icon></b-link>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>



    </div>

    <div class="row mx-0">
      <div class="col-12 col-lg-12 text-lg-center pt-4 pt-lg-0">

        <button class="border-0 save_btn_page_5" type="button" :disabled="saving" @click="onSubmit">
                    <span class="text_save_page_5">Сохранить
                    </span>
          <b-spinner v-if="saving" variant="light" small></b-spinner>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import {currencyMask, host} from "@/constants";
import Treeselect from "@riophae/vue-treeselect";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 10,
  prefix: '',
  suffix: ''
})

export default {
  props: { childParams: Array, entityId: Number, patientId: String},
  name: "ClientReportComplex2ParamForm",
  components: {
    Treeselect
  },
  data() {
    return {
      vMask: valueMask,
      mask: currencyMask,
      //childParams: this.childParamsProp,
      childrenSearchQuery: '',
      selectOptions: [],
      rubricators: [],
      elements:[],
      saving: false,
      normalizeTreeselect(node) {
        return {
          id: node.value.paramId,
          label: node.value.paramName + (node.value.biomaterialTypeName ? ', ' + node.value.biomaterialTypeName : '') + ';_;_; ' + node.value.paramSynonym + ' ' + node.value.searchString
        }
      }
    }
  },
  filters: {
    nodeLabelFilter(value) {
      if (value) {
        return value.substring(0, value.indexOf(';_;_;'))
      }

    },
    searchQueryFilter: function (options, diseases, searchQuery) {

      console.log('filter ' + JSON.stringify(diseases))

      let result = []

      result = options.filter((el, index, []) => {
        return diseases.paramIdFile === el.value.paramId || ((searchQuery) && (el.value.paramName + el.value.biomaterialTypeName + ';_;_; ' + el.value.paramSynonym + ' ' + el.value.searchString).toLowerCase().includes(searchQuery.toLowerCase()) )
      })



      return result
    },
  },
  methods: {
    onSubmit() {
      this.saving = true
      let request = {entityId: this.entityId, probes: []}

      this.elements.forEach(item => {
        request.probes.push({
          name: item.param.name,
          unit: item.param.unit,
          value: item.param.value,
          paramIdFile: item.paramIdFile,
          propertyId: item.propertyValue && item.propertyValue.value ? item.propertyValue.propertyId : null,
          propertyValueId: item.propertyValue && item.propertyValue.value ? item.propertyValue.value : null,
          plusMinus: item.plusMinus,
          titres: item.titres,
          startValueUser: item.isUserScale ? item.startValueUser : null,
          finishValueUser: item.isUserScale ? item.finishValueUser : null,
        })
      })

      fetch(host + '/rest/recognition/' + this.patientId + '/params', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(request),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            console.log('Response ' + JSON.stringify(json))

            this.saving = false
            this.$emit('params-saved')

          }).catch(error => {

        console.log(error)
        this.saving = false

      })

    },
    childrenSearchChange(searchQuery, item) {
      // console.log('searchQuery ' + JSON.stringify(searchQuery))
      // console.log('instanceId ' + JSON.stringify(item))
      item.childrenSearchQuery = searchQuery
    },
    async onParamSelect1(node, item) {
      console.log('node ' + JSON.stringify(node))
      console.log('item ' + JSON.stringify(item))
      item.selected = node.value
      item.paramType = node.value.paramType
      item.paramIdFile = node.value.paramId
      await this.onParamSelect(item)

    },
    async fetchMeasurementOptions(paramId) {
      //console.log('measOptions ')
      let response = await fetch(host + '/rest/dicts/parammeasurementunit/' + paramId, {
        credentials: "include"
      })
      let json = await response.json()
      //console.log('measOptions ' + JSON.stringify(json))
      return json.options
    },
    async onParamSelect(item) {
      //item.param.unit = item.selected.measurementUnitName
      item.measurementOptions = await this.fetchMeasurementOptions(item.selected.paramId)

      let response = await fetch (host + '/rest/params/' + item.selected.paramId + '/properties/values/dict', {
        credentials: "include"
      })
      let json = await response.json()

      item.propertyValueOptions = [ {value: null, text: ''}]
      item.titreOptions = []
      item.plusMinusOptions = []
      item.isInterval = false
      item.isTitres = false
      for (let p of json.options) {
        if (p.text) {
          item.propertyValueOptions.push(
              {value: p, text: p.propertyName + ', ' + p.text }
          )
          // if (this.currentAnalit && this.currentAnalit.propertyValueId === p.value) {
          //   this.propertyValue = p
          // }
        }
        if (p.titres) {
          item.titreOptions.push(
              {value: p.titres, text: p.titres }
          )
        }
        if (p.plusMinus) {
          item.plusMinusOptions.push(
              {value: p.plusMinus, text: p.plusMinus }
          )
        }
        if (p.startValue || p.finishValue) {
          item.isInterval = true
        }
        if (p.titres) {
          item.isTitres = true
        }
      }





      if (item.selected.paramType == 1 || item.selected.paramType == 3) {
        this.onMeasurementUnitSelect(item)
      }

    },
    setUserScale(item) {
      item.isUserScale = true
      this.$forceUpdate()
    },
    resetUserScale(item) {
      item.startValueUser = item.scaleSelect.startValue
      item.finishValueUser = item.scaleSelect.finishValue
      item.isUserScale = false
      this.$forceUpdate()
    },
    plusMinusSelect(newVal, item) {
      //console.log('plus minus Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
      this.$forceUpdate()
    },
    titresSelect(newVal, item) {
      //console.log('titres Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
      this.$forceUpdate()
    },
    propertyValueSelect(newVal, item) {
      //console.log('Prop val Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.titres = null
      item.paramValue = null
      this.$forceUpdate()
    },
    paramValueSelect(newVal, item) {
      //console.log('param val Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      this.$forceUpdate()
    },
    toogleItemOpenChild(item) {
      item.openedChild = item.openedChild ? false : true
      console.log(JSON.stringify(item))
    },
    async onMeasurementUnitSelect(event) {
      await this.measurementUnitSelect(event)
      this.$forceUpdate()
    },
    async measurementUnitSelect(p) {
      //fetch(host + '/rest/params/' + this.selected.paramId + '/patient/' + this.patientId + '/scale/' + this.measurementUnitId, {
      let muId = p.measurementOptions.find(el => (el.text == p.param.unit)).value
      let response = await fetch(host + '/rest/params/' + p.paramIdFile + '/patient/' + this.patientId + '/scale/' + muId, {
        credentials: "include"
      })
      let json = await response.json()

      if (json.resultCode == 0) {
        p.scaleSelect = json.scaleSelect
        p.startValueUser = p.scaleSelect.startValue
        p.finishValueUser = p.scaleSelect.finishValue
        p.isUserScale = false
      } else {
        p.scaleSelect = {}
        p.isUserScale = false
      }

    },
    async fetchDicts(param) {
      if (!param || param.paramType === 4 || param.paramType === 8 || param.paramType === 9) return

      param.propertyValue = ''
      param.titres = ''
      param.plusMinus = ''
      param.paramValue = ''
      param.examinationDate = ''

      let response = await fetch(host + '/rest/dicts/parammeasurementunit/' + param.paramId, {
        credentials: "include"
      })
      let json = await response.json()

      param.measurementOptions = json.options



      response = await fetch (host + '/rest/params/' + param.paramId + '/properties/values/dict', {
        credentials: "include"
      })
      json = await response.json()
      param.propertyValueOptions = []
      param.titreOptions = []
      param.plusMinusOptions = []
      param.isInterval = false
      param.isTitres = false
      for (let p of json.options) {
        if (p.text) {
          param.propertyValueOptions.push(
              {value: p, text: p.propertyName + ', ' + p.text }
          )
        }
        if (p.titres) {
          param.titreOptions.push(
              {value: p.titres, text: p.titres }
          )
        }
        if (p.plusMinus) {
          param.plusMinusOptions.push(
              {value: p.plusMinus, text: p.plusMinus }
          )
        }
        if (p.startValue || p.finishValue) {
          param.isInterval = true
        }
        if (p.titres) {
          param.isTitres = true
        }
      }




    },
    async fetchData() {
      // let response = await fetch(host + '/rest/params/forcomplex/' + this.param.paramId, {
      //   credentials: "include"
      // })
      // let json = await response.json()
      //
      //
      // this.childParams = json.params
      // for(let p of this.childParams) {
      //   p.openedChild = false
      //   await this.fetchDicts(p)
      //   console.log('Param cycle ' + JSON.stringify(p))
      // }

    },
    prepareAnalitsFromResponse(params) {
      this.selectOptions = []
      //this.params = params
      let selectParent = false
      for (let p of params) {

        let option = {
          value: p,
          text: p.paramName
        }
        this.selectOptions.push(option)
        // if (this.currentAnalit && this.currentAnalit.parentParamId == p.paramId && this.currentAnalit.onlyOneSelect) {
        //   this.selected = p
        //   this.selected.onlyOneSelect = this.currentAnalit.onlyOneSelect
        //   selectParent = true
        //   this.onParamSelect()
        // } else if (this.currentAnalit && !selectParent && this.currentAnalit.paramId == p.paramId) {
        //   this.selected = p
        //   this.onParamSelect()
        // }
      }
      //console.log('fetching end')
      // if (this.currentAnalit !== null) {
      //   //console.log('Current analit is ' + JSON.stringify(this.currentAnalit))
      //   this.measurementUnitId = this.currentAnalit.measurementUnitId
      //
      // }
      this.$forceUpdate()
    },
    trySelectParam(param) {
      let resArr = this.selectOptions.filter(el => {
        return (el.text.indexOf(param.name) >= 0 || param.name.indexOf(el.text) >= 0) && el.value.paramType != 4 && el.value.paramType != 5 && el.value.paramType != 6 && el.value.paramType != 8 && el.value.paramType != 9
      })
      //console.log('resArr ' + JSON.stringify(resArr))
      for (let res of resArr) {
        if (res.value.biomaterialType == 7) {
          //console.log('return ' + res.value.paramId)
          return res.value
        }
      }
      if (resArr.length >0) {
        //console.log('return ' + resArr[0].value.paramId)
        return resArr[0].value
      }
      return null
    }
  },
  async mounted() {


    //await this.fetchData()
      //console.log('date ' + new Date().getFullYear() + '-' + (new Date().getMonth() < 9 ? '0' + (new Date().getMonth()+1) : (new Date().getMonth()+1)) + '-' + new Date().getDate())

    let response = await fetch(host + '/rest/params', {
        credentials: "include"
      })
    let json = await response.json()
    this.prepareAnalitsFromResponse(json.params)

    if (this.childParams) {
      for( let el of this.childParams) {
        let val = this.trySelectParam(el)
        let opts = []
        if (val) {
          opts = await this.fetchMeasurementOptions(val.paramId)


        }
        let element = {
          param: el,
          paramType: val ? val.paramType : 1,
          paramIdFile: val ? val.paramId : null,
          selected: val,
          childrenSearchQuery: '',
          measurementOptions: opts,
          propertyValueOptions: [ {value: null, text: ''}],
          titreOptions: [],
          plusMinusOptions: [],
          isInterval: false,
          isTitres: false,
          propertyValue: null,
          titres: null,
          plusMinus: null
        }
        if (val) {


          if (val.paramType == 1 || val.paramType == 3) {
            this.measurementUnitSelect(element)
          }
        }

        //console.log('opts ' + JSON.stringify(opts))
        this.elements.push(element )
      }
      this.$forceUpdate()
    }


      // fetch(host + '/rest/rubricators/forDoctor', {
      //   credentials: "include"
      // })
      //     .then(response => response.json())
      //     .then(json => {
      //       this.rubricators = json.options
      //       for (let p of this.rubricators) {
      //         p.options = []
      //         p.selectedValue = null
      //         fetch(host + '/rest/rubricators/' + p.value, {
      //           credentials: "include"
      //         })
      //             .then(response => response.json())
      //             .then(json => {
      //               p.options = json.options
      //               p.options.splice(0,0,{value: null, text: ''})
      //               this.$forceUpdate()
      //             })
      //       }
      //       console.log('fetching end')
      //       if (this.currentAnalit !== null) {
      //         console.log('Current analit is ' + JSON.stringify(this.currentAnalit))
      //         this.measurementUnitId = this.currentAnalit.measurementUnitId
      //
      //       }
      //     })


  }
}
</script>

<style scoped>

.padding100 {
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 10px;
}

</style>