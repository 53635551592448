<template>
  <div>

    <div class="row mx-0 analit-simple"  v-for="(item, i) in childParams" :key="item.paramId">

      <div :class="(item.paramType === 4 || item.paramType === 5 || item.paramType === 6 || item.paramType === 8 || item.paramType === 9)? ' analit-complex col-lg-12 col-sm-12 d-flex py-3 just_border' : ' analit-simple col-lg-3 col-sm-5 d-flex py-3 just_border'">
        <b-link class="attribute_left_page_7" v-if="(item.paramType === 4 || item.paramType === 5 || item.paramType === 6 || item.paramType === 8 || item.paramType === 9)" @click="toogleItemOpenChild(item)">
          <img style="vertical-align: middle;" v-if="isShowButton(item, i)" src="@/assets/images/patients/icon_delete.png">
          <img style="vertical-align: middle;" v-else src="@/assets/images/patients/icon_open.png">
          {{ item.paramName }}, {{ item.biomaterialTypeName }}
        </b-link>
        <span v-else class="attribute_left_page_7">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </div>

      <div class="col-lg-12 col-sm-12 container-fluid" v-if="isShowButton(item, i)">
        <ClientReportComplexParamForm v-bind:param="item" v-bind:patient-id="patientId" v-if="(item.paramType == 4 || item.paramType === 5 || item.paramType === 6 || item.paramType == 8 || item.paramType == 9)" ref="clientComplexReport"></ClientReportComplexParamForm>
      </div>

      <div :class="(item.paramType == 1 || item.paramType === 3  || item.paramType===10) ? 'analit-simple col-lg-6 col-sm-7 row_padding just_border': 'analit-simple col-lg-9 col-sm-7 row_padding just_border'" v-if="!(item.paramType == 4 || item.paramType === 5 || item.paramType === 6 || item.paramType == 8 || item.paramType == 9)">
        <div class="row text-center mb-3 mb-lg-0 h-100"  >
          <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 1 || item.paramType === 3 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
            <div class="px-0"><span class="attribute_center_page_7">Значение</span></div>
            <div class="px-0 row_margin">
              <b-input-group class="border-0 w-100 row_padding "  >
                <div v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)" class="border-0 analit-input-prepend-titr" >1:</div>
                <!--                    <b-form-input v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="paramValue" autocomplete="off" @change="paramValueSelect"></b-form-input>-->
                <input :class="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0) ? 'border-0 w-70 analit-input-titr text-left' : 'border-0 w-100 analit-input'" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)">
              </b-input-group>


<!--              <b-input-group prepend="1:" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)" class="border-0 w-100 h_30_border_radius">-->
<!--                <b-form-input v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)"></b-form-input>-->
<!--              </b-input-group>-->
<!--              <input v-else class="border-0 w-100 analit-input" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="item.paramValue" autocomplete="off" @change="paramValueSelect($event, item)">-->
<!--              <input type="text" >-->

            </div>
          </div>
          <div  class="col-md-6 col-6 px-0" v-if="(item.paramType === 1 || item.paramType === 3 || (item.paramType === 7 && (item.isTitres || item.isInterval)))">
            <div class="px-0"><span class="attribute_center_page_7">Единицы измерения</span></div>
            <div class="px-0 row_margin">
<!--              <b-form-select id="measure" v-model="item.measurementUnitId" :options="item.measurementOptions" :select-size="1" size="1"-->
<!--                             class="custom-select border-0 pt-0 w-75 inside_the_field h_30"></b-form-select>-->

              <select v-model="item.measurementUnitId" @change="onMeasurementUnitSelect(item)" class="custom-select border-0 analit-input w-100 h_30">
                <option v-for="option in item.measurementOptions" v-bind:value="option.value">
                  {{ option.text }}
                </option>
              </select>

            </div>
          </div>

          <div class="col-md-12 col-12 px-0" v-if="(item.paramType === 2 || (item.paramType === 7 && item.propertyValueOptions && item.propertyValueOptions.length > 0))">
            <div class="px-0">
<!--              <span class="attribute_center_page_7">Выбор значения</span>-->
            </div>
            <div class="px-0 row_margin py-3">
              <b-form-select id="propertyValue" v-model="item.propertyValue" :options="item.propertyValueOptions" :select-size="1" @change="propertyValueSelect($event, item)"
                             class="custom-select border-0 w-100 analit-input h_30"></b-form-select>
            </div>
          </div>

          <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.titreOptions && item.titreOptions.length > 0)">
            <div class="px-0"><span class="attribute_center_page_7">Титры</span></div>
            <div class="px-0 row_margin">
              <b-form-select id="titres" v-model="item.titres" :options="item.titreOptions" :select-size="1" @change="titresSelect($event, item)"
                             class="custom-select border-0 w-100 analit-input h_30"></b-form-select>
            </div>
          </div>

          <div class="col-md-6 col-6 px-0" v-if="(item.paramType === 7 && item.plusMinusOptions && item.plusMinusOptions.length > 0)">
            <div class="px-0"><span class="attribute_center_page_7">Кресты</span></div>
            <div class="px-0 row_margin">
              <b-form-select id="measure" v-model="item.plusMinus" :options="item.plusMinusOptions" :select-size="1" @change="plusMinusSelect($event, item)"
                             class="custom-select border-0 w-100 analit-input h_30"></b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.paramType == 1 || item.paramType === 3" class="analit-simple col-lg-3 col-sm-12 text-center just_border" >
        <div class="row h-100">
          <div class="col-12 ">
            <div>
									<span class="attribute_center_page_7">Референтный диапазон
									</span>
            </div>
            <div  class="d-flex justify-content-center mb-3 mb-lg-0">
              <div><input id="startValueUser" autocomplete="off" type="text" v-mask="mask" v-model="item.startValueUser"  :disabled="item.notAllowToChangeRD"  @change="setUserScale(item)"  class="border-0 w-100 analit-input"></div>
              <div class="px-1"> - </div>
              <div><input id="finishValueUser" type="text" v-mask="mask" autocomplete="off" v-model="item.finishValueUser" :disabled="item.notAllowToChangeRD" @change="setUserScale(item)"  class="border-0 w-100 analit-input"></div>
              <div class="px-1">
                <b-link title="Установить первоначальные значения" @click="resetUserScale(item)"><b-icon  icon="x-circle" variant="success"  size="lg" ></b-icon></b-link>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>



  </div>
</template>

<script>
import {host, currencyMask} from "@/constants";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 10,
  prefix: '',
  suffix: ''
})

export default {
  props: { param: Object, patientId: String, currentAnalit: Object },
  name: "ClientReportComplexParamForm",
  data() {
    return {
      mask: currencyMask,
      childParams:[],
      vMask: valueMask,
      //openedChildren: []
    }
  },
  watch: {
    param: function(newVal, oldVal) { // watch it
      console.log('watch call')
      this.fetchData()
    }
  },
  methods: {

    isComplexParam(paramType) {
      return (paramType === 4 || paramType === 5 || paramType === 6 || paramType === 8 || paramType === 9)
    },
    isShowButton(item, i) {
      return (this.isComplexParam(item.paramType) && item.openedChild)
    },
    checkOnlyOneSelect(item) {
      if (this.param.onlyOneSelect) {
        this.childParams.forEach((el) => {
          if (el.paramId != item.paramId) {
            el.titres = null
            el.propertyValue = null
            el.paramValue = null
            el.plusMinus = null
          }
        })
      }
    },
    plusMinusSelect(newVal, item) {
      //console.log('plus minus Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
      this.$forceUpdate()
    },
    titresSelect(newVal, item) {
      //console.log('titres Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      item.paramValue = null
      this.$forceUpdate()
    },
    propertyValueSelect(newVal, item) {
      //console.log('Prop val Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.titres = null
      item.paramValue = null
      this.$forceUpdate()
    },
    paramValueSelect(newVal, item) {
      //console.log('param val Item is ' + JSON.stringify(item))
      this.checkOnlyOneSelect(item)
      item.plusMinus = null
      item.titres = null
      item.propertyValue = {value:null, propertyId: item.propertyValue.propertyId}
      this.$forceUpdate()
    },
    toogleItemOpenChild(item) {
      item.openedChild = item.openedChild ? false : true
      this.$forceUpdate()
    },
    setUserScale(item) {
      item.isUserScale = true
      this.$forceUpdate()
    },
    resetUserScale(item) {
      item.startValueUser = item.scaleSelect.startValue
      item.finishValueUser = item.scaleSelect.finishValue
      item.isUserScale = false
      this.$forceUpdate()
    },
    async fetchDicts(param) {
      console.log('fetchDicts')
      if (!param || param.paramType === 4 || param.paramType === 8 || param.paramType === 9) return

      //param.propertyValue = {}
      // param.titres = ''
      // param.plusMinus = ''
      // param.paramValue = ''
      //param.examinationDate = ''

      let response = await fetch(host + '/rest/dicts/parammeasurementunit/' + param.paramId, {
        credentials: "include"
      })
      let json = await response.json()

            param.measurementOptions = json.options



      response = await fetch (host + '/rest/params/' + param.paramId + '/properties/values/dict', {
        credentials: "include"
      })
      json = await response.json()
      param.propertyValueOptions = [{value: null, text: ''}]
      param.titreOptions = []
      param.plusMinusOptions = []
      param.isInterval = false
      param.isTitres = false
      for (let p of json.options) {
        if (p.text) {
          param.propertyValueOptions.push(
              {value: p, text: p.propertyName + ', ' + p.text }
          )
          if (this.currentAnalit && this.currentAnalit.propertyValueId === p.value) {

            param.propertyValue = p
            console.log('Тестовый лог проперти' + JSON.stringify(param.propertyValue))
          }
        }
        if (p.titres) {
          param.titreOptions.push(
              {value: p.titres, text: p.titres }
          )
        }
        if (p.plusMinus) {
          param.plusMinusOptions.push(
              {value: p.plusMinus, text: p.plusMinus }
          )
        }
        if (p.startValue || p.finishValue) {
          param.isInterval = true
        }
        if (p.titres) {
          param.isTitres = true
        }
      }
      //console.log('Тестовый лог внутри' + JSON.stringify(param.propertyValue))
    },
    async fetchData() {
      let response = await fetch(host + '/rest/params/forcomplex/' + this.param.paramId, {
        credentials: "include"
      })
      let json = await response.json()

      this.childParams = json.params
      this.childParams.forEach(el => {el.propertyValue = {}})
      for(let p of this.childParams) {
        p.openedChild = false
        p.isUserScale = false
        if (p.paramType == 1 || p.paramType == 3) {
          this.measurementUnitSelect(p)
        }
        if (this.currentAnalit && p.paramId == this.currentAnalit.paramId) {
          p.titres = this.currentAnalit.titres
          // if (this.currentAnalit && this.currentAnalit.propertyValueId === p.value) {
          //   this.propertyValue = p
          // }
          //p.propertyValue = {}
          p.paramValue = this.currentAnalit.paramValue
          p.plusMinus = this.currentAnalit.plusMinus
        }
        await this.fetchDicts(p)
        //console.log('Тестовый лог снаружи' + JSON.stringify(p.propertyValue))
      }
    },
    async onMeasurementUnitSelect(event) {
      await this.measurementUnitSelect(event)
      this.$forceUpdate()
    },
    async measurementUnitSelect(p) {
      //fetch(host + '/rest/params/' + this.selected.paramId + '/patient/' + this.patientId + '/scale/' + this.measurementUnitId, {
      let response = await fetch(host + '/rest/params/' + p.paramId + '/patient/' + this.patientId + '/scale/' + p.measurementUnitId, {
        credentials: "include"
      })
      let json = await response.json()

      if (json.resultCode == 0) {
        p.scaleSelect = json.scaleSelect
        p.startValueUser = p.scaleSelect.startValue
        p.finishValueUser = p.scaleSelect.finishValue
        p.isUserScale = false
      } else {
        p.scaleSelect = {}
        p.isUserScale = false
      }

    },
    async onSubmit(examinationDate) {

      if (this.currentAnalit) {
        fetch(host + '/rest/params/clientreport/' + this.currentAnalit.paramClientId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                console.log('Delete Success ' + json)
              } else {
                console.log('Delete Failed ' + json)
              }
            }).catch(error => {
              console.log(JSON.stringify(error))
            })
      }

      for (let [index, item] of this.childParams.entries()) {
        if (item.paramType == 4 || item.paramType == 8 || item.paramType == 9) {

        } else if ((item.propertyValue && item.propertyValue.value) || item.paramValue || item.plusMinus || item.titres) {
          let rq = null
          let method = 'POST'

          rq = {
            clientId: this.patientId,
            param: {
              paramId: item.paramId,
              paramValue: item.paramValue,
              measurementUnitId: item.measurementUnitId,
              examinationDate: examinationDate,
              propertyId: item.propertyValue && item.propertyValue.value ? item.propertyValue.propertyId : null,
              propertyValueId: item.propertyValue && item.propertyValue.value ? item.propertyValue.value : null,
              plusMinus: item.plusMinus,
              titres: item.titres,
              startValueUser: item.isUserScale ? item.startValueUser : null,
              finishValueUser: item.isUserScale ? item.finishValueUser : null,
              parentParamId: this.param.paramId,
              onlyOneSelect: this.param.onlyOneSelect

            }
          }

          try {
            console.log(JSON.stringify(rq))
            let response = await fetch(host + '/rest/params/clientreport', {
              method: method,
              credentials: 'include',
              body: JSON.stringify(rq),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            })
            let json = await response.json()

            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
            } else {
              console.log('Login Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }

          } catch (error) {
            console.log(error)
          }

        }

      }

      if (this.$refs.clientComplexReport && this.$refs.clientComplexReport.length > 0) {
        for (let ref of this.$refs.clientComplexReport) {
          await ref.onSubmit(examinationDate)
        }
      }
    }
  },
  async mounted() {
    console.log('Mounted started' + this.patientId)
    await this.fetchData()

    this.$forceUpdate()
  }
}
</script>

<style scoped>

.topBorderClass {
  border-top: black solid 2px;
}
.paramNameComplex {
  font-weight: bold;
  color: rgb(23, 162, 184);
}
.paramName {
  font-weight: bold;
}

</style>