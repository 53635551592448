<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <b-button variant="info" @click="editFormula()">Создать формулу</b-button>
        <br /><br />
        <div v-if="error" class="alert alert-danger">
          {{error}}
        </div>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon variant="info" icon="search" size="lg"></b-icon>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="searchRequest" autocomplete="off"></b-form-input>
        </b-input-group>
        <b-table striped hover :items="items | filterItems(searchRequest)" :fields="fields" ref="measurementUnitTable">
          <!-- A custom formatted column -->
          <template v-slot:cell(value)="data">
            <b>{{ data.item.value }}</b>
          </template>
          <template v-slot:cell(text)="data">
            <span v-html="data.item.text"></span>
          </template>
          <template v-slot:cell(buttons)="data">
            <h3>
              <nobr>
                <b-link @click="editFormula(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                &nbsp;
                <b-link @click="deleteFormula(data.item.formulaId, data.item.formulaName)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>
        </b-table>
        <b-modal id="formulaModal" hide-footer size="lg" title="Формула">
          <FormulaEdit @save-success="afterSave" v-bind:error="error" v-bind:current-measurement-unit="currentMeasurementUnit"></FormulaEdit>
        </b-modal>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import FormulaEdit from '@/components/admin/rules/FormulaEdit'
import Loader from '@/components/Loader'
import {host} from '@/constants'
export default {

  name: "FormulaList",
  components: {
    Loader, FormulaEdit
  },
  filters: {
    filterItems: function (items, searchRequest) {
      if (searchRequest !== null && searchRequest !== "" && searchRequest.length > 2 ) {
        return items.filter((el) => ( ( el.formulaName && (el.formulaName.toLowerCase()).includes(searchRequest.toLowerCase()) )
        ) )
      }
      return items
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'formulaId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'formulaName',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'modifyDate',
          label: 'Дата изменения',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentMeasurementUnit: null,
      searchRequest: null
    }
  },
  methods: {
    deleteFormula(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить единицу формулу "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/warehouse/formulas/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.formulaId !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
              this.loading = false
            })
          }
    },
    editFormula(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('formulaModal')
    },
    fetchData() {
      fetch(host + '/rest/warehouse/formulas', {
            credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.formulaOptions
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('formulaModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>