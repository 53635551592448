<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="text" label-for="text" label="Название">
        <b-form-input id="text" type="text" required placeholder="Введите значение" v-model="mu.text" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="diseaseCode" label-for="diseaseCode" label="Код">
        <b-form-input id="diseaseCode" type="text" required placeholder="Введите значение" size="16" v-model="mu.diseaseCode" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="diseaseType" label-for="diseaseType" label="Тип" v-if="currentRubricator.value != 'Drug'">
        <b-form-select id="diseaseType" v-model="mu.diseaseType" :options="diseaseTypeOptions"
                       :select-size="1"></b-form-select>
      </b-form-group>

<!--      <b-form-group id="text" label-for="text" label="Синонимы" v-if="currentRubricator.value == 'Drug'">-->
<!--        <b-form-input id="text" type="text" placeholder="Введите значение" v-model="mu.drugSynonym" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->

      <b-row class="newAttr" v-if="currentRubricator.value === 'Drug'">
        <b-col>
          <label>Фармакологическая группа</label>
          <br>
          <treeselect name="drugTypes" id="drugTypes" placeholder=""
                      multiple clearable searchable
                      open-on-click disableFuzzyMatching
                      :noResultsText="'Не найдено...'"
                      :options="drugTypeOptions"
                      :max-height="200"
                      :normalizer="normalizeTreeselect"
                      v-model="diseaseTypes"
                      @input="selectedDrugTypesChanged"
                      />
        </b-col>
      </b-row>

      <b-row class="newAttr">
        <b-col>
          <label for="startYear"><b>Возраст (лет):</b> От</label>
        </b-col>
        <b-col>
          <label for="finishYear">До</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-input v-mask="yMask" id="startYear" type="text" size="4"
                        v-model="mu.startYear" autocomplete="off"></b-form-input>
        </b-col>
        <b-col>
          <b-form-input v-mask="yMask" id="finishYear" type="text" size="4"
                        v-model="mu.finishYear" autocomplete="off"></b-form-input>
        </b-col>
      </b-row>


      <b-form-group id="ig2" label-for="paramValue" label="Пол">
        <b-form-select id="sexId" v-model="mu.genderCode" :options="genderOptions" required
                       :select-size="1"></b-form-select>
      </b-form-group>

      <b-form-group id="ig2" label-for="paramValue" label="Фаза цикла" v-if="mu.genderCode == 2" :disabled="mu.postmenopause || mu.postpartumWoman || mu.pregnant" >
        <b-form-select id="menstrualCyclePhaseId" :disabled="mu.postmenopause || mu.postpartumWoman || mu.pregnant" v-model="mu.menstrualCyclePhaseId" :options="menstrualCyclePhaseOptions"
                       :select-size="1"></b-form-select>
      </b-form-group>


      <b-row class="newAttr" v-if="mu.genderCode == 2">
        <b-col><b-form-checkbox v-model="mu.postmenopause" name="postmenopause" @change="togglePostmenopause" switch>Постменопауза</b-form-checkbox></b-col>
        <b-col><b-form-checkbox v-model="mu.postpartumWoman" name="postpartumWoman" @change="togglePostpartumWoman" switch>Родильница</b-form-checkbox></b-col>
        <b-col><b-form-checkbox v-model="mu.pregnant" name="pregnant" @change="togglePregnant" switch>Беременность</b-form-checkbox></b-col>
      </b-row>

      <b-row class="newAttr" v-if="mu.genderCode === 2 && mu.pregnant">
        <b-col>
          <label for="startPregnancyWeek"><b>Неделя беременности:</b> От</label>
        </b-col>
        <b-col>
          <label for="finishPregnancyWeek">До</label>
        </b-col>
      </b-row>
      <b-row v-if="mu.genderCode === 2 && mu.pregnant">
        <b-col>
          <b-form-input v-mask="yMask" id="startPregnancyWeek" type="text" required size="4"
                        v-model="mu.startPregnancyWeek" autocomplete="off"></b-form-input>
        </b-col>
        <b-col>
          <b-form-input v-mask="yMask" id="finishPregnancyWeek" type="text" required size="4"
                        v-model="mu.finishPregnancyWeek" autocomplete="off"></b-form-input>
        </b-col>
      </b-row>

      <b-form-group id="diseaseCommentLabel" label-for="diseaseComment" label="Комментарий для графического отчета" v-if="currentRubricator.value == 'Disease'">
        <b-textarea rows="5" max-rows="7" id="diseaseComment" v-model="mu.diseaseComment"></b-textarea>
      </b-form-group>

<!--      <b-row class="newAttr">-->
<!--        <b-col>-->
<!--          <label v-if="currentRubricator.value === 'Disease'">Родительские патологии</label>-->
<!--          <label v-else-if="currentRubricator.value === 'RiskFactor'">Родительские факторы внешней среды и особые состояния организма</label>-->
<!--          <label v-else-if="currentRubricator.value === 'LaboratoryError'">Родительские лабораторные ошибки</label>-->
<!--          <label v-else>Родительские лекарственные препараты</label>-->
<!--          <br>-->
<!--          <treeselect name="parentDiseases" id="parentDiseases" placeholder=""-->
<!--                      multiple clearable searchable-->
<!--                      open-on-click disableFuzzyMatching-->
<!--                      :noResultsText="'Не найдено...'"-->
<!--                      :options="parentDiseaseOptions | searchQueryFilter(parentDiseases, parentSearchQuery)"-->
<!--                      :max-height="200"-->
<!--                      :normalizer="normalizeTreeselect"-->
<!--                      v-model="parentDiseases"-->
<!--                      @search-change="parentSearchChange"/>-->
<!--        </b-col>-->
<!--      </b-row>-->

<!--      <b-row class="newAttr">-->
<!--        <b-col>-->
<!--          <label v-if="currentRubricator.value === 'Disease'">Дочерние патологии</label>-->
<!--          <label v-else-if="currentRubricator.value === 'RiskFactor'">Дочерние факторы внешней среды и особые состояния организма</label>-->
<!--          <label v-else-if="currentRubricator.value === 'LaboratoryError'">Дочерние лабораторные ошибки</label>-->
<!--          <label v-else>Дочерние лекарственные препараты</label>-->
<!--          <br>-->
<!--          <treeselect name="childrenDiseases" id="childrenDiseases" placeholder=""-->
<!--                      multiple clearable searchable-->
<!--                      open-on-click disableFuzzyMatching-->
<!--                      :noResultsText="'Не найдено...'"-->
<!--                      :options="parentDiseaseOptions | searchQueryFilter(childrenDiseases, childrenSearchQuery)"-->
<!--                      :max-height="200"-->
<!--                      :normalizer="normalizeTreeselect"-->
<!--                      v-model="childrenDiseases"-->
<!--                      @search-change="childrenSearchChange"/>-->
<!--        </b-col>-->
<!--      </b-row>-->

      <b-row class="newAttr">
        <b-col>
          <div class="accordion" role="tablist">
            <b-card v-if="currentRubricator.value == 'Disease' && currentRubricatorValue" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info">Родительские и дочерние патологии</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <DiseaseParentChildList v-bind:current-rubricator="currentRubricator" v-bind:current-rubricator-value="currentRubricatorValue" ></DiseaseParentChildList>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card v-if="currentRubricator.value == 'Drug' && currentRubricatorValue" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info">Синонимы</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <DrugSynonyms @save-success="afterSave" v-bind:drug-id="currentRubricatorValue.value" ></DrugSynonyms>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="info">Аналиты</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-table striped hover :items="factorBands" :fields="fields">
                    <template v-slot:cell(param)="data">
                      <router-link :to="'/analitedit/' + data.item.paramId">{{ data.item.paramName }}, {{ data.item.biomaterialTypeName }}</router-link>
                    </template>
                    <template v-slot:cell(property)="data">
                      {{ ((data.item.propertyName ? data.item.propertyName :'')  + (data.item.propertyValueName? (', ' + data.item.propertyValueName) : '' )) }}
                    </template>

                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>


          </div>
        </b-col>


      </b-row>


      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host, yearMask} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DrugSynonyms from "@/components/admin/factors/DrugSynonyms";
import DiseaseParentChildList from "./DiseaseParentChildList";

export default {
  name: "DiseaseValueEdit",
  props: {
    currentRubricator: Object,
    currentRubricatorValue: Object
  },
  components: {
    DiseaseParentChildList,
    DrugSynonyms,
    Treeselect
  },
  data() {
    return {
      error: null,
      diseaseTypeOptions: [],
      parentDiseaseOptions: [],
      parentDiseases: [],
      childrenDiseases: [],
      parentSearchQuery: '',
      childrenSearchQuery: '',
      drugTypeOptions: [],
      isDrugTypesChanged: false,
      fields: [
        {
          key: 'param',
          label: 'Аналит'
        },
        {
          key: 'sexName',
          label: 'Пол'
        },
        {
          key: 'zoneName',
          label: 'Интервал',
          sortable: false
        },
        {
          key: 'property',
          label: 'Вариант',
          sortable: false
        },
        {
          key: 'priorityReport',
          label: 'Приоритет',
          sortable: true
        }
      ],
      yMask: yearMask,
      menstrualCyclePhaseOptions: [{value: null, text: ''}],
      genderOptions: [{value: 0, text: 'мужской и женский'}, {value: 1, text: 'мужской'}, {value: 2, text: 'женский'}],
      factorBands: [],
      diseaseTypes:[],
      mu: this.currentRubricatorValue !== null ? {
        value: this.currentRubricatorValue.value,
        text: this.currentRubricatorValue.text,
        diseaseCode: this.currentRubricatorValue.diseaseCode,
        genderCode: this.currentRubricatorValue.genderCode,
        gender: this.currentRubricatorValue.gender,
        startYear: this.currentRubricatorValue.startYear,
        finishYear: this.currentRubricatorValue.finishYear,
        menstrualCyclePhaseId: this.currentRubricatorValue.menstrualCyclePhaseId,
        menstrualCyclePhaseName: this.currentRubricatorValue.menstrualCyclePhaseName,
        postpartumWoman: this.currentRubricatorValue.postpartumWoman,
        postmenopause: this.currentRubricatorValue.postmenopause,
        pregnant: this.currentRubricatorValue.pregnant,
        startPregnancyWeek: this.currentRubricatorValue.startPregnancyWeek,
        finishPregnancyWeek: this.currentRubricatorValue.finishPregnancyWeek,
        diseaseType: this.currentRubricatorValue.diseaseType,
        diseaseTypeName: this.currentRubricatorValue.diseaseTypeName,
        drugSynonym: this.currentRubricatorValue.drugSynonym,
        diseaseComment: this.currentRubricatorValue.diseaseComment
      } : {
        value: null,
        text: null,
        diseaseCode: null,
        genderCode: null,
        gender: null,
        startYear: null,
        finishYear: null,
        menstrualCyclePhaseId: null,
        menstrualCyclePhaseName: null,
        postpartumWoman: null,
        postmenopause: null,
        pregnant: null,
        startPregnancyWeek: null,
        finishPregnancyWeek: null,
        diseaseType: null,
        diseaseTypeName: null,
        drugSynonym: null,
        diseaseComment: null,
      },
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  filters: {
    searchQueryFilter: function (options, diseases, searchQuery) {
      console.log('selected = ' + diseases)
      console.log('searchQuery = ' + searchQuery)
      let result = options.filter((el, index, []) => { return diseases.indexOf(el.value)>=0 || (el.text.includes(searchQuery) && (searchQuery || index < 50)) })
      return result
    },

  },
  methods: {
    afterSave() {
      this.$emit('synonym-added')
    },
    selectedDrugTypesChanged(value, instanceId) {
      this.isDrugTypesChanged = true
    },
    parentSearchChange(searchQuery, instanceId) {
      this.parentSearchQuery = searchQuery
    },
    childrenSearchChange(searchQuery, instanceId) {
      this.childrenSearchQuery = searchQuery
    },
    togglePostmenopause(checked) {
      if (checked) {
        this.mu.pregnant = false
        this.mu.postpartumWoman = false
        this.mu.menstrualCyclePhaseId = null
      }
    },
    togglePostpartumWoman(checked) {
      if (checked) {
        this.mu.pregnant = false
        this.mu.postmenopause = false
        this.mu.menstrualCyclePhaseId = null
      }
    },
    togglePregnant(checked) {
      if (checked) {
        this.mu.postpartumWoman = false
        this.mu.postmenopause = false
        this.mu.menstrualCyclePhaseId = null
      }
    },
    async onSubmit() {
      let method = 'POST'
      if (this.currentRubricatorValue !== null) {
        method = 'PUT'
      }

      console.log(JSON.stringify(this.mu))
      try {
        let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value, {
          method: method,
          credentials: 'include',
          body: JSON.stringify(this.mu),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode == 0) {
          console.log('Save Success ' + JSON.stringify(json))
          if (method === 'POST') {
            this.mu.value = json.entityId
          }
          // await this.updateChildren(this.mu.value)
          // await this.updateParents(this.mu.value)

          if (this.isDrugTypesChanged) {
            await this.updateDrugTypes(this.mu.value)
          }

          this.$emit('save-success')
        } else {
          console.log('Save Failed ' + json)
          this.error = json.resultCode + ' ' + json.message;
        }

      } catch(error ) {
        this.error = error
        console.log(JSON.stringify(error))
      }

    },
    async updateDrugTypes(diseaseId) {
      let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + diseaseId + '/types', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({entityTypes : this.diseaseTypes}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await response.json()

      if (json.resultCode != 0) {

        console.log('update DrugTypes Save Failed ' + json)
        this.error = json.resultCode + ' ' + json.message;
        throw this.error
      }
    },
    // async updateChildren(diseaseId) {
    //   let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + diseaseId + '/children', {
    //     method: 'PUT',
    //     credentials: 'include',
    //     body: JSON.stringify({children : this.childrenDiseases}),
    //     headers: {'Content-Type': 'application/json; charset=utf-8'}
    //   })
    //   let json = await response.json()
    //
    //   if (json.resultCode != 0) {
    //
    //     console.log('updateChildren Save Failed ' + json)
    //     this.error = json.resultCode + ' ' + json.message;
    //     throw this.error
    //   }
    // },
    // async updateParents(diseaseId) {
    //   let response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + diseaseId + '/parents', {
    //     method: 'PUT',
    //     credentials: 'include',
    //     body: JSON.stringify({children : this.parentDiseases}),
    //     headers: {'Content-Type': 'application/json; charset=utf-8'}
    //   })
    //   let json = await response.json()
    //
    //   if (json.resultCode != 0) {
    //
    //     console.log('updateParents Save Failed ' + json)
    //     this.error = json.resultCode + ' ' + json.message;
    //     throw this.error
    //   }
    // }


  },
  async mounted() {
    let dictType = 'diseasetype'
    if (this.currentRubricator.value === 'Disease') {
      dictType = 'diseasetype'
    } else if (this.currentRubricator.value === 'RiskFactor') {
      dictType = 'riskfactortype'
    } else if (this.currentRubricator.value === 'LaboratoryError') {
      dictType = 'laboratoryerrortype'
    }
    fetch(host + '/rest/dicts/' + dictType, {
      credentials: 'include'
    })
        .then(response => response.json())
        .then(json => {
          this.diseaseTypeOptions = json.options
        })

    if (this.currentRubricator.value === 'Drug') {
      fetch(host + '/rest/dicts/drugtype', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.drugTypeOptions = json.options
          })
    }

    let response = await fetch(host + '/rest/dicts/menstrualcyclephase')
    let json = await response.json()
    this.menstrualCyclePhaseOptions = this.menstrualCyclePhaseOptions.concat(json.options)

    response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.mu.value + '/params', {
      credentials: "include"
    })
    json = await response.json()
    this.factorBands = json.factorBands

    // response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.mu.value + '/parents', {
    //   credentials: "include"
    // })
    // json = await response.json()
    // this.parentDiseases = json.children
    //
    // response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.mu.value + '/children', {
    //   credentials: "include"
    // })
    // json = await response.json()
    // this.childrenDiseases = json.children

    // response = await fetch(host + '/rest/factors/' + this.currentRubricator.value, {
    //   credentials: 'include'
    // })
    // json = await response.json()
    // this.parentDiseaseOptions = json.options

    if (this.currentRubricator.value === 'Drug' && this.currentRubricatorValue) {
      response = await fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + this.mu.value + '/types', {
        credentials: "include"
      })
      json = await response.json()
      this.diseaseTypes = json.entityTypes
    }

  }
}
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}

</style>
