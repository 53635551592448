<template>
  <div class="container-fluid">
    <template v-if="permission.questionaryBlock">
      <b-row>
        <div><span class="patients m-auto pl-2"  style="color: #606D6D!important;">Доступ запрещён</span></div>
      </b-row>
    </template>
    <template v-else>
    <div v-if="this.role === 1" class="row mx-0">
      <div class="col-12">
        <b-button @click="finishQuestionary()" variant="info">Отправить анкету врачу</b-button>
      </div>
    </div>
    <div v-if="this.role === 1" class="row mx-0">
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div  class="row mx-0">

      <div role="tablist" class="container-fluid padding_0">
        <b-card v-if="permission.questionaryTabGeneralEnable" no-body class="mb-1">
          <b-card-header header-tag="div" v-b-toggle.antropo class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block  class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Общая часть</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="antropo" accordion="my-accordion" role="tabpanel">
            <b-card-body body-tag="div" class="background_E9F5EA_page_7">
              <b-form @submit.prevent="onAntropoSubmit">
                <div class="d-flex mx-0 py-3 row justify-content-around">
                  <div class="d-flex py-2 py-xl-0">
                    <div class="m-auto">
													<span class="pr-3 span_page_8">
														Вес, кг
													</span>
                    </div>
                    <div class="m-auto">
                      <b-form-input type="number" max="400" v-model="dynamicInfo.weight"
                                    @change="onAntropoSubmit" @blur="calcBodyMassIndex()"
                                    autocomplete="off" class=" border_radius border-0 text-center  input_znachenie w_105"
                                    :state="!dynamicInfo.weight || (dynamicInfo.weight>0 && dynamicInfo.weight < 401)"
                      >
                      </b-form-input>
                    </div>
                  </div>
                  <div class="d-flex py-2 py-xl-0">
                    <div class="m-auto">
													<span class="pr-3 span_page_8">
														Рост, см
													</span>
                    </div>
                    <div class="m-auto">
                      <b-form-input type="number" max="230" v-model="dynamicInfo.height"
                             @change="onAntropoSubmit" @blur="calcBodyMassIndex()"
                             autocomplete="off" class=" border_radius border-0 text-center  input_znachenie w_105"
                                    :state="!dynamicInfo.height || (dynamicInfo.height>0 && dynamicInfo.height < 231)"
                      >
                      </b-form-input>
                    </div>
                  </div>
                  <div class="d-flex py-2 py-xl-0">
                    <div class="m-auto">
													<span class="pr-3 span_page_8">
														Индекс массы тела
													</span>
                    </div>
                    <div class="m-auto">
                      <input type="text" v-model="dynamicInfo.bodyMassIndex"  @change="onAntropoSubmit" autocomplete="off" disabled class=" border_radius border-0 text-center span_input w_105" value="Заполните рост и вес">
                    </div>
                  </div>
                  <div class="d-flex py-2 py-xl-0  ">
                    <div class="m-auto">
													<span class="pr-3 span_page_8">
														Окружность талии, см
													</span>
                    </div>
                    <div class="m-auto">
                      <input type="text"  v-model="dynamicInfo.waistCircle"  @change="onAntropoSubmit" autocomplete="off" class="border_radius border-0 text-center input_znachenie w_105" value="" >
                    </div>
                  </div>
                  <div class=" d-flex py-2 pb-xl-0">
                    <div class="m-auto">
													<span class="span_page_8 m-auto">
														Артериальное <br> давление
													</span>
                    </div>

                    <div class="  m-auto pl-2">
                      <div class=" d-flex m-auto pl-2 w-100">
                        <span class="attribute_center_page_7 pr-1 text-center w_116">Систолическое</span>
                        <div class="attribute_center_page_7 px-2">&nbsp;</div>
                        <span class="attribute_center_page_7 pl-1 text-center w_116">Диастолическое</span>
                      </div>
                      <div class="d-flex m-auto pl-2">
                        <b-form-input type="text" v-model="dynamicInfo.sadPressure"
                                      @change="onAntropoSubmit" autocomplete="off" class="border_radius border-0 text-center  span_input w_116"
                                      :state="!dynamicInfo.sadPressure || (dynamicInfo.sadPressure>0 && dynamicInfo.sadPressure < 311)"
                        >
                        </b-form-input>
                        <div class="px-2 pt-1">/</div>
                        <b-form-input type="text" v-model="dynamicInfo.dadPressure"
                                      @change="onAntropoSubmit" autocomplete="off" class="border_radius border-0 text-center  span_input w_116"
                                      :state="!dynamicInfo.dadPressure || (dynamicInfo.dadPressure>0 && dynamicInfo.dadPressure < 221)"
                        >
                        </b-form-input>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <div class="py-2 row mx-0 d-flex justify-content-around">-->

<!--                  <div  class="d-flex">-->
<!--                    <div class="m-auto">-->
<!--														<span class="span_page_8 ">-->
<!--															Вредные привычки:-->
<!--														</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--&lt;!&ndash;                  <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="m-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                      <label class="pr-2 vrednie_privichki m-auto" for="customSwitch1">&ndash;&gt;-->
<!--&lt;!&ndash;                        Курение&ndash;&gt;-->
<!--&lt;!&ndash;                      </label>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="custom-control custom-switch m-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                      <input type="checkbox" class="custom-control-input" id="customSwitch1">&ndash;&gt;-->
<!--&lt;!&ndash;                      <label class="custom-control-label" for="customSwitch1"></label>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="m-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                      <label class="pr-2 vrednie_privichki m-auto" for="customSwitch2">&ndash;&gt;-->
<!--&lt;!&ndash;                        Алкоголь&ndash;&gt;-->
<!--&lt;!&ndash;                      </label>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="custom-control custom-switch m-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                      <input type="checkbox" class="custom-control-input" id="customSwitch2">&ndash;&gt;-->
<!--&lt;!&ndash;                      <label class="custom-control-label" for="customSwitch2"></label>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--                  <div class="d-flex">-->
<!--                    <div class="m-auto">-->
<!--                      <label class="pr-2 vrednie_privichki m-auto" >-->

<!--                      </label>-->
<!--                    </div>-->

<!--                    <div class="custom-control custom-switch m-auto">-->

<!--&lt;!&ndash;                      <input type="checkbox" class="custom-control-input" id="customSwitch3">&ndash;&gt;-->
<!--&lt;!&ndash;                      <label class="custom-control-label" for="customSwitch3"></label>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->

                <div class="row mx-0 pb-4 pt-5">
                  <div class="col-lg-4 col-6 pl-4">
                    <div>
                      <span class="span_page_8 d-flex">
                        Уровень физической активности
                      </span>
                      <div class="d-flex pt-3">
                        <b-checkbox-group switches :options="lifeStyleOptions" stacked v-model="lifeStyles" @change="lifeStyleSelect"></b-checkbox-group>
                      </div>
                      <div>
                        <span class="span_page_8 d-flex pt-5">
													Особенности питания
												</span>
                        <div class="d-flex pt-3">
                          <b-checkbox-group switches :options="dietOptions" stacked v-model="diets" @change="dietSelect"></b-checkbox-group>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div class="col-lg-4 col-6 d-flex justify-content-center">
                    <div>
												<span class="span_page_8 d-flex">
													Сексуальное здоровье
												</span>
                      <div class="d-flex pt-3">
                        <b-checkbox-group switches :options="sexualHealthOptions" stacked v-model="sexualHealths" @change="sexualHealthSelect"></b-checkbox-group>
                      </div>
                      <div>
                      <span class="span_page_8 d-flex pt-5">
                        Структура сна
                      </span>
                        <div class="d-flex pt-3">
                          <b-checkbox-group switches :options="sleepOptions" stacked v-model="sleeps" @change="sleepSelect"></b-checkbox-group>
                        </div>
                      </div>
                      <div>
                      <span class="span_page_8 d-flex pt-5">
                        Наркотики
                      </span>
                        <div class="d-flex pt-3">
                          <b-checkbox-group switches :options="narcoticOptions" stacked v-model="narcoticTypes" @change="narcoticTypeSelect" ></b-checkbox-group>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div class="col-lg-4  col-6 pl-5">
                    <div>
                      <span class="span_page_8 d-flex">
                        Курение
                      </span>
                      <div class="d-flex pt-3">
                        <b-checkbox-group switches :options="smokeOptions" stacked v-model="smokeTypes" @change="smokeTypeSelect"></b-checkbox-group>
                      </div>
                      <div>
                      <span class="span_page_8 d-flex pt-5">
                        Употребление алкоголя в течение последних двенадцати месяцев более 100 мл крепких напитков или более 300 мл вина или более 1 литра пива
                      </span>
                        <div class="d-flex pt-3">
                          <b-checkbox-group switches :options="alcoholOptions" stacked v-model="alcoholTypes" @change="alcoholTypeSelect"></b-checkbox-group>
                        </div>
                      </div>
                    </div>

                  </div>
<!--                  <div class="col-12 text-center pt-4">-->
<!--                    <button class="border-0 save_btn_page_5" type="submit">-->
<!--													<span class="text_save_page_5">Сохранить-->
<!--													</span>-->
<!--                    </button>-->
<!--                  </div>-->
                </div>

<!--                <div v-if="errorAntropo" class="alert alert-danger">-->
<!--                  {{errorAntropo}}-->
<!--                </div>-->
<!--                <b-form-group id="weightGroup" label-for="weight" label="Вес, кг" label-cols-lg="2">-->
<!--                  <b-form-input id="weight" type="number" placeholder="Вес пациентта в килограммах" v-model="dynamicInfo.weight" @change="onAntropoSubmit" @blur="calcBodyMassIndex()" autocomplete="off"></b-form-input>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="heightGroup" label-for="height" label="Рост, см" label-cols-lg="2">-->
<!--                  <b-form-input id="height" type="number" placeholder="Рост пациента в сантимерах" v-model="dynamicInfo.height"  @change="onAntropoSubmit" @blur="calcBodyMassIndex()" autocomplete="off"></b-form-input>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="bodyMassIndex" label-for="bodyMassIndex" label="Индекс массы тела" label-cols-lg="2">-->
<!--                  <b-form-input id="bodyMassIndex" type="text" disabled placeholder="Заполните рост и вес" v-model="dynamicInfo.bodyMassIndex"  @change="onAntropoSubmit" autocomplete="off"></b-form-input>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="pressureGroup" label-for="height" label="Артериальное давление, мм. рт. ст." label-cols-lg="2" >-->
<!--                  <div class="row">-->
<!--                    <div class="col-lg-5" >-->
<!--                      <b-form-input id="sadPressure" type="number" placeholder="Систолическое давление" v-model="dynamicInfo.sadPressure" @change="onAntropoSubmit" autocomplete="off"></b-form-input>-->
<!--                    </div>-->
<!--                    <div class="col-lg-1" align="center" >/</div>-->
<!--                    <div class="col-lg-5" >-->
<!--                      <b-form-input id="dadPressure" type="number" placeholder="Диастолическое давление" v-model="dynamicInfo.dadPressure"  @change="onAntropoSubmit" autocomplete="off"></b-form-input>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="waistCircleGroup" label-for="waistCircle" label="Окружность талии, см" label-cols-lg="2">-->
<!--                  <b-form-input id="waistCircle" type="number" placeholder="Окружность талии в сантимерах" v-model="dynamicInfo.waistCircle"  @change="onAntropoSubmit" autocomplete="off"></b-form-input>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="lifeStyleIdGroup" label-for="lifeStyleId" label="Уровень физической активности" label-cols-lg="2">-->
<!--                  &lt;!&ndash;                  <b-form-select id="lifeStyleId" v-model="dynamicInfo.lifeStyleId" :options="lifeStyleOptions" :select-size="lifeStyleOptions.length" @input="onChangeSelect" @click="onClick"></b-form-select>&ndash;&gt;-->
<!--                  <b-checkbox-group switches :options="lifeStyleOptions" stacked v-model="lifeStyles" @change="lifeStyleSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="sexualHealthIdGroup" label-for="sexualHealthId" label="Сексуальное здоровье" label-cols-lg="2">-->
                  <!--                  <b-form-select id="sexualHealthId" v-model="dynamicInfo.sexualHealthId" :options="sexualHealthOptions" :select-size="sexualHealthOptions.length"></b-form-select>-->
<!--                  <b-checkbox-group switches :options="sexualHealthOptions" stacked v-model="sexualHealths" @change="sexualHealthSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="sleepIdGroup" label-for="sleepId" label="Структура сна" label-cols-lg="2">-->
<!--                  &lt;!&ndash;                  <b-form-select id="sleepId" v-model="dynamicInfo.sleepId" :options="sleepOptions" :select-size="sleepOptions.length"></b-form-select>&ndash;&gt;-->
<!--                  <b-checkbox-group switches :options="sleepOptions" stacked v-model="sleeps" @change="sleepSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="dietIdGroup" label-for="dietId" label="Особенности питания" label-cols-lg="2">-->
<!--                  &lt;!&ndash;                  <b-form-select id="dietId" v-model="dynamicInfo.dietId" :options="dietOptions" :select-size="dietOptions.length"></b-form-select>&ndash;&gt;-->
<!--                  <b-checkbox-group switches :options="dietOptions" stacked v-model="diets" @change="dietSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->

<!--                <b-form-group id="smokeTypeGroup" label-for="smokeType" label="Курение" label-cols-lg="2">-->
<!--                  &lt;!&ndash;                  <b-form-select id="smokeType" v-model="dynamicInfo.smokeType" :options="smokeOptions" :select-size="smokeOptions.length"></b-form-select>&ndash;&gt;-->
<!--                  <b-checkbox-group switches :options="smokeOptions" stacked v-model="smokeTypes" @change="smokeTypeSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="alcoholTypeGroup" label-for="alcoholType" label="Употребление алкоголя в течение последних двенадцати месяцев более 100 мл крепких напитков или более 300 мл вина или более 1 литра пива" label-cols-lg="5">-->
<!--                  &lt;!&ndash;                  <b-form-select id="alcoholType" v-model="dynamicInfo.alcoholType" :options="alcoholOptions" :select-size="alcoholOptions.length"></b-form-select>&ndash;&gt;-->
<!--                  <b-checkbox-group switches :options="alcoholOptions" stacked v-model="alcoholTypes" @change="alcoholTypeSelect"></b-checkbox-group>-->
<!--                </b-form-group>-->

<!--                <b-form-group id="narcoticGroup" label-for="narcotic" label="Наркотики" label-cols-lg="2">-->
<!--                  <b-checkbox-group switches :options="narcoticOptions" stacked v-model="narcoticTypes" @change="narcoticTypeSelect"></b-checkbox-group>-->
<!--                  &lt;!&ndash;                  <b-checkbox v-model="dynamicInfo.narcotic" id="narcotic" switch>Наркотики</b-checkbox>&ndash;&gt;-->
<!--                </b-form-group>-->
                <!--                <b-button type="submit" variant="info" >Сохранить</b-button>-->
              </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card v-if="gender === 2 && ageNumber >= 10 && permission.questionaryTabReproductEnable" no-body class="mb-1">
          <b-card-header header-tag="div" class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block v-b-toggle.additional class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Текущее репродуктивно-физиологическое состояние женщины</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="additional" accordion="my-accordion" role="tabpanel">
            <b-card-body class="background_E9F5EA_page_7">
              <b-form @submit.prevent="onAdditionalSubmit">

                <b-form-group id="menstrualCyclePhaseIdGroup" label-for="menstrualCyclePhaseId" label="Фаза цикла" label-cols-lg="2">
                  <b-checkbox-group :disabled="dynamicInfo.postmenopause || dynamicInfo.postpartumWoman || dynamicInfo.pregnant || dynamicInfo.beforeMenarche" switches :options="menstrualCyclePhaseOptions" v-model="menstrualCyclePhases" @change="menstrualCyclePhaseSelect"></b-checkbox-group>
                  <!--                  <b-form-select id="menstrualCyclePhaseId" :disabled="dynamicInfo.postmenopause || dynamicInfo.postpartumWoman || dynamicInfo.pregnant" v-model="dynamicInfo.menstrualCyclePhaseId" :options="menstrualCyclePhaseOptions"-->
                  <!--                                 :select-size="1"></b-form-select>-->
                </b-form-group>
                <b-form-group id="beforeMenarcheGroup" label-for="beforeMenarche" >
                  <b-form-checkbox v-model="dynamicInfo.beforeMenarche" name="beforeMenarche" @change="toggleBeforeMenarche" switch>До менархе (10-16 лет)</b-form-checkbox>
                </b-form-group>
                <b-form-group id="pregnantGroup" label-for="pregnant" >
                  <b-form-checkbox v-model="dynamicInfo.pregnant" name="pregnant" @change="togglePregnant" switch>Беременность</b-form-checkbox>
                </b-form-group>
                <b-form-group id="postpartumWomanGroup" label-for="postpartumWoman" >
                  <b-form-checkbox v-model="dynamicInfo.postpartumWoman" name="postpartumWoman" @change="togglePostpartumWoman" switch>Родильница</b-form-checkbox>
                </b-form-group>
                <b-form-group id="postmenopauseGroup" label-for="postmenopause" >
                  <b-form-checkbox v-model="dynamicInfo.postmenopause" name="postmenopause" @change="togglePostmenopause" switch>Постменопауза</b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="dynamicInfo.pregnant" id="pregnantTrimesterGroup" label-for="pregnantTrimester" label="Триместр беременности" label-cols-lg="2">
                  <b-form-select id="pregnantTrimester" v-model="dynamicInfo.pregnantTrimester" @change="onTrimesterSubmit" :options="pregnantTrimesterOptions"
                                 :select-size="1"></b-form-select>
                </b-form-group>
                <b-form-group v-if="dynamicInfo.pregnant" id="pregnantWeekGroup" label-for="height" label="Неделя беременности" label-cols-lg="2">
                  <b-form-input id="pregnantWeek" type="text" v-mask="vMask" v-model="dynamicInfo.pregnantWeek"  @change="onPregnantWeekSubmit" autocomplete="off" :state="!dynamicInfo.pregnantWeek || (dynamicInfo.pregnantWeek >= 1 && dynamicInfo.pregnantWeek <= 43)"></b-form-input>
                </b-form-group>
                <b-form-group v-if="dynamicInfo.pregnant" id="pregnantDateStartGroup" label-for="pregnantDateStart" label="Дата зачатия">
                  <b-form-input id="pregnantDateStart" type="date" placeholder="" v-model="dynamicInfo.pregnantDateStart" @change="onPregnantDateStartSubmit" autocomplete="off"></b-form-input>
                </b-form-group>


                <!--                <b-button type="submit" variant="info" >Сохранить</b-button>-->
              </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Жалобы -->
        <b-card no-body class="mb-1" v-if="permission.questionaryTabComplaintsEnable">
          <b-card-header header-tag="div" class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block v-b-toggle.complaints class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Жалобы</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="complaints" accordion="my-accordion" role="tabpanel">
            <b-card-body class="background_E9F5EA_page_7 padding_0">
              <PatientComplaints v-bind:patient-id="patientId" v-bind:gender="gender" ref="patientComplaints"></PatientComplaints>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Хронические заболевания -->
        <b-card no-body class="mb-1" v-if="permission.questionaryTabChronicEnable">
          <b-card-header header-tag="div" class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block v-b-toggle.chronicDiseases class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Хронические заболевания</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="chronicDiseases" accordion="my-accordion" role="tabpanel">
            <b-card-body class="background_E9F5EA_page_7">
              <ChronicDiseases v-bind:patient-id="patientId"></ChronicDiseases>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Лекарственные препараты -->
        <b-card no-body class="mb-1" v-if="permission.questionaryTabDrugEnable">
          <b-card-header header-tag="div" class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block v-b-toggle.patientDrugs class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Лекарственные препараты</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="patientDrugs" accordion="my-accordion" role="tabpanel">
            <b-card-body class="background_E9F5EA_page_7">
              <PatientDrugs v-bind:patient-id="patientId"></PatientDrugs>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Наследственность -->
        <b-card no-body class="mb-1" v-if="permission.questionaryTabHeredityEnable">
          <b-card-header header-tag="div" class="pt-1 pb-1 pl-3 d-flex justify-content-between background_page_8 border_bottom_page_8-10" role="tab">
            <button block v-b-toggle.heredity class="border-0 outline_none_background tabheader__button">
              <span class="btn_page_8">Наследственность</span>
            </button>
            <span class="justify-content-end">
              <img src="@/assets/images/patients/questionary/arrow_down.png" >
            </span>
          </b-card-header>
          <b-collapse id="heredity" accordion="my-accordion" role="tabpanel">
            <b-card-body class="background_E9F5EA_page_7">
              <Heredity v-bind:patient-id="patientId"></Heredity>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>
    </div>
    </template>
  </div>

</template>

<script>
  import {host, pregnantWeekMask} from '@/constants'
  import PatientComplaints from "@/components/patients/PatientComplaints";
  import ChronicDiseases from "@/components/patients/ChronicDiseases";
  import Heredity from "@/components/patients/Heredity";
  import PatientDrugs from "@/components/patients/PatientDrugs";
  import createNumberMask from "text-mask-addons/dist/createNumberMask";

  export default {
    components: {PatientDrugs, Heredity, ChronicDiseases, PatientComplaints},
    props: ["patientId"],
    name: "DynamicPatientInfoForm",
    computed: {
      role: {
        get() {
          return this.$store.getters.role
        }
      },
      permission: {
        get() {
          return this.$store.getters.permission
        }
      }
    },
    data() {
      return {
        patient: Object,
        gender: 0,
        ageNumber: 0,
        vMask: pregnantWeekMask,
        oldLifestyleId: null,
        dynamicInfo: Object,
        lifeStyleOptions: [],
        lifeStyles: [],
        sexualHealthOptions: [],
        sexualHealths: [],
        dietOptions: [],
        sleeps: [],
        diets: [],
        sleepOptions: [],
        smokeOptions: [],
        smokeTypes: [],
        alcoholOptions: [],
        alcoholTypes: [],
        menstrualCyclePhases: [],
        narcoticTypes: [],
        narcoticOptions: [
          {value: true, text: "употребляю"},
          {value: false, text: "не употребляю"},
        ],

        loaded: false,
        errorAntropo: null,
        menstrualCyclePhaseOptions: [
          {value:0, text:"не известна", disabled: false},
          {value:1, text:"фолликулярная", disabled: false},
          {value:2, text:"овуляторная", disabled: false},
          {value:3, text:"лютеиновая", disabled: false}
        ],
        pregnantTrimesterOptions: [{value: 1, text: '1'}, {value: 2, text: '2'}, {value: 3, text: '3'}]
      }
    },
    // watch: {
    //   lifeStyles(newVal, oldVal) {
    //
    //     console.log('oldVal ' + oldVal)
    //
    //     this.lifeStyles = newVal.filter(el => (!oldVal.includes(el)))
    //
    //     console.log('newVal ' + this.lifeStyles)
    //
    //     // if (newVal) {
    //     //
    //     // }
    //   }
    // },
    methods : {
      lifeStyleSelect(checked) {
        let current = checked.filter(el => (el !== this.dynamicInfo.lifeStyleId))
        if (current.length > 0) {
          this.dynamicInfo.lifeStyleId = current[0]
        } else {
          this.dynamicInfo.lifeStyleId = null
        }
        this.lifeStyles = current
        this.onAntropoSubmit()
      },
      sexualHealthSelect(checked) {
        let current = checked.filter(el => (el !== this.dynamicInfo.sexualHealthId))
        if (current.length > 0) {
          this.dynamicInfo.sexualHealthId = current[0]
        } else {
          this.dynamicInfo.sexualHealthId = null
        }
        this.sexualHealths = current
        this.onAntropoSubmit()
      },
      sleepSelect(checked) {
        //console.log(checked)
        //let checkedSleepOk = checked.filter(el => (el == 1))
        if (checked.length > 0 && checked[checked.length - 1] == 1) {
          this.sleeps = [1]
        } else {
          this.sleeps = checked.filter(el => (el !== 1))
        }
        this.dynamicInfo.sleeps = this.sleeps

        this.onSleepSubmit()

      },
      dietSelect(checked) {
        if (checked.length > 0 && (checked[checked.length - 1] <= 2 || checked[checked.length - 1] >= 6)) {
          this.diets = [checked[checked.length - 1]]
        } else {
          this.diets = checked.filter(el => (el == 3 || el == 4 || el == 5 ))
        }

        this.dynamicInfo.diets = this.diets
        this.onDietSubmit()
      },
      smokeTypeSelect(checked) {
        let current = checked.filter(el => (el !== this.dynamicInfo.smokeType))
        if (current.length > 0) {
          this.dynamicInfo.smokeType = current[0]
        } else {
          this.dynamicInfo.smokeType = null
        }
        this.smokeTypes = current
        this.onAntropoSubmit()
      },
      alcoholTypeSelect(checked) {
        let current = checked.filter(el => (el !== this.dynamicInfo.alcoholType))
        if (current.length > 0) {
          this.dynamicInfo.alcoholType = current[0]
        } else {
          this.dynamicInfo.alcoholType = null
        }
        this.alcoholTypes = current
        this.onAntropoSubmit()
      },
      narcoticTypeSelect (checked) {
        let current = checked.filter(el => (el !== this.dynamicInfo.narcotic))
        if (current.length > 0) {
          this.dynamicInfo.narcotic = current[0]
        } else {
          this.dynamicInfo.narcotic = null
        }
        this.narcoticTypes = current
        this.onAntropoSubmit()
      },
      menstrualCyclePhaseSelect(checked) {
        console.log('menstrualCyclePhaseSelect "' + checked + '"')
        if ((this.patient.ageNumber > 45 || this.patient.ageNumber < 12)) {
          this.menstrualCyclePhases = []
          return
        }
        let current = checked.filter(el => (el !== this.dynamicInfo.menstrualCyclePhaseId))
        if (current.length > 0) {
          this.dynamicInfo.menstrualCyclePhaseId = current[0]
        } else {
          this.dynamicInfo.menstrualCyclePhaseId = null
        }
        this.menstrualCyclePhases = current
        this.onAdditionalSubmit()
      },
      onClick(evt) {
        console.log('click!')
        console.log(evt)
      },
      onChangeSelect(newVal) {
        console.log('newVal' +  this.dynamicInfo.lifeStyleId)
        console.log('modelVal' + this.oldLifestyleId)
        if (this.oldLifestyleId === this.dynamicInfo.lifeStyleId) {
          this.dynamicInfo.lifeStyleId = null
        }
        this.oldLifestyleId = this.dynamicInfo.lifeStyleId
      },
      togglePostmenopause(checked) {
        if (checked && (this.patient.ageNumber < 30)) {
          this.dynamicInfo.postmenopause = false
          return
        }
        if (checked) {
          this.dynamicInfo.pregnant = false
          this.dynamicInfo.beforeMenarche = false
          this.dynamicInfo.postpartumWoman = false
          this.dynamicInfo.menstrualCyclePhaseId = null
          this.menstrualCyclePhases = []
          this.dynamicInfo.pregnantTrimester = null
          this.dynamicInfo.pregnantWeek = null
          this.dynamicInfo.pregnantDateStart = null
        }
        this.onAdditionalSubmit()
      },
      togglePostpartumWoman(checked) {
        if (checked && (this.patient.ageNumber > 45 || this.patient.ageNumber < 12)) {
          this.dynamicInfo.postpartumWoman = false
          return
        }
        if (checked) {
          this.dynamicInfo.pregnant = false
          this.dynamicInfo.beforeMenarche = false
          this.dynamicInfo.postmenopause = false
          this.dynamicInfo.menstrualCyclePhaseId = null
          this.menstrualCyclePhases = []
          this.dynamicInfo.pregnantTrimester = null
          this.dynamicInfo.pregnantWeek = null
          this.dynamicInfo.pregnantDateStart = null
        }
        this.onAdditionalSubmit()
      },
      toggleBeforeMenarche(checked) {
        if (checked && (this.patient.ageNumber > 16 || this.patient.ageNumber < 10)) {
          this.dynamicInfo.beforeMenarche = false
          return
        }
        if (checked) {
          this.dynamicInfo.pregnant = false
          this.dynamicInfo.postpartumWoman = false
          this.dynamicInfo.postmenopause = false
          this.dynamicInfo.menstrualCyclePhaseId = null
          this.menstrualCyclePhases = []
        }
        this.onAdditionalSubmit()
      },
      togglePregnant(checked) {
        if (checked && (this.patient.ageNumber > 45 || this.patient.ageNumber < 12)) {
          this.dynamicInfo.pregnant = false
          return
        }
        if (checked) {
          this.dynamicInfo.beforeMenarche = false
          this.dynamicInfo.postpartumWoman = false
          this.dynamicInfo.postmenopause = false
          this.dynamicInfo.menstrualCyclePhaseId = null
          this.menstrualCyclePhases = []
        }
        this.onAdditionalSubmit()
      },
      calcBodyMassIndex() {
        fetch(host + '/rest/patients/bodymassindex/' + this.dynamicInfo.weight + '/' + this.dynamicInfo.height, {
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
            .then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.dynamicInfo.bodyMassIndex = json.bodyMassIdex
                this.errorAntropo = null
              } else {
                this.errorAntropo = json.message;
              }
            }).catch(error => {
          console.log(JSON.stringify(error))
        })
      },
      async onTrimesterSubmit() {
        this.dynamicInfo.pregnantWeek = null
        this.dynamicInfo.pregnantDateStart = null
        await this.onAdditionalSubmit()
      },
      async onPregnantWeekSubmit() {
        this.dynamicInfo.pregnantDateStart = null

        if (this.dynamicInfo.pregnantWeek >= 1 && this.dynamicInfo.pregnantWeek <=13) {
          this.dynamicInfo.pregnantTrimester = 1
        } else if (this.dynamicInfo.pregnantWeek >= 14 && this.dynamicInfo.pregnantWeek <= 28) {
          this.dynamicInfo.pregnantTrimester = 2
        } else if (this.dynamicInfo.pregnantWeek >= 29 && this.dynamicInfo.pregnantWeek <= 43) {
          this.dynamicInfo.pregnantTrimester = 3
        } else {
          this.dynamicInfo.pregnantWeek = null
          return
        }
        await this.onAdditionalSubmit()
      },
      async onPregnantDateStartSubmit() {

        if (this.dynamicInfo.pregnantDateStart && new Date(this.dynamicInfo.pregnantDateStart) < new Date()) {
          this.dynamicInfo.pregnantWeek = Math.floor(((await this.dateDiff(new Date(this.dynamicInfo.pregnantDateStart), new Date()) )/7 +1) )
          if (this.dynamicInfo.pregnantWeek >= 1 && this.dynamicInfo.pregnantWeek <=13) {
            this.dynamicInfo.pregnantTrimester = 1
          } else if (this.dynamicInfo.pregnantWeek >= 14 && this.dynamicInfo.pregnantWeek <= 28) {
            this.dynamicInfo.pregnantTrimester = 2
          } else if (this.dynamicInfo.pregnantWeek >= 29 && this.dynamicInfo.pregnantWeek <= 43) {
            this.dynamicInfo.pregnantTrimester = 3
          } else {
            this.dynamicInfo.pregnantWeek = null
            return
          }
        }


        await this.onAdditionalSubmit()
      },
      async dateDiff(first, second) {
        let result = Math.round((second-first)/(1000*60*60*24))
        return result;
      },
      async onAntropoSubmit(){
        if (this.dynamicInfo.height > 230) {
          this.$errorToast('Рост должен быть меньше 230 см')
          return
        }
        if (this.dynamicInfo.weight > 400) {
          this.$errorToast('Вес должен быть меньше 400 кг')
          return
        }
        if (this.dynamicInfo.sadPressure > 400) {
          this.$errorToast('Систолическое давление должно быть меньше 310 мм рт.ст.')
          return
        }
        if (this.dynamicInfo.dadPressure > 400) {
          this.$errorToast('Диастолическое давление должно быть меньше 220 мм рт.ст.')
          return
        }
        try {
          const rq = {patientId: this.patientId, patientInfo: this.dynamicInfo}
          console.log(JSON.stringify(rq))
          let response = await fetch(host + '/rest/patients/dynamic/antropo', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()

          if (json.resultCode == 0) {
            console.log('save success ' + json)
            //this.makeToast("Общие данные")
          } else {

            console.log('Login Failed ' + json)
            this.errorAntropo = json.message;
          }
        } catch(error) {
          console.log(JSON.stringify(error))
        }

        await this.onLifestyleSubmit()

        // noinspection ES6MissingAwait
        this.$refs.patientComplaints.fetchData()
      },
      async onSleepSubmit(){
        try {
          const rq = {patientId: this.patientId, patientInfo: this.dynamicInfo}
          console.log(JSON.stringify(rq))
          let response = await fetch(host + '/rest/patients/dynamic/sleeps', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()

          if (json.resultCode == 0) {
            console.log('save success ' + json)
            //this.makeToast("Общие данные")
          } else {

            this.errorAntropo = json.message;
          }
        } catch(error) {
          console.log(JSON.stringify(error))
        }

        // noinspection ES6MissingAwait
        this.$refs.patientComplaints.fetchData()
      },
      async onDietSubmit(){
        try {
          const rq = {patientId: this.patientId, patientInfo: this.dynamicInfo}
          console.log(JSON.stringify(rq))
          let response = await fetch(host + '/rest/patients/dynamic/diets', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()

          if (json.resultCode == 0) {
            console.log('save success ' + json)
            //this.makeToast("Общие данные")
          } else {

            this.errorAntropo = json.message;
          }
        } catch(error) {
          console.log(JSON.stringify(error))
        }

        // noinspection ES6MissingAwait
        this.$refs.patientComplaints.fetchData()
      },
      async onAdditionalSubmit(){
        try {
          const rq = {patientId: this.patientId, patientInfo: this.dynamicInfo}
          console.log(JSON.stringify(rq))
          let response = await fetch(host + '/rest/patients/dynamic/additional', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()
          if (json.resultCode == 0) {
            this.$messageToast('Данные о женском здоровье', 500)

          } else {
            this.$errorToast(json.resultCode + ' ' + json.message)
            this.errorAntropo = json.message;
          }
        } catch(error) {
          console.log(error)
          this.$errorToast('Неизвестная ошибка')
        }
        // noinspection ES6MissingAwait
        this.$refs.patientComplaints.fetchData()
        this.$emit('updateData')
      },
      async onLifestyleSubmit(){
        try {
          const rq = {patientId: this.patientId, patientInfo: this.dynamicInfo}
          console.log(JSON.stringify(rq))
          let response = await fetch(host + '/rest/patients/dynamic/lifestyle', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await  response.json()
          if (json.resultCode == 0) {
            console.log('save success ' + json)
            this.makeToast("Общие данные", 500)

          } else {

            console.log('Login Failed ' + json)
            this.errorAntropo = json.message;
          }
        } catch(error) {
          console.log(JSON.stringify(error))
        }
      },
      async finishQuestionary() {
        this.saving = true
        try {


          let response = await fetch(host + '/rest/patients/' + this.patientId + '/questionary/finish', {
            method: 'PUT',
            credentials: 'include',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()

          if (json.resultCode !== 0) {
            this.$errorToast(json.resultCode + ' ' + json.message)
          } else {
            this.$messageToast('Данные анкеты отправлены врачу')
          }
        } catch(error)  {
          console.log(error)
          this.$errorToast('Неизвестная ошибка')
        }
        this.saving = false
      },
      makeToast(title = '', autoHideDelay = 5000) {
        this.$bvToast.toast("Сохранены успешно", {
          title: title,
          toaster: 'b-toaster-top-center',
          variant: "info",
          autoHideDelay: autoHideDelay,
          appendToast: true
        })
      },
      makeErrorToast(message = '') {
        this.$bvToast.toast(message, {
          title: `Ошибка`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
          noAutoHide: true,
          appendToast: true
        })
      }
    },

    mounted() {
      fetch(host + '/rest/patients/' + this.patientId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.patient = json.patient
            this.gender = this.patient.genderCode
            this.ageNumber = this.patient.ageNumber
            this.loading = false
          })

      fetch(host + '/rest/patients/dynamic/' + this.patientId, {
        credentials: "include"
      })
        .then(response => response.json())
        .then(json => {
          this.dynamicInfo = json.clientInfo
          this.lifeStyles.push(this.dynamicInfo.lifeStyleId)
          this.sleeps = this.dynamicInfo.sleeps
          this.diets = this.dynamicInfo.diets
          this.sexualHealths.push(this.dynamicInfo.sexualHealthId)
          this.alcoholTypes.push(this.dynamicInfo.alcoholType)
          this.smokeTypes.push(this.dynamicInfo.smokeType)
          this.menstrualCyclePhases.push(this.dynamicInfo.menstrualCyclePhaseId)
          this.narcoticTypes.push(this.dynamicInfo.narcotic)
          this.loaded = true
          console.log(json)
          console.log(this.loaded)
        })
      fetch(host + '/rest/dicts/lifeStyle')
              .then(response => response.json())
              .then(json => {
                this.lifeStyleOptions = this.lifeStyleOptions.concat(json.options)
                console.log(json)
              })
      fetch(host + '/rest/dicts/sexualHealth')
              .then(response => response.json())
              .then(json => {
                //this.sexualHealthOptions = [{value: null, text:'Не выбрано'}]
                this.sexualHealthOptions = this.sexualHealthOptions.concat(json.options)
                console.log(json)
              })
      fetch(host + '/rest/dicts/diet')
              .then(response => response.json())
              .then(json => {
                //this.dietOptions = [{value: null, text:'Не выбрано'}]
                this.dietOptions = this.dietOptions.concat(json.options)
                console.log(json)
              })
      fetch(host + '/rest/dicts/sleep')
              .then(response => response.json())
              .then(json => {
                //this.sleepOptions = [{value: null, text:'Не выбрано'}]
                this.sleepOptions = this.sleepOptions.concat(json.options)
                console.log(json)
              })
      fetch(host + '/rest/dicts/smoketype')
          .then(response => response.json())
          .then(json => {
            this.smokeOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/dicts/alcoholtype')
          .then(response => response.json())
          .then(json => {
            this.alcoholOptions = json.options
            console.log(json)
          })
      // fetch(host + '/rest/dicts/menstrualcyclephase')
      //     .then(response => response.json())
      //     .then(json => {
      //       this.menstrualCyclePhaseOptions = this.menstrualCyclePhaseOptions.concat(json.options)
      //       console.log(json)
      //     })
    }
  }
</script>

<style scoped>

.tabheader__button{
  display: block;
  width: 100%;
  text-align: left;
}
</style>