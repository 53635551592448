<template>
  <div class="row mx-0 h-100">
<!--    <img src="@/assets/images/BAnner2.png" class="w-100" style="height: 28px; max-height: 28px;">-->
<!--    <div class="w-100" style="background: url('@/assets/images/BAnner.png') no-repeat; min-height: 28px;"></div>-->
<!--    <b-button variant="info" to="/patientsadd">Создать пациента</b-button>-->
<!--    <br /><br />-->
    <Loader v-bind:showLoader="loading" />
<!--    <b-table v-if="!loading" striped hover :items="items" :fields="fields" @row-clicked="rowClickHandler">-->
<!--      &lt;!&ndash; A custom formatted column &ndash;&gt;-->
<!--      <template v-slot:cell(fullname)="data">-->
<!--        <b>{{ data.item.firstname }}</b> <b class="text-info">{{ data.item.lastname }}</b>-->
<!--      </template>-->
<!--    </b-table>-->



<!--    <CabinetSidebar />-->
    <!------------------------------ПАЦИЕНТЫ КОМП ВЕРСИЯ-------------------------------------------------->
    <div class="col-12 col-lg-12 col-xl-12 d-lg-flex d-none h-100">
      <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-4">
          <div class="row border-bottom pb-2">
            <div class="col-4 pt-4 ">
              <img src="@/assets/images/Group 68.png" class="pb-2">
              <span class="pl-2 patients">Пациенты</span>
            </div>
            <div class="col-5 pt-3">
              <router-link tag="button" class="border-0 pt-1 btn_new_pacient" to="/patientsadd"><img src="@/assets/images/akar-icons_circle-plus.png"></router-link>
              <router-link to="/patientsadd"><span class="new_patient">Новый пациент</span></router-link>
            </div>
            <div class="col-3 pt-4">
              <form >
                <div class="search row justify-content-end pr-3 ">
                  <div class="d-flex">
                    <input type="search" placeholder="Поиск" v-model="searchRequest" class="border-right-0 px-4 search_text">
                    <input type="button" value="" class="border-0 pl_search_btn">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 py-2 px-4">

          <b-pagination v-if="(searchRequest == null || searchRequest == '' || this.searchRequest == -1)"
                        v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                        @change="fetchData" variant="success" pills first-number last-number
                        prev-class="paging-elements" next-class="paging-elements" page-class="paging-elements" ellipsis-class="paging-elements">
            <template #prev-text class="paging-elements-temp"><b>&#60;</b></template>
            <template #next-text class="paging-elements-temp"><b>&#62;</b></template>
          </b-pagination>


              <div v-for="(item) in searchItems(fullItems, items)" :key="item.id" class="row my-3 mx-0 fio_id_block">

                <div class="col-5 col-lg-5 d-flex fio_block id_block_first">
                  <div class="d-flex">
                    <div class="">
                      <img src="@/assets/images/Group 69.png">
                    </div>
                    <div class="pl-4 pt-2">
                      <div>
                        <b-link @click="rowClickHandler(item)" class="FIO">{{ item.lastname }} {{ item.firstname }}
                          {{ item.middlename }}
                          <span class="old">, {{ item.ageText }}, {{ item.gender ? item.gender.substring(0, 1).toUpperCase() : ""}}</span>
                        </b-link>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-lg-4 d-flex id_block justify-content-end">
                  <div class="diagnoz text-right">
                    <button @click="getLink(item)" type="button" class="save_btn_common">Сформировать ссылку для заполнения анкеты пациентом</button>
                  </div>
                </div>
                <div class="col-2 col-lg-2 d-flex id_block">
                  <span class="ID m-auto">ID {{ item.clientCode }}</span>
                </div>
                <div class="col-1 col-lg-1 d-flex justify-content-around mt-1">

                  <router-link tag="button" :to="{name: 'patientsedit', params: { patientId: item.id } }" class="border-0 btn_trns"><div><img title="Редактировать пациента" src="@/assets/images/Group 142.png"></div></router-link>
                  <button class="border-0 btn_trns" @click="deletePatient(item)"><div><img title="Удалить пациента" src="@/assets/images/gg_close-o.png"></div></button>
                </div>
              </div>



          <div class="col-12 text-center pb-4">
<!--            <a href="#" class="color_black">12......10</a>-->
          </div>
        </div>
      </div>
      </div>
    </div>
    <!------------------------------ПАЦИЕНТЫ MOB ВЕРСИЯ-------------------------------------------------->
    <div class="col-10 col-md-9 d-lg-none">
      <div class="row">
        <div class="col-6 text-left pt-3">
          <img src="@/assets/images/Group 68.png" class="pb-2">
          <span class="pl-2 patients">Пациенты</span>
        </div>

        <div class="col-6 pt-3 text-right">
          <form>
            <div class="search row justify-content-end ">
              <div class="d-flex">
                <input type="search" placeholder="Поиск" v-model="searchRequest" class="border-right-0 w-75 search_text">
                <input type="submit" value="" class="border-0 search_btn">
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 text-center">
          <router-link tag="button" class="border-0 pt-1 btn_new_pacient" to="/patientsadd"><img src="@/assets/images/akar-icons_circle-plus.png"></router-link>
          <router-link to="/patientsadd"><span class="new_patient">Новый пациент</span></router-link>
        </div>

        <div class="px-3">

          <b-pagination v-if="(searchRequest == null || searchRequest == '' || this.searchRequest == -1)"
                        v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                        @change="fetchData" variant="success" pills first-number last-number
                        prev-class="paging-elements" next-class="paging-elements" page-class="paging-elements" ellipsis-class="paging-elements">
            <template #prev-text class="paging-elements-temp"><b>&#60;</b></template>
            <template #next-text class="paging-elements-temp"><b>&#62;</b></template>
          </b-pagination>


          <div class="row mx-0 my-3 fio_id_block" v-for="(item) in searchItems(fullItems, items)" :key="item.id">
            <div class="col-12">

                <div>
                  <a href="#" class="FIO">{{ item.lastname }} {{ item.firstname }}
                    {{ item.middlename }}
                    <span class="old">({{ item.ageText }})</span>
                  </a>
                </div>
                <div class="diagnoz">
                  <span> Д/ р:  {{ item.birthday }} | </span>
                  <span> Диагноз: </span>
                </div>

            </div>

            <div class="col-12 d-flex justify-content-between border-top">
              <div class="d-flex id_block_mob">
                <span class="ID m-auto">ID {{ item.clientCode }}</span>
              </div>
              <div>
<!--                <button class="border-0 btn_trns"><div><img src="images/Group 142.png"></div></button>-->
<!--                <button class="border-0 btn_trns"><div><img src="images/gg_close-o.png"></div></button>-->
              </div>
            </div>
          </div>



        </div>
        <div class="col-12 text-center pb-4">
<!--          <a href="#" class="color_black">12......10</a>-->
        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import CabinetSidebar from "@/components/CabinetSidebar";
  import Loader from '@/components/Loader'
  import {host} from '@/constants'
  export default {
    name: "PatientList",
    data() {
      return {
        fieldsDesign: [
          {
            key: 'designRow'
          },
        ],
        items: [],
        fullItems: [],
        currentPage: 1,
        perPage: 100,
        totalRows: 100,
        loading: true
      }
    },
    computed: {
      searchRequest: {
        get() {
          return this.$store.getters.patientListSearchRequest
        },
        set(value) {
          this.$store.commit('updatePatientListSearchRequest', value)
        }
      },
    },
    mounted() {
      this.fetchData(1)
      this.fetchFullData()

    },
    methods: {
      fetchFullData() {
        fetch(host + '/rest/patients', {
          credentials: "include"
        }).then(response => response.json())
            .then(json => {
              console.log(json)
              this.fullItems = json.patients
            })
      },
      fetchData(pageNumber = 1) {
        this.loading = true
        fetch(host + '/rest/patients/paging/' + pageNumber, {
          credentials: "include"
        }).then(response => response.json())
            .then(json => {
              console.log(json)
              this.totalRows = json.totalRows
              this.perPage = json.perPage
              this.items = json.patients

              this.loading = false
            })
      },
      getLink(item) {
        fetch(host + '/rest/register/link/' + item.id, {
          method: 'GET',
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              console.log('Response ' + JSON.stringify(json))
              if (json.resultCode === 0) {
                this.$customInfoMessageToast('Ссылка для пациента ' + item.lastname + ' ' + item.firstname + ' ' + item.middlename, json.url)
              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }
            }).catch(error => {
          this.$errorToast(json.resultCode + ' ' + json.message)
        })

      },
      deletePatient(client) {
        if (confirm('Вы действительно хотите удалить данного пациента?')) {
          fetch(host + '/rest/patients/' + client.id, {
            credentials: "include",
            method: 'DELETE'
          }).then(response => response.json())
              .then(json => {
                if (json.resultCode === 0) {
                  this.$messageToast('Удаление пациента')
                  this.items.splice(this.items.indexOf(client), 1)
                } else {
                  this.$errorToast(json.resultCode + ' ' + json.message)
                }
              }).catch(er => {
                this.$errorToast(er)
              })
        }
        console.log('deletePatient call ' + client.clientCode)
      },
      searchItems(fullItems, items) {
        if (this.searchRequest !== null && this.searchRequest !== "" && this.searchRequest !== -1) {
          return fullItems.filter((el) => (('' + el.lastname + el.firstname + el.middlename + el.clientCode).toLowerCase().includes(this.searchRequest.toLowerCase()))).slice(0, this.perPage)
        }
        return items
      },
      rowClickHandler(item) {
        //console.log(item.id)
        this.$router.push('/patients/' + item.id + '/examinations')
      }
    },
    components: {
      Loader, CabinetSidebar
    }
  }
</script>

<style >

@import '../assets/css/main_page.css';

</style>