<template>

<!--  <ul class="sidenav app-sidenav" >-->
<!--    <router-link-->
<!--        v-for="link in links"-->
<!--        :key="link.url"-->
<!--        tag="li"-->
<!--        active-class="active"-->
<!--        :to="link.url"-->
<!--        :exact="link.exact"-->
<!--    >-->
<!--      <a href="#" class="waves-effect waves-orange pointer">{{link.title}}</a>-->
<!--    </router-link>-->
<!--  </ul>-->

  <div class="p-0" >
    <b-nav vertical v-if="isAuthorized">
      <b-nav-item v-for="link in links" :key="link.url" class="nav-custom" active-class="active nav-active-custom" v-if="role === link.role" :to="link.url" :disabled="link.delimeter === true">{{link.title}}</b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  props: ['value'],
  name: "SidebarMenu",
  computed: {
    isAuthorized: {
      get() {
        return this.$store.getters.isAuthorized
      }
    },
    role: {
      get() {
        return this.$store.getters.role
      }
    }
  },
  data() {
    return {

      error: null,

      links: [
        {role: 3, url: '/analits', title: 'Аналиты', exact: true},
        {role: 3, url: '/rulesandformulas', title: 'Правила и формулы'},
        {role: 3, url: '/rubricators', title: 'Рубрикаторы'},
        {role: 3, url: '/factors', title: 'Элементы заключения и рекомендаций'},
        {role: 3, url: '/zones', title: 'Элементы интерпретации'},
        {role: 3, url: '/scales', title: 'Альтернативные шкалы'},
        //{role: 3, url: '/scaleranges', title: 'Шкалы диапазонов'},
        {role: 3, url: '/checklists', title: 'Анкета'},
        {role: 3, url: '/reports/settings', title: 'Настройка отчётов'},
        //{role: 3, url: null, title: '', delimeter: true},
        {role: 3, url: '/doctors', title: 'Врачи'},
        {role: 3, url: '/clinics', title: 'Клиники'},
        {role: 3, url: '/specializations', title: 'Специализации'},
        {role: 3, url: '/pins', title: 'Pin-коды'},
        {role: 3, url: '/customers', title: 'Заказчики'},
        {role: 3, url: '/paramcodemappings', title: 'Группы соответствия аналитов'},
        {role: 2, url: '/patients', title: 'Пациенты'}
      ]
    }
  }
}
</script>

<style scoped>
.nav-active-custom {
  color: #fff!important;
  background-color: #17a2b8 !important;
}

.nav-custom{
  border-right: none!important;
  margin-right: 0!important;
  border-radius: 0!important;

}

.app-sidenav {
  padding-top: 5px;
  position: absolute;
  top: 64px;
  width: 250px;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  bottom: 0 !important;
  height: auto !important
}

.app-sidenav.open {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

</style>