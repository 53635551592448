<template>
  <b-container fluid>
    <b-row>
    </b-row>

    <b-row v-for="(item, index) in paramsTemplates" :key="item.paramTemplateId" style="height: 26px; line-height: 22px;">
      <b-col cols="9">
        <template v-if="ptedit[index] == true">
          <input v-model="newName" class="border-0 analit-input" style="text-align: left!important; font-weight: normal!important;">
          <b-link @click="modifyTemplateName(item, newName, index)" ><b-icon icon="check2"></b-icon></b-link>
          <b-link @click="deactivateEditMode(item, index)" ><b-icon icon="x-circle"></b-icon></b-link>
        </template>
        <template v-else >
          {{item.paramTemplateName}}
          <b-link @click="activateEditMode(item, index)"><b-icon icon="pencil" ></b-icon></b-link>
        </template>

      </b-col>
      <b-col cols="2"><b-link @click="sendToClient(item)">Назначить</b-link></b-col>
      <b-col><b-link @click="deleteParamsTemplate(item)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>

    <Loader v-bind:show-loader="loading"></Loader>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import Loader from "@/components/Loader";

export default {
  props: ["patientId"],
  name: "ParamsTemplateRecommendations",
  components: {Loader},
  data() {
    return {
      loading: false,
      paramsTemplates:[],
      ptedit:[],
      newName: ''
    }
  },
  methods: {
    modifyTemplateName(item, newName, index) {
      console.log('save ' + newName)
      this.loading = true

      fetch(host + '/rest/params-templates', {
        method: "PUT",
        credentials: "include",
        headers: {'Content-Type': 'application/json; charset=utf-8'},
        body: JSON.stringify({paramsTemplateName: newName, paramsTemplateId: item.paramTemplateId})
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              item.paramTemplateName = newName
              this.deactivateEditMode(item, index)
            } else {
              this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
            }
            this.loading = false
            this.$forceUpdate()
          })
          .catch(error => {
            console.log(error)
            this.$errorToast("Возникла непредвиденная ошибка, попробуйте повторить позже")
            this.loading = false
          })
    },
    activateEditMode(item, index) {
      this.newName = item.paramTemplateName
      this.ptedit[index] = true
      //console.log('activateEditMode ' + this.ptedit)

      this.$forceUpdate()
    },
    deactivateEditMode(item, index) {
      console.log("deactivateEditMode call")
      this.newName = ''
      this.ptedit[index] = false
      this.$forceUpdate()
    },
    async deleteParamsTemplate(item) {
      try {
        if (confirm('Вы уверены, что хотите удалить шаблон "' + item.paramTemplateName + '"?')) {
          let response = await fetch(host + '/rest/params-templates/' + item.paramTemplateId, {
            method: "DELETE",
            credentials: "include",
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          })
          let json = await response.json()
          if (json.resultCode === 0) {
            await this.fetchParamsTemplates()
          } else {
            this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
          }
        }
      } catch(error) {
        console.log(error)
      }
    },



    async fetchParamsTemplates() {
      this.loading = true
      try {
        let response = await fetch(host + '/rest/params-templates', {
          credentials: "include"
        })
        let json = await response.json()
        this.paramsTemplates = json.paramsTemplates
        this.ptedit = []
        for(let pt of this.paramsTemplates) {
          this.ptedit.push(false)
        }
        this.loading = false

      } catch(error) {
        console.log(error)
        this.loading = false
      }
    },
    fetchParamsTemplatesSync() {
      this.loading = true

      fetch(host + '/rest/params-templates', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.paramsTemplates = json.paramsTemplates
            this.ptedit = []
            for(let pt of this.paramsTemplates) {
              this.ptedit.push(false)
            }
            this.loading = false
          })
          .catch(error => {
            console.log(error)
            this.loading = false
          })

    },
    async sendToClient(item = null) {
      this.loading = true
      try {
        let response = await fetch(host + '/rest/params-templates/params-template-to-client', {
          method: "POST",
          credentials: "include",
          headers: {'Content-Type': 'application/json; charset=utf-8'},
          body: JSON.stringify({paramsTemplateId: item.paramTemplateId, clientId: this.patientId})
        })
        let json = await response.json()
        if (json.resultCode != 0) {
          this.$errorToast("Ошибка " + json.resultCode + ". " + json.message)
        }
        this.loading = false

      } catch(error) {
        console.log(error)
        this.loading = false
      }

      this.$emit('afterSelectParamsTemplate', item)

      //await this.fetchParamsTemplates()
      // this.currentAnalit = item
      // this.$bvModal.show('createClientReportParamModal')
    },
  },
  async mounted() {
    await this.fetchParamsTemplates()
  }
}
</script>

<style scoped>

</style>