<template>
  <b-form @submit.prevent="onSubmit" class="mx-4 my-4">
    <div class="log" >Логин</div>
    <b-form-input id="login" type="text" placeholder="" v-model="form.login" autocomplete="off" class="mt-2 log_in login-input"></b-form-input>
    <div class="mt-2 pass"><span>Пароль</span></div>
    <div class="password pass_icon">
      <b-form-input id="login" type="password" placeholder="" v-model="form.password" autocomplete="off" class="mt-2 position-relative pass_in login-input"></b-form-input>
<!--      <a href="#" class="password-control pass_eyes img_pass_eyes"  onclick="return show_hide_password(this);"></a>-->
    </div>
    <div class="text-center mt-3 remember_me"><input type="checkbox"> Запомнить меня </div>
    <router-link to="/pwdrecover"><div class="text-center mt-3 mb-4 forgot_pass">Забыли пароль?</div></router-link>
    <div class="mt-3"><button type="submit" class="btn_enter"><span class="enter">Войти</span> <b-spinner v-if="saving" variant="light" small></b-spinner></button></div>
    <router-link to="/docregister"><div class="text-center mt-3 check_in" >Регистрация</div></router-link>
  </b-form>

<!--  <div>-->
<!--    <b-form @submit.prevent="onSubmit">-->
<!--      <b-form-group id="ig1" label-for="login" label="Логин">-->
<!--        <b-form-input id="login" type="text" placeholder="Введите логин" v-model="form.login" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->

<!--      <b-form-group id="ig2" label-for="password" label="Пароль">-->
<!--        <b-form-input id="login" type="password" placeholder="Введите пароль" v-model="form.password" autocomplete="off"></b-form-input>-->
<!--      </b-form-group>-->

<!--      <b-button type="submit" class="btn-info" variant="primary">Войти</b-button>-->

<!--    </b-form>-->
<!--  </div>-->
</template>

<script>
import {host, roles} from "@/constants";

export default {
  name: "LoginIndex",
  computed: {
    isAuthorized: {
      get() {
        return this.$store.getters.isAuthorized
      },
      set(value) {
        this.$store.commit('updateIsAuthorized', value)
      }
    },
    role: {
      get() {
        return this.$store.getters.role
      },
      set(value) {
        this.$store.commit('updateRole', value)
      }
    },
    rolename: {
      get() {
        return this.$store.getters.rolename
      },
      set(value) {
        this.$store.commit('updateRolename', value)
      }
    },
    username: {
      get() {
        return this.$store.getters.username
      },
      set(value) {
        this.$store.commit('updateUsername', value)
      }
    },
    userId: {
      get() {
        return this.$store.getters.userId
      },
      set(value) {
        this.$store.commit('updateUserId', value)
      }
    },
    activationDaysLeftCount: {
      get() {
        return this.$store.getters.activationDaysLeftCount
      },
      set(value) {
        this.$store.commit('updateActivationDaysLeftCount', value)
      }
    },
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      },
      set(value) {
        this.$store.commit('updateReportDetailedBlock', value)
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      },
      set(value) {
        this.$store.commit('updateReportGeneralBlock', value)
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      },
      set(value) {
        this.$store.commit('updateReportConciseBlock', value)
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      },
      set(value) {
        this.$store.commit('updateReportPatientBlock', value)
      }
    },
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      },
      set(value) {
        this.$store.commit('updateReportGraphicBlock', value)
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      },
      set(value) {
        this.$store.commit('updatePermission', value)
      }
    }
  },
  data() {
    return {
      saving: false,
      form: {
        login: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit(){
      this.saving = true
      fetch(host + '/rest/session', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(this.form),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.isAuthorized = true
              this.role = json.role
              this.rolename = roles.filter(t => t.id == this.role)[0].name
              this.username = json.username
              this.userId = json.userId
              this.activationDaysLeftCount = json.activationDaysLeftCount
              this.reportDetailedBlock = json.reportDetailedBlock
              this.reportGeneralBlock = json.reportGeneralBlock
              this.reportConciseBlock = json.reportConciseBlock
              this.reportPatientBlock = json.reportPatientBlock
              this.reportGraphicBlock = json.reportGraphicBlock
              this.permission = json.permission

              if (this.role == 3) {
                this.$router.push('/analits')
              }  else if (this.role == 2) {
                this.$router.push('/patients')
              }  else {
                this.$router.push('/patients/' + this.userId)
              }
            } else {
              this.$errorToast(json.message);
            }
            this.saving = false
          }).catch(error => {
            this.$errorToast(error);
            this.saving = false
          })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/main_page.css';
</style>