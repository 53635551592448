<template>
  <b-container fluid>
    <b-row>
      <b-button variant="info" to="/customer-edit">Создать заказчика</b-button> &nbsp;
      <b-button variant="info" @click="restartSchedulers">Обновить список заказчиков для обмена исследованиями</b-button>
    </b-row>
    <b-row>
      &nbsp;<br>
    </b-row>
    <b-row>
      <b-table striped hover :items="items" :fields="fields">
        <!-- A custom formatted column -->
        <template v-slot:cell(doctorName)="data">
          <span v-if="data.item.isBlocked" >{{ data.item.firstname }} {{ data.item.lastname }}</span>
          <span v-else><b>{{ data.item.firstname }}</b> <b class="text-info">{{ data.item.lastname }}</b></span>
        </template>
        <template v-slot:cell(isActive)="data">
          <span>
            <b-checkbox disabled v-model="data.item.isActive"></b-checkbox>
          </span>
        </template>
        <template v-slot:cell(isAlphalabCustomer)="data">
          <span>
            <b-checkbox disabled v-model="data.item.isAlphalabCustomer"></b-checkbox>
          </span>
        </template>
        <template v-slot:cell(buttons)="data">
          <h3 class="buttons">
            <nobr>
              <b-link @click="rowClickHandler(data.item)">
                <b-icon variant="info" icon="pencil" size="lg"></b-icon>
              </b-link>
              &nbsp;
              <b-link @click="deleteCustomer(data.item)">
                <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
              </b-link>

            </nobr>
          </h3>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <Loader v-bind:showLoader="loading" />
    </b-row>
  </b-container>
</template>

<script>
import Loader from "../../Loader";
import {host} from '@/constants';
export default {
  name: "CustomerList",
  components: {Loader},
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'name',
          label: 'Название',
          sortable: true
        },
        {
          key: 'url',
          label: 'URL',
          sortable: false
        },
        {
          key: 'login',
          label: 'Login',
          sortable: false
        },
        {
          key: 'timeout',
          label: 'Частота запросов',
          sortable: false
        },
        {
          key: 'isActive',
          label: 'Активный',
          sortable: false
        },
        {
          key: 'isAlphalabCustomer',
          label: 'Заказчик Альфалаб',
          sortable: false
        },
        {
          key: 'doctorName',
          label: 'Врач',
          sortable: false
        },
        {
          key: 'paramCodeMappingName',
          label: 'Группа соответствия аналитов',
          sortable: false
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],

    }
  },
  methods :{
    rowClickHandler(item) {
      this.$router.push('/customer-edit/' + item.id)
    },
    async fetchData() {
      console.log('fetchData started')
      this.loading = true
      try {
        const response = await fetch(host + '/rest/customers', {
          credentials: 'include'
        })
        const json = await response.json()


        this.loading = false

        if (json.resultCode == 0) {
          this.items = json.customers


        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
          console.log(error)
        }
      } catch(error) {
        this.$errorToast(error)
        console.log(error)
        this.loading = false
      }

    },
    async restartSchedulers() {
      this.loading = true
      try {
        const response = await fetch(host + '/rest/customers/restartschedulers', {
          credentials: 'include'
        })
        const json = await response.json()
        if (json.resultCode == 0) {
          this.$customInfoMessageToastAutohide('Обновление заказчиков', 'Обмен исследованиями по списку заказчиков запущен успешно')
        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
          console.log(error)
        }
        this.loading = false
      } catch(error) {
        this.$errorToast(error)
        console.log(error)
        this.loading = false
      }
    },
    async deleteCustomer(customer) {

      if (confirm('Вы действительно хотите удалить заказчика?'))
        this.loading = true;
      let response = await fetch(host + '/rest/customers/' + customer.id , {
        method: 'DELETE',
        credentials: 'include',
      })
      let json = await response.json()
      try {
        if (json.resultCode == 0) {
          this.$messageToast('Удаление врача')
          await this.fetchData()

        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
      } catch(error) {
        this.$errorToast(error)
      }
      this.loading = false
    }
  },
  async mounted() {
    console.log(' mounted started ')
    await this.fetchData()
  }
}
</script>

<style scoped>

</style>